<template>
    <div class="mp-calendar" v-if="data">
        <z-caption size="2xl" tag="p" class="u-bottom-margin--s-important">
            Investor Calendar
        </z-caption>
        <div class="mp-calendar__content">
            <z-caption class="mp-calendar__title u-bottom-margin--s-important" tag="p" size="4xl" weight="700">
                <span v-html="data.name"></span>
            </z-caption>
            <date-block
                class="u-bottom-margin--s"
                :isVertical="data.date.end ? true : false"
                :date="data.date.start"
                :dateEnd="data.date.end"
            />
        </div>
        <z-link
            class="mp-calendar__link"
            size="s"
            href="/news-and-events/investors-calendar/"
        >Learn more</z-link>
    </div>
</template>

<script>
export default {
    name: 'mp-calendar',
    computed: {
        data () {
            if (!this.$root.app.components['main-page'] && !this.$root.app.components['main-page'].events) return
            return this.$root.app.components['main-page'].events
        }
    }
}
</script>

<style lang="scss">
.mp-calendar {
    background-color: $token-colors-white;
    border-radius: 10px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
    @include padding-level(top, S);
    @include padding-level(bottom, S);
    @include padding-level(right, S);
    @include padding-level(left, S);
    display: flex;
    flex-direction: column;

    &__content {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include breakpoint (mobile) {
            display: block;
        }

        .mp-calendar__title {
            max-width: 485px;

            @include breakpoint (tablet) {
                max-width: 240px;
            }

            @include breakpoint (v-tablet) {
                max-width: 100%;
            }
        }
    }

    &__link {
        margin-top: auto;
    }
}
</style>
