<template>
    <div class="detail-page" v-if="data">
        <div class="detail-page__header">
            <div class="detail-page__info">
                <span
                    class="detail-page__info-item"
                    v-if="data.pdf"
                >
                    <z-link
                        :download="`${data.name}.pdf`"
                        :href="data.pdf"
                        target="_blank"
                        theme="text"
                        size="xs"
                        icon="sign-in"
                    >
                        <span v-html="text.download"></span>
                    </z-link>
                </span>
                <span
                    v-if="data.date"
                    class="detail-page__info-item"
                >{{ data.date | momentFilter('MM/DD/YYYY') }}</span>
                <span
                    v-if="data.source"
                    class="detail-page__info-item"
                >{{ text.source }}  &#171;{{ data.source }}&#187;</span>
                <div class="detail-page__info-item" v-if="hasSlotInfo">
                    <slot name="info"/>
                </div>
            </div>

            <div class="u-top-margin--xs" v-if="hasSlotHeader">
                <slot name="header" />
            </div>
        </div>
        <article
            class="detail-page__body"
            v-html="data.detail"
        ></article>
        <div class="detail-page__footer u-top-margin--m" v-if="hasSlotFooter">
            <slot name="footer" />
        </div>
        <back-link class="u-top-margin--l" />
    </div>
</template>

<script>
export default {
    name: 'detail-page',
    props: {
        componentName: String,
        inlineData: Object,
        type: String
    },
    computed: {
        hasSlotInfo () {
            return !!this.$slots.info
        },
        hasSlotHeader () {
            return !!this.$slots.header
        },
        hasSlotFooter () {
            return !!this.$slots.footer
        }
    },
    data () {
        return {
            data: null,
            text: {
                source: this.$root.lang === 'ru' ? 'Источник:' : 'Source:',
                download: this.$root.lang === 'ru' ? 'Скачать полную версию в pdf' : 'Download full pdf version',
                back: this.$root.lang === 'ru' ? 'Назад к списку' : 'Back to the list'
            }
        }
    },
    updated () {
        if (this.inlineData) {
            this.data = this.inlineData
        }
    },
    created () {
        if (this.inlineData) {
            this.data = this.inlineData
        } else if (this.componentName) {
            this.data = this.$root.app.components[this.componentName]
        }

        this.$store.dispatch('BackText', this.text.back)
        this.$store.dispatch('BackLink', this.data['back-link'])
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
