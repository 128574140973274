<template>
    <div
        class="main-slider"
        v-if="data.length"
    >
        <div
            class="main-slider__container"
            ref="mainSliderContainer"
        >
            <div class="main-slider__wrapper">
                <div
                    class="main-slider__slide"
                    v-for="(slide, index) in data"
                    :key="index"
                >
                    <img
                        v-if="slide.image"
                        :src="slide.image"
                        :alt="slide.name"
                    />
                    <div class="main-slider__news container container--wide">
                        <div class="row">
                            <div class="col-default-3 col-tablet-hide"></div>
                            <div class="col-default-6 col-tablet-6 col-v-mobile-12">
                                <span
                                    class="main-slider__date"
                                    v-if="slide.date"
                                >
                                    {{ slide.date | momentFilter('DD MMMM YYYY') }}
                                </span>
                                <a
                                    class="main-slider__name"
                                    v-if="slide.name && slide.link"
                                    :href="slide.link"
                                    :title="slide.name"
                                    v-html="slide.name"
                                    target="_blank"
                                ></a>
                            </div>
                            <div class="col-default-3 col-tablet-6 col-v-mobile-hide"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="main-slider__navigation container container--wide hide-me--tablet"
                v-if="data.length > 1"
            >
                <button
                    class="main-slider__button main-slider__button--prev"
                    ref="mainSliderNavPrev"
                >
                    <z-icon
                        name="slider"
                        dir="down"
                        width="16"
                        height="16"
                    />
                </button>
                <button
                    class="main-slider__button main-slider__button--next"
                    ref="mainSliderNavNext"
                >
                    <z-icon
                        name="slider"
                        dir="up"
                        width="16"
                        height="16"
                    />
                </button>
            </div>
            <div
                class="main-slider__info container container--wide"
                v-if="data.length > 1"
            >
                <div
                    class="main-slider__pagination"
                    ref="mainSliderPagination"
                ></div>
                <span class="main-slider__counter">{{ slider.realIndex + 1 }}&nbsp;/&nbsp;{{ data.length }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import Swiper from 'swiper/dist/js/swiper.js'

export default {
    name: 'main-slider',
    props: {},
    data () {
        return {
            slider: Object,
            data: this.$root.app.components['main-slider']
        }
    },
    mounted () {
        this.init()

        this.$nextTick(() => {
            if (this.data.length) objectFitPolyfill(this.$el.querySelectorAll('img'))
        })
    },
    methods: {
        init () {
            let container = this.$refs.mainSliderContainer
            let pagination = this.$refs.mainSliderPagination
            let navigationPrev = this.$refs.mainSliderNavPrev
            let navigationNext = this.$refs.mainSliderNavNext

            this.slider = new Swiper(container, {
                loop: true,
                speed: 1000,
                autoplay: {
                    delay: 10000,
                    disableOnInteraction: false
                },
                effect: 'fade',
                fadeEffect: {
                    crossFade: true
                },
                spaceBetween: 0,
                slidesPerView: 1,
                mousewheel: false,
                simulateTouch: true,
                slideActiveClass: 'is-active',
                slideClass: 'main-slider__slide',
                wrapperClass: 'main-slider__wrapper',
                containerModifierClass: 'main-slider__container',
                pagination: {
                    el: pagination,
                    type: 'bullets',
                    clickable: false,
                    bulletActiveClass: 'is-active',
                    bulletClass: 'main-slider__bullet'
                },
                navigation: {
                    nextEl: navigationNext,
                    prevEl: navigationPrev
                }
            })
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
