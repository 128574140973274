<template>
    <component
        class="card"
        :href="link"
        :is="link ? 'a' : 'span'"
        :class="`card--theme-${theme}`"
        :target="link ? '_blank' : false"
    >
        <div class="card__image-wrapper" v-if="img">
            <img
                class="card__image"
                :src="img"
                :alt="title"
            />
        </div>
        <span class="card__info">
            <div class="card__top" v-if="theme == 'vacancy' || title">
                <z-icon
                    v-if="theme == 'vacancy'"
                    :color="iconColor"
                    :name="icon"
                    dir="bottom"
                    width="50"
                    height="50"
                />
                <component
                    class="card__title"
                    :is="title && links.title && !link ? 'a' : 'span'"
                    :href="links.title"
                    v-html="title"
                ></component>
            </div>
            <div class="card__bottom">
                <div class="card__date-block">
                    <div class="card__extension"
                        v-if="theme != 'vacancy'"
                        v-html="type"
                    ></div>
                    <div class="card__type" v-if="theme != 'vacancy'">
                        <z-icon
                            name="icons/photo"
                            v-if="theme == 'photo'"
                            dir="top"
                            width="25"
                            height="25"
                        />
                        <z-icon
                            name="icons/video"
                            v-if="theme == 'video'"
                            dir="top"
                            width="25"
                            height="25"
                        />
                        <span
                            class="card__text"
                            v-if="theme == 'doc'"
                        >
                            {{ date | momentFilter('DD MMMM YYYY') }}
                        </span>
                        <span
                            class="card__text"
                            v-if="theme == 'video' || theme == 'photo'"
                            v-html="theme == 'photo'? photoText : videoText"
                        ></span>
                    </div>
                </div>
                <div class="card__icon-block">
                    <z-link
                        v-if="theme == 'photo'"
                        href="javascript: void(0);"
                        @click="$emit('open')"
                    >
                        <z-icon
                            name="search"
                            dir="top"
                            width="25"
                            height="25"
                        />
                    </z-link>
                    <z-link
                        v-if="theme == 'doc'"
                        :href="download"
                        target="_blank"
                    >
                        <z-icon
                            name="search"
                            dir="top"
                            width="25"
                            height="25"
                        />
                    </z-link>
                    <z-link
                        v-if="theme == 'video'"
                        href="javascript: void(0);"
                        @click="$emit('open')"
                    >
                        <z-icon
                            class="card__icon"
                            name="play"
                            dir="bottom"
                            width="25"
                            height="25"
                        />
                    </z-link>
                    <z-link
                        v-if="isDownloadable"
                        :download="download"
                        :href="download"
                    >
                        <z-icon
                            class="card__icon"
                            name="download"
                            dir="bottom"
                            width="25"
                            height="25"
                        />
                    </z-link>
                </div>
            </div>
        </span>
    </component>
</template>

<script>
export default {
    name: 'card-media',
    props: {
        theme: {
            type: String,
            default: 'photo',
            validator: prop => ['photo', 'video', 'vacancy', 'doc'].includes(prop)
        },
        links: {
            type: Object,
            default: () => { return {} }
        },
        icon: {
            type: String,
            default: 'chart'
        },
        iconColor: {
            type: String,
            default: '#C4C7CC'
        },
        img: String,
        link: String,
        type: String,
        video: String,
        title: String,
        source: String,
        download: String,
        date: [String, Number]
    },
    data () {
        return {
            photoText: this.$root.lang === 'ru' ? 'Фото' : 'Photo',
            videoText: this.$root.lang === 'ru' ? 'Видео' : 'Video'
        }
    },
    computed: {
        isDownloadable () {
            if (this.theme === 'photo' || this.theme === 'doc') return true
            if (this.theme === 'video' && this.source === 'local') return true
            return false
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
