var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"products-and-services"},[_c('div',{staticClass:"main-page-title"},[_c('z-caption',{staticClass:"u-collapse--all",attrs:{"tag":"h2","size":"5xl"}},[_vm._v("\n            Products and services\n        ")]),_vm._v(" "),_c('z-link',{staticClass:"main-page-title__link hide-me--mobile",attrs:{"size":"s","href":"/company/products-and-services/"}},[_vm._v("Learn more")])],1),_vm._v(" "),_c('slider',{attrs:{"theme":"products","options":{
            slidesPerView: 5,
            loop: true,
            breakpoints: {
                600: {
                    slidesPerView: 1
                },
                1023: {
                    slidesPerView: 2
                },
                1359: {
                    slidesPerView: 4
                }
            }
        }}},[_c('slide',[_c('div',{staticClass:"products-and-services__slide"},[_c('z-link',{staticClass:"products-and-services__link",attrs:{"rel":"noopener noreferrer","href":"//qiwi.com/","target":"_blank"}},[_c('z-image',{attrs:{"src":"/images/main-page/products/wallet.svg"}})],1)],1)]),_vm._v(" "),_c('slide',[_c('div',{staticClass:"products-and-services__slide"},[_c('z-link',{staticClass:"products-and-services__link",attrs:{"rel":"noopener noreferrer","href":"//qiwi.business/en/","target":"_blank"}},[_c('z-image',{attrs:{"src":"/images/main-page/products/business.svg"}})],1)],1)]),_vm._v(" "),_c('slide',[_c('div',{staticClass:"products-and-services__slide"},[_c('z-link',{staticClass:"products-and-services__link",attrs:{"rel":"noopener noreferrer","href":"//www.contact-sys.com/","target":"_blank"}},[_c('z-image',{attrs:{"src":"/images/main-page/products/contact.svg"}})],1)],1)]),_vm._v(" "),_c('slide',[_c('div',{staticClass:"products-and-services__slide"},[_c('z-link',{staticClass:"products-and-services__link products-and-services__link--dark",attrs:{"rel":"noopener noreferrer","href":"//rowi.com/","target":"_blank"}},[_c('z-image',{attrs:{"src":"/images/main-page/products/rowi.svg"}})],1)],1)]),_vm._v(" "),_c('slide',[_c('div',{staticClass:"products-and-services__slide"},[_c('z-link',{staticClass:"products-and-services__link products-and-services__link--dark",attrs:{"rel":"noopener noreferrer","href":"//www.flocktory.com/en_US/","target":"_blank"}},[_c('z-image',{attrs:{"src":"/images/main-page/products/flocktory.svg"}})],1)],1)])],1),_vm._v(" "),_c('z-link',{staticClass:"hide-me--default show-me--mobile u-top-margin--s",attrs:{"size":"s","href":"/company/products-and-services/"}},[_vm._v("Learn more")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }