<template>
    <div>
        <z-preloader v-if="loading" />
        <detail-page
            v-if="data && !loading"
            class="sec-fillings-detail"
            type="sec"
            :inline-data="{
                code: data.code,
                date: data.date,
                detail: data.text,
                'back-link': '/results-and-reports/sec-filings/'
            }"
        >
            <template v-slot:info>
                <z-link
                    v-for="(file, index) in data.files"
                    :download="buildFileName(file)"
                    target="_blank"
                    :key="index"
                    :class="{ 'u-right-margin--2xs': index + 1 < data.files.length }"
                    :href="file.link"
                    :icon="file.type === 'html' ? 'link' : 'file'"
                    theme="gray"
                    :inline="false"
                >{{ file.type }}</z-link>
            </template>
        </detail-page>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'sec-fillings-detail',
    data () {
        return {
            data: this.$root.app.components['sec-fillings-detail'],
            loading: false
        }
    },
    methods: {
        buildFileName (file) {
            if (file.hasOwnProperty('filename')) return file.filename

            let name = 'report'

            if (file.hasOwnProperty('name')) {
                name = file.name
            }

            // if (file.hasOwnProperty('type')) {
            //     name += `.${file.type}`
            // }

            return name
        }
    },
    created () {
        if (!this.data.files) {
            this.data.files = []
        }

        if (this.data.code && (this.data.files.length === 0 || !this.data.text || this.data.text.length === 0)) {
            this.loading = true
            axios.get(`https://tools.eurolandir.com/tools/services/?func=GetPressReleaseById&companycode=cy-qiwi&v=gnw&format=json&id=${this.data.code}`)
                .then((res) => {
                    if (res.data) {
                        if (!this.data.text || this.data.text.length === 0) {
                            this.data.text = res.data[0].message
                        }

                        if (res.data[0].attachments && this.data.files.length === 0) {
                            this.data.files = res.data[0].attachments.map(obj => {
                                let arrType = obj.filename.split('.')
                                return {
                                    link: obj.url,
                                    name: obj.filename,
                                    type: arrType[arrType.length - 1],
                                    size: null
                                }
                            })

                            this.data.files.sort((a, b) => {
                                if (a.type < b.type) { return -1 }
                                if (a.type > b.type) { return 1 }
                                return 0
                            })
                        }

                        this.loading = false
                    }

                    this.$forceUpdate()
                })
                .catch((err) => {
                    console.log(err)
                    this.loading = false
                })
        }

        try {
            this.data.files.sort((a, b) => {
                if (a.type < b.type) { return -1 }
                if (a.type > b.type) { return 1 }
                return 0
            })
        } catch (error) {
            console.log(error)
        }
    }
}
</script>

<style lang="scss">
.sec-fillings-detail {
    .detail-page__body {
        div {
            max-width: 100%;
            width: auto !important;
        }

        table {
            th, td {
                padding: initial;
            }
        }
    }
}
</style>
