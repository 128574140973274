const phoneMask = {
    bind (element, binding) {
        if (!binding.value) return

        element.oninput = e => {
            if (!e.isTrusted) return

            element.value = element.value.replace('+7', '')

            let x = element.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)

            element.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '')
            element.value = element.value.replace(/^/, '+7 ')

            let event = document.createEvent('Event')

            event.initEvent('input', true, true)
            element.dispatchEvent(event)
        }
    }
}

export { phoneMask }
