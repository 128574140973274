<template>
    <div class="z-copyright">
        <span
            v-if="textBefore"
            class="z-copyright__text"
        >
            {{ textBefore }}
        </span>
        <span>&copy;</span>
        <span class="z-copyright__years">
            <span
                v-if="year"
                class="z-copyright__year-start"
            >
                {{ this.year }}
            </span>
            {{hyphen}}
            <span
                v-if="yearCurrent"
                class="z-copyright__year-current"
            >
                {{ currentYear }}
            </span>
        </span>
        <span
            v-if="textAfter"
            class="z-copyright__text"
        >
            {{ textAfter }}
        </span>
    </div>
</template>

<script>
export default {
    name: 'z-copyright',
    props: {
        textAfter: String,
        textBefore: String,
        yearCurrent: Boolean,
        year: [String, Number]
    },
    data () {
        return {
            lang: this.$root.lang
        }
    },
    computed: {
        currentYear () {
            return new Date().getFullYear()
        },
        hyphen () {
            return this.year && this.yearCurrent ? '—' : ''
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
