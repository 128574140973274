import Vue from 'vue'
import moment from 'moment'

// eslint-disable-next-line no-undef
const LANG = App.page.lang
moment.locale(`${LANG}`)

/**
 * Change format date
 * @name filter.momentFilter
 * @param {number|string} dataStr - Source timestamp
 * @param {string} pattern - moment format date
 * @returns String
 */
Vue.filter('momentFilter', function (dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
    moment.locale(LANG)
    return isNaN(dataStr) ? moment(dataStr).format(pattern) : moment.unix(dataStr).format(pattern)
})

/**
 * Change format date
 * @name filter.numberFilter
 * @param {number|string} dataStr - number
 * @param {object} options - contains {string} locale, {string} millesimal, {decimal} millesimal
 * @returns String
 */
Vue.filter('numberFilter', function (dataStr, options = {}) {
    let settings = {
        locale: LANG === 'ru' ? 'ru-RU' : 'en-EN',
        millesimal: '',
        decimal: '.'
    }
    settings = {...settings, ...options}

    let result = dataStr.toString().replace(' ', '')
    result = result.replace(new RegExp(settings.millesimal, 'g'), '')
    result = result.replace(new RegExp(`[${settings.decimal}]`, 'g'), '.')
    result = Number(result)

    if (isNaN(result)) return dataStr

    return result.toLocaleString(settings.locale)
})
