/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chairperson': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<g clip-path="url(#clip0_5243_46247)"><circle pid="0" cx="15.338" cy="14.684" r="14.684" _fill="#2F363F"/><path pid="1" d="M8.537 14.513c0 4.092 2.82 7.244 7.185 7.244 2.487 0 4.268-1.037 5.463-2.251l-1.332-1.82c-1.057 1.037-2.545 1.761-4.033 1.761-3.015 0-4.68-2.369-4.68-4.973 0-2.565 1.606-4.777 4.66-4.777 1.351 0 2.82.626 3.799 1.468l1.214-1.88c-1.351-1.311-3.31-1.898-5.13-1.898-4.19 0-7.146 3.093-7.146 7.126z" _fill="#fff"/></g><defs><clipPath id="clip0_5243_46247"><path pid="2" _fill="#fff" transform="scale(1.02439)" d="M0 0h29.286v29.286H0z"/></clipPath></defs>'
  }
})
