<template>
    <div class="calendar" v-if="data && data.length">
        <z-tabs>
            <template v-slot:label>
                <z-tabs-label
                    v-for="item in yearSorted"
                    :key="item"
                    :id="item"
                >
                    {{item}}
                </z-tabs-label>
            </template>
            <template v-slot:content>
                <z-tabs-content
                    v-for="(itemA, indexA) in data"
                    :key="indexA"
                    :id="itemA.year"
                >
                    <div class="row">
                        <div
                            class="col-default-4 col-tablet-6 col-mobile-12"
                            v-for="(itemB, indexB) in itemA.items"
                            :key="indexB"
                        >
                            <calendar-card class="u-entire-height" :data="itemB" />
                        </div>
                    </div>
                </z-tabs-content>
            </template>
        </z-tabs>
    </div>
</template>

<script>
import CalendarCard from './components/Card.vue'

export default {
    name: 'calendar',
    data () {
        return {
            data: this.$root.app.components.events
        }
    },
    created () {
        if (this.data) {
            this.data.sort((a, b) => {
                return parseInt(b.year) - parseInt(a.year)
            })
        }
    },
    computed: {
        yearSorted () {
            return this.data.map(obj => obj.year).sort((a, b) => parseInt(b) - parseInt(a))
        }
    },
    components: {
        CalendarCard
    }
}
</script>

<style lang="scss"></style>
