<template>
    <div
        class="last-updates"
        v-if="data.length"
    >
        <div
            class="last-updates__item"
            v-for="(item, index) in data"
            :key="index"
        >
            <span
                class="last-updates__title"
                v-if="item.title"
                v-html="item.title"
            ></span>
            <z-link
                v-if="item.link && item.name"
                :href="item.link"
                theme="white"
                target="_blank"
            >{{ item.name }}</z-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'last-updates',
    data () {
        return {
            data: this.$root.app.components['last-updates']
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
