/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'minus-circle': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M2.75 12a9.25 9.25 0 1118.5 0 9.25 9.25 0 01-18.5 0zM12 1.25C6.063 1.25 1.25 6.063 1.25 12S6.063 22.75 12 22.75 22.75 17.937 22.75 12 17.937 1.25 12 1.25zm-4 10a.75.75 0 000 1.5h8a.75.75 0 000-1.5H8z" _fill="#FF8C00"/>'
  }
})
