/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'social/instagram': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<circle pid="0" cx="16" cy="16" r="16" _fill="#2F363F"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M10.4 8.8h11.2c.88 0 1.6.72 1.6 1.6v11.2c0 .88-.72 1.6-1.6 1.6H10.4c-.88 0-1.6-.72-1.6-1.6V10.4c0-.88.72-1.6 1.6-1.6zm8.72 10.32a4.43 4.43 0 01-7.564-3.133c0-.27.035-.53.083-.787H10.4v5.773a.552.552 0 00.554.552h10.092a.55.55 0 00.554-.552V15.2h-1.265c.048.256.083.517.083.787a4.431 4.431 0 01-1.298 3.133zm-5.69-4.192a2.767 2.767 0 002.557 3.828 2.77 2.77 0 10-2.558-3.828zm6.324-2.128h1.292c.306 0 .554-.25.554-.554v-1.293a.553.553 0 00-.555-.553h-1.292a.553.553 0 00-.553.553v1.293a.555.555 0 00.554.554z" _fill="#fff"/>'
  }
})
