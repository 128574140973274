/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-thin-right': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M17.22 15.78l-5.47-5.47-5.47 5.47a.75.75 0 01-1.06-1.06l6-6a.75.75 0 011.06 0l6 6a.75.75 0 11-1.06 1.06z" _fill="#000"/>'
  }
})
