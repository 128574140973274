/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cardtocard': {
    width: 72,
    height: 76,
    viewBox: '0 0 72 76',
    data: '<ellipse pid="0" cx="36" cy="37.601" rx="36" ry="37.601" _fill="#F5F5F5"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M15.711 48.237h8.63l1.856-4.236h-10.14V30.705h39.785v7.031c1.683 2.073 2.805 4.597 3.28 6.85h.777V17.455c0-2.118-1.64-3.876-3.711-3.876H15.71c-2.071 0-3.711 1.713-3.711 3.876v26.907c0 2.119 1.64 3.877 3.711 3.877zm.345-30.422h39.786v5.228H16.056v-5.228zm27.963 38.76c-3.452 0-6.387-2.298-7.508-5.498a8.889 8.889 0 01-.475-2.84h3.84l-5.911-13.43-5.87 13.43h3.841c0 .992.13 1.938.302 2.84 1.252 5.589 6.042 9.78 11.738 9.78 2.157 0 4.185-.586 5.955-1.667l-1.683-3.921a7.609 7.609 0 01-4.23 1.307zm11.737-11.177c.173.901.302 1.848.302 2.84h3.84L54.03 61.623l-5.869-13.432h3.84c0-.991-.172-1.938-.474-2.84-1.122-3.2-4.056-5.498-7.508-5.498-1.597 0-3.02.496-4.272 1.307l-1.683-3.876a11.334 11.334 0 015.955-1.667c5.696 0 10.486 4.191 11.737 9.78z" _fill="#FF8C00"/>'
  }
})
