/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'open_api': {
    width: 72,
    height: 76,
    viewBox: '0 0 72 76',
    data: '<ellipse pid="0" cx="36" cy="37.601" rx="36" ry="37.601" _fill="#F5F5F5"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M19.832 25.757L6 38.245 20 50.34l3.584-3.976-9.632-8.344 9.184-8.512-3.304-3.752zm9.018 32.216l4.32.768 10.416-42-4.32-.768-10.416 42zm22.664-7.632l13.832-12.488-14-12.096-3.584 3.976 9.632 8.344-9.184 8.512 3.304 3.752z" _fill="#2F363F"/>'
  }
})
