<template>
    <div class="news">
        <!-- begin: filters -->
        <list-filters
            :data="news.filters"
            v-if="Object.keys(news.filters).length"
            @change="onChange"
            :class="{'is-disabled': loading}"
            @clear="clearFilters"
            :show-clear-btn="isParamsNotEmpty"
            :show-search-input="true"
        />
        <!-- end: filters -->

        <!-- <div class="news__divider news__divider--transparent" v-if="news.list.length">
            <div class="news__title u-medium">{{ text.founded }}:&nbsp;</div>
            <div class="news__counter u-medium">{{ nav.count }}</div>
        </div> -->

        <!-- begin: news list -->
        <z-table
            class="news__table"
            :colgroup="['25%', '75%']"
        >
            <z-table-row
                v-for="(item, index) in news.list"
                :key="index"
            >
                <z-table-col index="0">
                    <span class="show-me--mobile hide-me--default gray-60-text">{{ item.date | momentFilter('MM/DD/YYYY') }}</span>
                    <date-block class="hide-me--mobile" :date="item.date" />
                </z-table-col>
                <z-table-col index="1">
                    <z-link :href="item.link" theme="big">
                        <span v-html="item.name"></span>
                    </z-link>
                </z-table-col>
            </z-table-row>
        </z-table>
        <!-- end: news list -->

        <!-- begin: preloader -->
        <z-preloader v-if="loading" />
        <!-- end: preloader -->

        <!-- begin: not found -->
        <z-not-found
            v-if="!news.list.length && !loading"
            :text="text.notFoundText"
            :title="text.notFoundTitle"
        />
        <!-- end: not found -->
    </div>
</template>

<script>
import { getNews } from '@/api/news'
import { mixinDevice } from '@/utils/mixin'
import { debounce } from 'throttle-debounce'
import { queryString } from '@/utils/queryString'

export default {
    name: 'news',
    mixins: [mixinDevice],
    props: {
        initial: {
            type: Object,
            default: () => {
                return {
                    years: '',
                    search: ''
                }
            }
        }
    },
    data () {
        return {
            news: {
                list: this.$root.app.components.news.items,
                filters: this.$root.app.components.news.filters
            },
            text: {
                clear: this.$root.lang === 'ru' ? 'Сбросить' : 'Clear',
                filters: this.$root.lang === 'ru' ? 'Фильтр' : 'Filter',
                founded: this.$root.lang === 'ru' ? 'Найдено' : 'Found',
                notFoundTitle: this.$root.lang === 'ru' ? 'Ничего не найдено' : 'No results found',
                notFoundText: this.$root.lang === 'ru' ? 'По&nbsp;Вашему запросу ничего не&nbsp;нашлось.' : 'Sorry, no matches were found for your request. Try changing your search terms.'
            },
            nav: {
                count: this.$root.app.components.news.nav.count,
                total: this.$root.app.components.news.nav.total,
                current: this.$root.app.components.news.nav.current
            },
            page: 1,
            loading: false,
            notFound: false,
            params: this.initial
        }
    },
    computed: {
        isParamsNotEmpty () {
            let params = Object.assign({}, this.params)

            delete params.page

            let arr = Object.values(params)

            for (let i = 0; i < arr.length; i++) {
                if (arr[i].toString().length > 0) {
                    return true
                }
            }
        }
    },
    mounted () {
        this.listenScroll()
    },
    methods: {
        onChange (data) {
            this.page = 1
            this.params.page = this.page
            this.params[data.name] = data.value
            this.send(this.clearEmptyParams(this.params))
        },
        send: debounce(1000, function (params) {
            this.loading = true

            if (this.page === 1) this.news.list = []

            getNews(params).then(res => {
                this.updateNav(res)
                this.updateList(res)
                this.updateFilters(res)

                queryString(params)

                this.loading = false
            })
        }),
        updateNav (res) {
            if (res.hasOwnProperty('nav')) {
                this.nav.current = res.nav.current
                this.nav.total = res.nav.total
                this.nav.count = res.nav.count
            }
        },
        updateList (res) {
            if (res.hasOwnProperty('items')) {
                if (res.items.length === 0) this.notFound = true
                if (this.nav.current < 2) {
                    this.news.list = res.items
                } else {
                    this.news.list = this.news.list.concat(res.items)
                }
            }
        },
        updateFilters (res) {
            if (res.hasOwnProperty('filters')) {
                if (Object.keys(res.filters).length) this.news.filters = res.filters
            }
        },
        clearEmptyParams () {
            let params = Object.assign({}, this.params)

            Object.keys(params).forEach(function (key, index) {
                if (Array.isArray(params[key])) {
                    if (!params[key].length) delete params[key]
                } else if (!params[key]) {
                    delete params[key]
                }
            })

            return params
        },
        clearFilters () {
            this.params.years = ''
            this.$root.$bus.$emit('clear', 'search')
        },
        listenScroll () {
            window.addEventListener('scroll', debounce(500, e => {
                let offsetHeight = this.$el.offsetHeight
                let bottom = this.$el.offsetTop + offsetHeight
                let scroll = window.pageYOffset || document.documentElement.scrollTop
                let scrolled = scroll + document.documentElement.clientHeight
                let condition = scrolled >= (bottom - 100) && !this.loading && this.nav.current < this.nav.total

                if (condition) {
                    this.page++
                    this.params.page = this.page
                    this.send(this.clearEmptyParams(this.params))
                }
            }))
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
