<template>
    <div class="committees" v-if="data.length">
        <div class="committees-legend">
            <div class="committees-legend__item">
                <z-icon
                    name="chairperson"
                    width="30"
                    height="30"
                />
                <span>Chairperson</span>
            </div>
            <div class="committees-legend__item">
                <z-icon
                    name="member"
                    width="30"
                    height="30"
                />
                <span>Member</span>
            </div>
        </div>

        <z-table
            :columns="['Name', 'Status', 'Audit Committee', 'Compensation Committee', 'Strategy Committee']"
            :colgroup="['20%', '20%', '20%', '20%', '20%']"
        >
            <z-table-row
                v-for="(item, index) in listFiltered"
                :key="index"
            >
                <z-table-col index="0">
                    <z-link :href="item.link">
                        <span v-html="item.name"></span>
                    </z-link>
                </z-table-col>
                <z-table-col index="1">
                    <span v-if="item.position" v-html="item.position"></span>
                </z-table-col>
                <z-table-col index="2">
                    <z-icon
                        v-if="item.audit"
                        :name="item.audit.toLowerCase()"
                        width="30"
                        height="30"
                    />
                </z-table-col>
                <z-table-col index="3">
                    <z-icon
                        v-if="item.compensation"
                        :name="item.compensation.toLowerCase()"
                        width="30"
                        height="30"
                    />
                </z-table-col>
                <z-table-col index="4">
                    <z-icon
                        v-if="item.strategy"
                        :name="item.strategy.toLowerCase()"
                        width="30"
                        height="30"
                    />
                </z-table-col>
            </z-table-row>
        </z-table>
    </div>
</template>

<script>
export default {
    name: 'committees',
    data () {
        return {
            data: this.$root.app.components.committees || []
        }
    },
    computed: {
        listFiltered () {
            return this.data.filter(obj => {
                return !(obj.audit === null && obj.compensation === null && obj.strategy === null)
            })
        }
    }
}
</script>

<style lang="scss">
.committees {
    .z-table-col {
        text-align: center;

        &:first-child,
        &:nth-of-type(2) {
            text-align: left;

            @include breakpoint (v-tablet) {
                min-width: 12em;
            }
        }

        &--body {
            @include padding-level(top, S);
            @include padding-level(bottom, S);
        }

        &--head {
            padding-top: 0;
        }
    }

    .z-table-row {
        border-bottom: 1px solid $token-colors-gray-20;
        transition: $transition;

        &--body {
            &:nth-of-type(odd) {
                background: $token-colors-white;
            }

            &:last-child {
                border-bottom: none;
            }

            &:hover {
                background-color: rgba($token-colors-primary, 0.04);
            }
        }
    }
}

.committees-legend {
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    top: 0.2em;
    right: 0;

    @include breakpoint (mobile) {
        position: relative;
        top: 0;
        margin-bottom: $token-spacers-xs;
    }

    &__item {
        & + & {
            margin-left: $token-spacers-xs;
        }
    }
}
</style>
