<template>
    <div class="bio" v-if="data.length">
        <div class="row">
            <div
                v-for="(item, index) in data"
                :key="index"
                class="col-default-4 col-v-tablet-6 col-mobile-12"
            >
                <bio-item :data="item" image-type="round"/>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'bio',
    props: {
        componentName: {
            type: String,
            default: 'management-board'
        },
        dataList: Array,
        imageType: {
            type: String,
            default: 'square',
            validator: prop => ['square', 'round'].includes(prop)
        }
    },
    data () {
        return {
            data: this.$root.app.components[this.componentName] || this.dataList || []
        }
    }
}
</script>

<style lang="scss">
</style>
