<template>
    <div class="list-filters">
        <div class="list-filters__content">
            <div class="list-filters__item" v-if="showSearchInput">
                <z-input
                    name="search"
                    theme="search"
                    :value="updatedFilters.search"
                    @change="onChange"
                    :placeholder="text.placeholders.search"
                />
            </div>

            <div
                class="list-filters__item list-filters__item--full-width"
                v-if="updatedFilters.years && updatedFilters.years.length"
            >
                <div class="z-tabs__container">
                    <div class="z-tabs__header">
                        <button
                            v-for="year in updatedFilters.years"
                            :key="year.id"
                            @click="onChange({
                                value: year.id,
                                name: 'years'
                            })"
                            class="z-tabs-label"
                            :class="{
                                'z-tabs-label--opened' : year.selected
                            }"
                        >{{ year.text }}</button>
                    </div>
                </div>

                <z-link
                    class="list-filters__clear"
                    kind="text"
                    href="javascript: void(0);"
                    v-if="showClearBtn"
                    @click="$emit('clear')"
                    size="s"
                    icon="cross"
                    width="16"
                    height="16"
                >{{ text.clear }}</z-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'list-filters',
    props: {
        data: Object,
        showClearBtn: Boolean,
        showSearchInput: Boolean
    },
    data () {
        return {
            text: {
                placeholders: {
                    search: this.$root.lang === 'ru' ? 'Введите ключевое слово' : 'Enter a keyword'
                },
                clear: this.$root.lang === 'ru' ? 'Сбросить' : 'Clear'
            }
        }
    },
    computed: {
        updatedFilters () {
            const data = this.data
            if (data.years) {
                data.years = data.years.sort((a, b) => b.id - a.id)
            }
            return data
        }
    },
    methods: {
        onChange (data) {
            this.$emit('change', data)
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
