<template>
    <div class="bio-detail" v-if="data">
        <div class="bio-detail__header">
            <div class="bio-detail__info">
                <div class="bio-detail__info-container">
                    <z-caption tag="h1" size="6xl" class="u-bottom-margin--3xs-important">
                        <span v-html="data.name"></span>
                    </z-caption>
                    <span class="gray-60-text" v-if="data.position" v-html="data.position"></span>
                    <ul class="bio-detail__info-list u-collapse--top">
                        <li
                            v-for="committee in Object.keys(data.committees).filter(key => data.committees[key]).sort((a, b) => data.committees[b].length - data.committees[a].length)"
                            :key="committee"
                            class="bio-detail__info-item"
                        >
                            <z-icon
                                :name="data.committees[committee].toLowerCase()"
                                class="u-right-margin--3xs"
                                width="30"
                                height="30"
                            />
                            <span>{{text[committee][data.committees[committee]]}}</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div
                v-if="data.image"
                class="bio-detail__image"
                :style="`background-image: url(${data.image})`"
            ></div>
        </div>
        <div class="bio-detail__content">
            <div class="bio-detail__detail html-content" v-if="data.detail" v-html="data.detail"></div>
            <div class="bio-detail__detail html-content" v-if="data.experience">
                <z-caption tag="h2" size="4xl" class="u-top-margin--l u-bottom-margin--xs-important">{{text.experienceTitle}}</z-caption>
                <div v-html="data.experience"></div>
            </div>
            <div class="bio-detail__detail html-content" v-if="data.qualifications">
                <z-caption tag="h2" size="4xl" class="u-top-margin--l u-bottom-margin--xs-important">{{text.qualificationsTitle}}</z-caption>
                <div v-html="data.qualifications"></div>
            </div>
            <back-link class="u-top-margin--l" v-if="data['back-link']" />
        </div>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'bio-detail',
    data () {
        return {
            data: this.$root.app.components['bio-detail'],
            text: {
                back: localize({
                    ru: 'Назад к списку',
                    en: 'Back to the list'
                }),
                experienceTitle: localize({
                    ru: 'Опыт работы',
                    en: 'Experience'
                }),
                qualificationsTitle: localize({
                    ru: 'Квалификация',
                    en: 'Qualifications'
                }),
                audit: {
                    MEMBER: localize({
                        ru: '',
                        en: 'Member of the Audit Committee'
                    }),
                    CHAIRPERSON: localize({
                        ru: '',
                        en: 'Chairperson of the Audit Committee'
                    })
                },
                strategy: {
                    MEMBER: localize({
                        ru: '',
                        en: 'Member of the Strategy Committee'
                    }),
                    CHAIRPERSON: localize({
                        ru: '',
                        en: 'Chairperson of the Strategy Committee'
                    })
                },
                compensation: {
                    MEMBER: localize({
                        ru: '',
                        en: 'Member of the Compensation Committee'
                    }),
                    CHAIRPERSON: localize({
                        ru: '',
                        en: 'Chairperson of the Compensation Committee'
                    })
                }
            }
        }
    },
    created () {
        this.$store.dispatch('BackText', this.text.back)
        this.$store.dispatch('BackLink', this.data['back-link'])
    }
}
</script>

<style lang="scss">
.bio-detail {
    @include margin-level(top, XS);

    &__detail {
        max-width: 1240px;
    }

    &__content {
        @include padding-level(top, L);
        @include padding-level(left, 3XL);
        @include padding-level(right, 3XL);

        @include breakpoint (v-tablet) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &__header {
        border-radius: 20px;
        display: flex;
        flex-wrap: wrap;
        background-color: $token-colors-gray-10;
    }

    &__info {
        overflow: hidden;
        width: 50%;
        @include padding-level(left, 3XL);
        @include padding-level(right, 3XL);
        @include padding-level(top, L);
        @include padding-level(bottom, L);
        display: flex;
        align-items: center;
        position: relative;

        &:before {
            content: '';
            display: block;
            position: absolute;
            z-index: 1;
            pointer-events: none;
            top: 0;
            left: 0;
            background-image: url(/images/bio-detail.svg);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: 50% 50%;
            opacity: 0.7;
            width: 20vw;
            height: 20vw;
            max-width: 360px;
            max-height: 360px;
            min-width: 300px;
            min-height: 300px;
            transform: translate(-10%, -10%);
        }

        @include breakpoint (v-tablet) {
            @include padding-level(left, M);
            @include padding-level(right, M);
            @include padding-level(top, M);
            @include padding-level(bottom, M);
        }

        @include breakpoint (mobile) {
            width: 100%;
            order: 2;
        }
    }

    &__info-container {
        position: relative;
        z-index: 2;
    }

    &__image {
        width: 50%;
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        border-radius: 20px;

        &:after {
            content: '';
            display: block;
            padding-top: 67%;
        }

        @include breakpoint (mobile) {
            width: 100%;
            order: 1;
        }
    }

    &__info-list {
        @include margin-level(top, S, true);
        margin-bottom: 0;
        overflow: hidden;
    }

    &__info-item {
        @include margin-level(top, S);
        display: flex;
        align-items: center;

        span {
            line-height: 1;
        }
    }
}
</style>
