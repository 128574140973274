<template>
    <div :class="[
        'calendar-card',
        classObject
    ]">
        <div class="calendar-card__container">
            <header class="calendar-card__header">
                <span class="calendar-card__tag" v-html="text[data.type]"></span>
            </header>
            <div class="calendar-card__content">
                <date-block
                    class="calendar-card__date"
                    :date="data.date.start"
                    :date-end="data.date.end"
                />
                <z-caption
                    tag="p"
                    size="4xl"
                    class="calendar-card__caption"
                >
                    <span v-html="data.name"></span>
                </z-caption>
            </div>
            <footer class="calendar-card__footer">
                <remind
                    class="calendar-card__remind"
                    v-if="data.type !== 'past' && data.remind"
                    :data="data.remind"
                >
                    <z-button kind="secondary" size="m">
                        <template v-slot:left>
                            <z-icon
                                name="calendar"
                                width="24"
                                height="24"
                            />
                        </template>
                        <span v-html="text.add"></span>
                    </z-button>
                </remind>

                <z-link
                    :href="data.link"
                    target="_blank"
                    v-if="data.link"
                    class="calendar-card__link"
                    icon="file"
                    inverted
                    :inline="false"
                >
                    <span v-html="text.open"></span>
                </z-link>
            </footer>
        </div>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'
import { calendarFormatDate } from '@/utils/mixin'

export default {
    name: 'calendar-card',
    mixins: [calendarFormatDate],
    props: {
        data: Object
    },
    computed: {
        classObject () {
            return [
                `calendar-card--theme-${this.data.type}`,
                { 'calendar-card--theme-qiwi': this.data.isQiwi }
            ]
        }
    },
    data () {
        return {
            text: {
                upcoming: localize({
                    ru: 'Ближайшие',
                    en: 'Upcoming'
                }),
                past: localize({
                    ru: 'Прошедшие',
                    en: 'Past'
                }),
                add: localize({
                    ru: 'Добавить в календарь',
                    en: 'Add to calendar'
                }),
                open: localize({
                    ru: 'Открыть',
                    en: 'Open'
                })
            }
        }
    }
}
</script>

<style lang="scss">
.calendar-card {
    $parent: &;

    background: $token-colors-tertiary;
    border-radius: 20px;
    color: $token-colors-white;

    &:before {
        content: '';
        padding-top: 100%;
        width: 0;
    }

    &__tag {
        padding: 4px $token-spacers-2-xs;
        color: inherit;
        border: 1px solid $token-colors-primary;
        color: $token-colors-primary;
        border-radius: 6px 6px 6px 0px;
        font-size: 16px;
    }

    &__date {
        color: $token-colors-primary;
    }

    &__caption {
        color: $token-colors-white !important;
    }

    &__header {
        @include margin-level(bottom, S);
        flex-shrink: 0;
        flex-grow: 0;
    }

    &__footer {
        flex-shrink: 0;
        flex-grow: 0;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        @include padding-level(top, XS);
    }

    &__date {
        @include margin-level(bottom, S);
    }

    &__container {
        display: flex;
        flex-direction: column;
        height: 100%;
        position: relative;
        z-index: 2;
        @include padding-level(top, M);
        @include padding-level(bottom, M);
        @include padding-level(left, M);
        @include padding-level(right, M);
    }

    &__content {
        flex-grow: 1;
        width: 100%;
    }

    &__remind {
        @include margin-level(right, S);
        @include margin-level(top, S);

        @include breakpoint(mobile) {
            margin-right: 0;
            width: 100%;

            .z-button {
                width: 100%;
            }
        }
    }

    &__link {
        color: inherit !important;
        @include margin-level(top, S);
    }

    &--theme {
        &-past {
            background: $token-colors-gray-10;
            color: $token-colors-tertiary;

            #{$parent}__caption {
                color: $token-colors-tertiary !important;
            }

            #{$parent}__date {
                color: $token-colors-tertiary;
            }

            #{$parent}__tag {
                border: 1px solid $token-colors-tertiary;
                color: $token-colors-tertiary;
            }
        }

        &-qiwi {
            background: #FFB861;
            position: relative;
            color: $token-colors-tertiary;

            #{$parent}__caption {
                color: $token-colors-tertiary !important;
            }

            #{$parent}__date {
                color: $token-colors-tertiary;
            }

            #{$parent}__tag {
                border: 1px solid $token-colors-tertiary;
                color: $token-colors-tertiary;
            }

            #{$parent}__link {
                color: $token-colors-tertiary;
            }

            &:after {
                content: '';
                border-radius: 20px;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background-image: url(/images/logo/qiwi.svg);
                background-repeat: no-repeat;
                opacity: 0.4;
                background-size: 68% auto;
                background-position: 0 0;
                background-position: -33% calc(100% - 32px);
                z-index: 0;
                pointer-events: none;
            }

            &#{$parent}--theme-past {
                background: #FFEAD1;

                &:after {
                    opacity: 0.15;
                }
            }
        }
    }
}
</style>
