<template>
    <div class="investment-case" v-if="data && data.length">
        <anchor-menu :data="anchorData" class="hide-me--tablet"/>
        <div class="u-top-margin--m">
            <div
                v-for="(item, index) in data"
                :key="item.id"
                :id="item.id"
                :class="[
                    'investment-case__container js-anchor-container',
                    { 'investment-case__container--last': index + 1 === data.length }
                ]"
                :data-anchor="item.id"
            >
                <div class="investment-case__counter hide-me--mobile">
                    <div class="investment-case-number">
                        <span>{{ index + 1 }}</span>
                    </div>
                </div>
                <div class="investment-case__info">
                    <z-caption tag="h2" size="5xl" class="investment-case__title u-bottom-margin--s-important" v-if="item.name">
                        <div class="investment-case-number investment-case-number--mobile">
                            <span>{{ index + 1 }}</span>
                        </div>
                        <span v-html="item.name"></span>
                    </z-caption>
                    <z-lead class="u-bottom-margin--s-important" v-if="item.lead">
                        <span v-html="item.lead"></span>
                    </z-lead>
                    <div>
                        <z-list v-if="item.list">
                            <z-list-item
                                v-for="(item, index) in item.list"
                                :key="index"
                            >
                                <span v-html="item"></span>
                            </z-list-item>
                        </z-list>
                        <div class="u-top-margin--m" v-if="item.detail" v-html="item.detail"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'investment-case',
    props: {
        data: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        anchorData () {
            return this.data.map(item => {
                return {
                    id: item.id,
                    name: item['short-name'] ? item['short-name'] : item.name
                }
            })
        }
    }
}
</script>

<style lang="scss">
.investment-case {
    $parent: &;

    &__container {
        display: flex;

        &--last {
            #{$parent}__counter {
                &:before {
                    display: none;
                }
            }
        }
    }

    &__counter {
        flex-shrink: 0;
        position: relative;
        overflow: hidden;

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 60px;
            @include margin-level(top, XS);
            width: 1px;
            height: 100%;
            background-color: $token-colors-primary;
            left: 50%;

            @include breakpoint (mobile) {
                top: 40px;
            }
        }

        &:after {
            content: '';
            width: 100%;
            @include padding-level(top, XS);
            background: $token-colors-white;
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }

    &__info {
        @include padding-level(bottom, 2XL);
        @include padding-level(top, 2XS);
        @include padding-level(left, XL);

        @include breakpoint (mobile) {
            padding: 0;
            @include padding-level(bottom, L);
        }
    }

    &__title {
        .z-caption__text {
            @include breakpoint (mobile) {
                display: flex;
                align-items: center;
            }
        }
    }
}

.investment-case-number {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $token-colors-primary;
    color: $token-colors-white;
    font-size: 36px;
    font-weight: 400;
    border-radius: 50%;
    flex-shrink: 0;

    @include breakpoint (mobile) {
        width: 40px;
        height: 40px;
        font-size: 22px;
    }

    &--mobile {
        display: none;

        @include breakpoint (mobile) {
            display: inline-flex;
            margin-right: 16px;
        }
    }
}
</style>
