/* eslint-disable */
require('./arrow-thin-right')
require('./arrow-up')
require('./arrows')
require('./audio')
require('./back')
require('./burger')
require('./calendar')
require('./cardtocard')
require('./chairperson')
require('./check-circle')
require('./checked')
require('./clock')
require('./coin_rub')
require('./coin_usd')
require('./contact_color')
require('./contact_grey')
require('./contact')
require('./cookies')
require('./cross')
require('./database')
require('./double-arrow-right')
require('./e-port')
require('./esg')
require('./external_table')
require('./external')
require('./factoring_plus')
require('./favorite')
require('./file')
require('./fintech')
require('./flock')
require('./flocktory_color')
require('./flocktory_grey')
require('./flocktory')
require('./flocktory')
require('./google')
require('./graphick')
require('./headphones')
require('./help')
require('./ical')
require('./iintellect_circle')
require('./intellect_money')
require('./link')
require('./load')
require('./logo')
require('./mail')
require('./member')
require('./minus-circle')
require('./money_transfers')
require('./moscow_exchange')
require('./nasdaq')
require('./open_api')
require('./osmp')
require('./outlook')
require('./payments')
require('./people')
require('./person')
require('./phone')
require('./plus-circle')
require('./prepaid_card')
require('./presentation')
require('./qiwi_business_color')
require('./qiwi_business_grey')
require('./qiwi_circle')
require('./qiwi')
require('./quotes')
require('./realweb')
require('./release')
require('./report')
require('./rialweb_circle')
require('./rocketbank')
require('./rowi_circle')
require('./rowi_color')
require('./rowi_grey')
require('./search')
require('./self_service_kiosk')
require('./sign-in')
require('./social')
require('./sovest_circle')
require('./sovest')
require('./ta')
require('./taxi_circle')
require('./tochka_bank')
require('./visa')
require('./vision')
