/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sovest': {
    width: 36,
    height: 36,
    viewBox: '0 0 36 36',
    data: '<g clip-path="url(#clip0_5083_45017)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M13.103 23.588a7.17 7.17 0 01-1.843-5.168l.03-.08a4.57 4.57 0 010-.74 7.161 7.161 0 017.53-6.79A8.26 8.26 0 0124.5 13V9.51a12.15 12.15 0 00-5.77-1.46c-6 0-10.64 4.02-10.64 10.33 0 6.31 4.64 10.31 10.64 10.31 2 .002 3.97-.482 5.74-1.41v-3.53a8.35 8.35 0 01-5.68 2.19h-.73a7.17 7.17 0 01-4.957-2.352zM5.58 10.178a14.69 14.69 0 00-2.49 8.152l-.03.05a14.68 14.68 0 0014.73 14.67A14.69 14.69 0 105.58 10.178zm-.188-4.37A17.375 17.375 0 0117.79 1h.83a17.374 17.374 0 01-.78 34.74H17A17.374 17.374 0 015.392 5.808z" _fill="#2F363F"/></g><defs><clipPath id="clip0_5083_45017"><path pid="1" _fill="#fff" d="M0 0h36v36H0z"/></clipPath></defs>'
  }
})
