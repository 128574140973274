<template>
    <div class="z-pagination" v-if="data && data.total > 1">
        <div class="z-pagination__list">
            <li
                v-if="showPrev"
                :class="[
                    'z-pagination__item',
                    { 'is-disabled' : data.current === 1 }
                ]"
                @click="changePage(prevPage)"
                key="prev"
            >
                <z-icon
                    class="z-pagination__icon"
                    name="arrow-up"
                    width="24"
                    height="24"
                    dir="left"
                />
            </li>
            <template v-if="hasFirst">
                <li
                    class="z-pagination__item"
                    @click="changePage(1)"
                    key="first"
                >
                    <span class="z-pagination__number z-pagination__number--single">1</span>
                </li>

                <li class="z-pagination__item is-disabled" v-if="isDividerShown('start')">
                    <span class="z-pagination__divider">
                        ...
                    </span>
                </li>
            </template>
            <li
                :class="[
                    'z-pagination__item',
                    { 'is-active' : item === data.current }
                ]"
                v-for="(item, index) in pages"
                :key="`${item}-${index}`"
                @click="changePage(item)"
            >
                <span :class="['z-pagination__number', getNumberClass(item)]">{{ item }}</span>
            </li>
            <template v-if="hasLast">
                <li class="z-pagination__item is-disabled" v-if="isDividerShown('end')">
                    <span class="z-pagination__divider z-pagination__divider--last">
                        ...
                    </span>
                </li>

                <li
                    class="z-pagination__item"
                    @click="changePage(data.total)"
                    key="last"
                >
                    <span :class="['z-pagination__number', getNumberClass(data.total)]">{{ data.total }}</span>
                </li>
            </template>
            <li
                v-if="showNext"
                :class="[
                    'z-pagination__item',
                    { 'is-disabled' : data.current === data.total }
                ]"
                @click="changePage(nextPage)"
                key="next"
            >
                <z-icon
                    class="z-pagination__icon"
                    name="arrow-up"
                    width="24"
                    height="24"
                    dir="right"
                />
            </li>
        </div>
    </div>
</template>

<script>
/* eslint-disable vue/no-side-effects-in-computed-properties */
export default {
    name: 'z-pagination',
    props: {
        data: Object,
        pageRange: {
            type: Number,
            default: 1
        },
        showPrev: {
            type: Boolean,
            default: true
        },
        showNext: {
            type: Boolean,

            default: true
        }
    },
    data () {
        return {
            start: null,
            end: null
        }
    },
    computed: {
        pages () {
            let pages = []
            let start = (this.data.current - this.pageRange) > 0 ? this.data.current - this.pageRange : 1
            let end = (this.data.current + this.pageRange) <= this.data.total ? this.data.current + this.pageRange : this.data.total

            if (start === 1 && end + 1 <= this.data.total && this.data.current === 1) {
                end += 1
            }

            if (end === this.data.total && start - 1 >= 1 && this.data.current !== this.data.total - 1) {
                start -= 1
            }

            for (let i = start; i <= end; i++) {
                pages.push(i)
            }

            this.start = start
            this.end = end

            return pages
        },
        rangeStart () {
            let start = (this.data.current - this.pageRange) > 0 ? this.data.current - this.pageRange : 1

            return (start > 0) ? start : 1
        },
        rangeEnd () {
            let end = this.data.current + this.pageRange

            return (end < this.data.total) ? end : this.data.total
        },
        prevPage () {
            return this.data.current - 1
        },
        nextPage () {
            return this.data.current + 1
        },
        hasFirst () {
            if (!this.start) return false
            return this.start !== 1
        },
        hasLast () {
            if (!this.end) return false
            return this.end !== this.data.total
        }
    },
    methods: {
        getNumberClass (item) {
            let number = 'z-pagination__number--single'

            if (String(item).length === 2) {
                number = 'z-pagination__number--double'
            }

            if (String(item).length >= 3) {
                number = 'z-pagination__number--triple'
            }

            return number
        },
        changePage (page) {
            if (page > 0 && page <= this.data.total) {
                this.$emit('change-page', page)
            }
        },
        isDividerShown (type) {
            let result = false
            if (type === 'start') {
                result = (this.pages[0] - 1) !== 1
            }
            if (type === 'end') {
                result = (this.pages[this.pages.length - 1] + 1) !== this.data.total
            }

            return result
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
