/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'factoring_plus': {
    width: 72,
    height: 76,
    viewBox: '0 0 72 76',
    data: '<ellipse pid="0" cx="36" cy="37.601" rx="36" ry="37.601" _fill="#F5F5F5"/><path pid="1" d="M32.418 57.959V40.812l6.567-6.859h16.417v6.859H38.164v17.147h-5.746z" _fill="#101012"/><path pid="2" d="M32.418 16.806v24.006l6.567-6.859V16.806h-6.567z" _fill="#E95629"/><path pid="3" d="M32.417 40.812v-6.859H16v6.859h16.417z" _fill="#4659DA"/>'
  }
})
