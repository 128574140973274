/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'double-arrow-right': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M17.69 12l-5.47-5.47a.75.75 0 011.06-1.06l6 6a.75.75 0 010 1.06l-6 6a.75.75 0 11-1.06-1.06L17.69 12zm-7 0L5.22 6.53a.75.75 0 011.06-1.06l6 6a.75.75 0 010 1.06l-6 6a.75.75 0 01-1.06-1.06L10.69 12z" _fill="#2F363F"/>'
  }
})
