<template>
    <div
        class="navigation-top"
        role="navigation"
        aria-label="Top navigation"
        :class="{'navigation-top--index': isIndex}"
    >
        <ul class="navigation-top__list">
            <li
                class="navigation-top__item"
                v-for="(item, index) in data"
                :key="index"
                :class="{'is-active': item.active}"
            >
                <z-button
                    v-if="item.params.type === 'button'"
                    tag="a"
                    :href="item.link"
                    size="xs"
                >{{item.name}}</z-button>
                <z-link
                    v-else-if="item.params.type === 'link'"
                    :href="item.link"
                    :external="item.params.external"
                    theme="black"
                >{{item.name}}</z-link>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'navigation-top',
    props: {
        isIndex: Boolean
    },
    data () {
        return {
            data: this.$root.app.components.navigation.top
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
