/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'burger': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M1 7h22a1 1 0 100-2H1a1 1 0 000 2zm0 6h22a1 1 0 100-2H1a1 1 0 100 2zm22 6H1a1 1 0 110-2h22a1 1 0 110 2z" _fill="#2F363F"/>'
  }
})
