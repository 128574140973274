/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'social/youtube': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<circle pid="0" cx="16" cy="16" r="16" _fill="#2F363F"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M24 15.333s0-2.79-.366-4.126a2.175 2.175 0 00-1.55-1.517C20.717 9.333 16 9.333 16 9.333s-4.714 0-6.084.357a2.176 2.176 0 00-1.55 1.517C8 12.543 8 15.333 8 15.333s0 2.79.366 4.127c.2.736.794 1.317 1.55 1.516 1.37.357 6.084.357 6.084.357s4.717 0 6.084-.357a2.176 2.176 0 001.55-1.516C24 18.123 24 15.333 24 15.333zm-4.8 0l-4.8 2.625v-5.25l4.8 2.625z" _fill="#fff"/>'
  }
})
