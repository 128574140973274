/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'report': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M9 1a2 2 0 00-2 2H6a3 3 0 00-3 3v14a3 3 0 003 3h12a3 3 0 003-3V6a3 3 0 00-3-3h-1a2 2 0 00-2-2H9zm8 4a2 2 0 01-2 2H9a2 2 0 01-2-2H6a1 1 0 00-1 1v14a1 1 0 001 1h12a1 1 0 001-1V6a1 1 0 00-1-1h-1zM9 4v1h6V3H9v1zm1 13.75a.75.75 0 00.75-.75v-6a.75.75 0 00-1.5 0v6c0 .414.336.75.75.75zm4.75-.75a.75.75 0 01-1.5 0v-2a.75.75 0 011.5 0v2z" _fill="#FF8C00"/>'
  }
})
