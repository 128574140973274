/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sovest_circle': {
    width: 72,
    height: 76,
    viewBox: '0 0 72 76',
    data: '<ellipse pid="0" cx="36" cy="37.601" rx="36" ry="37.601" _fill="#F5F5F5"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M28.654 46.356c-1.913-2.21-2.907-5.122-2.764-8.097l.045-.126a7.478 7.478 0 010-1.159c.071-1.474.42-2.918 1.026-4.251a11.194 11.194 0 012.508-3.516 10.658 10.658 0 013.607-2.243 10.322 10.322 0 014.154-.628c3.155-.03 6.202 1.196 8.52 3.431V24.3a17.614 17.614 0 00-8.655-2.287c-9 0-15.96 6.298-15.96 16.184 0 9.886 6.96 16.153 15.96 16.153a17.88 17.88 0 008.61-2.21v-5.53c-2.325 2.223-5.368 3.448-8.52 3.431H36.09c-2.848-.15-5.523-1.475-7.436-3.684zM17.37 25.346c-2.426 3.778-3.725 8.223-3.734 12.772l-.045.078a23.89 23.89 0 001.688 8.815 23.09 23.09 0 004.793 7.466 21.977 21.977 0 007.168 4.976 21.206 21.206 0 008.446 1.727c4.356-.004 8.612-1.355 12.233-3.883 3.62-2.53 6.443-6.122 8.11-10.325a23.967 23.967 0 001.255-13.29c-.848-4.462-2.944-8.561-6.021-11.78-3.078-3.22-7-5.413-11.271-6.305a21.164 21.164 0 00-12.726 1.293c-4.026 1.735-7.47 4.678-9.896 8.456zm-.281-6.846c4.997-4.988 11.687-7.697 18.597-7.533h1.245c6.91.162 13.476 3.184 18.252 8.403 4.777 5.218 7.373 12.204 7.218 19.421-.155 7.218-3.049 14.075-8.045 19.064-4.996 4.99-11.685 7.701-18.595 7.539H34.5c-6.91-.164-13.475-3.189-18.25-8.408-4.775-5.22-7.37-12.207-7.213-19.424.158-7.218 3.053-14.075 8.05-19.062z" _fill="#2F363F"/>'
  }
})
