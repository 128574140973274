<template>
    <div
        :href="link"
        :class="classObject"
        @click="$emit('click')"
        :is="link ? 'a' : 'div'"
        :target="buildTarget(link)"
    >
        <div class="z-card__body">
            <header
                class="z-card__header"
                v-if="this.$slots['top']"
            >
                <slot name="top" />
            </header>
            <div class="z-card__main">
                <span
                    class="z-card__title"
                    v-if="this.$slots['title']"
                >
                    <slot name="title" />
                </span>
                <div
                    v-if="this.$slots['content']"
                    class="z-card__content"
                >
                    <slot name="content"></slot>
                </div>
            </div>
            <footer
                class="z-card__footer"
                v-if="this.$slots['bottom'] || this.$slots['bottom-left'] || this.$slots['bottom-right']"
            >
                <div class="z-card__bottom">
                    <slot name="bottom" />
                </div>
                <div
                    class="z-card__bottom-left"
                    v-if="this.$slots['bottom-left']"
                >
                    <slot name="bottom-left" />
                </div>
                <div
                    class="z-card__bottom-right"
                    v-if="this.$slots['bottom-right']"
                >
                    <slot name="bottom-right" />
                </div>
            </footer>
        </div>
        <div v-if="decorRight || decorLeft" :style="styleObject" class="z-card__decor"></div>
    </div>
</template>

<script>
export default {
    name: 'z-card',
    props: {
        link: String,
        theme: {
            type: String,
            validator: prop => ['dark'].includes(prop)
        },
        fullHeight: Boolean,
        small: Boolean,
        bordered: Boolean,
        decorImage: String,
        decorRight: Boolean,
        decorLeft: Boolean,
        decorPadding: {
            type: String,
            default: 'bottom',
            validator: prop => ['bottom', 'none', 'right', 'left'].includes(prop)
        },
        decorSize: {
            type: String,
            default: 'm',
            validator: prop => ['s', 'm', 'l', 'xl'].includes(prop)
        }
    },
    methods: {
        buildTarget (href) {
            return /^http|^\/\//.test(href) ? '_blank' : false
        }
    },
    computed: {
        styleObject () {
            let obj = {}
            if (this.decorImage) {
                obj.backgroundImage = `url(${this.decorImage})`
            }
            return obj
        },
        classObject () {
            return [
                'z-card',
                {
                    'z-card--link': this.link,
                    'z-card--full-height': this.fullHeight,
                    'z-card--small': this.small,
                    'z-card--decor-left': this.decorLeft,
                    'z-card--decor-right': this.decorRight,
                    [`z-card--decor-padding-${this.decorPadding}`]: this.decorPadding,
                    'z-card--bordered': this.bordered,
                    [`z-card--theme-${this.theme}`]: this.theme,
                    [`z-card--decor-${this.decorSize}`]: this.decorSize
                }
            ]
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
