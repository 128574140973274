/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'coin_rub': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<path pid="0" d="M24 5.385c10.265 0 18.615 8.35 18.615 18.615 0 10.265-8.35 18.615-18.615 18.615-10.265 0-18.615-8.35-18.615-18.615 0-10.265 8.35-18.615 18.615-18.615zM24 46c12.13 0 22-9.87 22-22S36.13 2 24 2 2 11.87 2 24s9.87 22 22 22z" _fill="#FF8C00"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M24 40c8.837 0 16-7.163 16-16S32.837 8 24 8 8 15.163 8 24s7.163 16 16 16zm-5.658-27.134h7.319c4.186 0 7.126 2.812 7.126 6.551 0 3.74-2.94 6.583-7.094 6.583h-3.74v2.014h6.776v2.78h-6.775v3.483h-3.612v-3.483h-2.556v-2.78h2.556V26h-2.556v-2.78h2.556V12.866zm10.802 6.551c0-1.981-1.278-3.451-3.58-3.451h-3.61v6.935h3.61c2.27 0 3.58-1.502 3.58-3.484z" _fill="#FF8C00"/>'
  }
})
