/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'external': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M18.5 5.75V5h-5.75v1.5h3.19l-4.72 4.72 1.06 1.06L17 7.56v3.19h1.5v-5zm-7.75-.676a6.75 6.75 0 107.675 7.676h-1.521a5.25 5.25 0 11-6.154-6.154V5.074z" _fill="#2F363F"/>'
  }
})
