/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'person': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M22.5 7.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0zm7 18.062c0 7.25-27 7.25-27 0 0-12.815 27-12.684 27 0z" _fill="#2F363F"/>'
  }
})
