<template>
    <div
        class="date-block"
        :class="{
            'date-block--size-sm' : small,
            'date-block--type-vertical' : isVertical
        }"
    >
        <div class="date-block__item" v-if="date">
            <span class="date-block__date">
                {{ date | momentFilter('DD') }}
            </span>
            <span class="date-block__wrapper">
                <span class="date-block__month">
                    {{ date | momentFilter('MMMM') }}
                </span>
                <span :class="[
                    'date-block__year',
                    { 'date-block__year--hidden' : !year }
                ]">
                    {{ date | momentFilter('YYYY') }}
                </span>
            </span>
        </div>
        <div class="date-block__item" v-if="dateEnd">
            <span class="date-block__date">
                {{ dateEnd | momentFilter('DD') }}
            </span>
            <span class="date-block__wrapper">
                <span class="date-block__month">
                    {{ dateEnd | momentFilter('MMMM') }}
                </span>
                <span :class="[
                    'date-block__year',
                    { 'date-block__year--hidden' : !year }
                ]">
                    {{ dateEnd | momentFilter('YYYY') }}
                </span>
            </span>
        </div>
    </div>
</template>

<script>
import moment from 'moment'

export default {
    name: 'date-block',
    props: {
        date: [String, Number, Boolean],
        dateEnd: [String, Number, Boolean],
        isVertical: Boolean,
        year: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            lang: this.$root.lang,
            small: !!this.dateEnd
        }
    },
    created () {
        moment.locale(this.lang)
    }
}
</script>

<style lang="scss">
.date-block {
    color: $token-colors-tertiary;
    font-size: 18px;
    display: flex;
    flex-wrap: wrap;

    &__item {
        display: flex;
        position: relative;
    }

    &__date {
        font-size: 4em;
        line-height: 1;
        font-weight: 100;
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 0.25em;
        margin-left: 0.2em;
    }

    &__month {
        font-size: 1em;
        line-height: 1.5;
        text-transform: capitalize;
        border-bottom: 1px solid;
    }

    &__year {
        font-size: 1em;
        line-height: 1.5;

        &--hidden {
            display: none;
        }
    }

    &--size-sm {
        .date-block__date {
            font-size: 3em;
        }

        .date-block__wrapper {
            margin-top: 0.0875em;
        }

        .date-block__month {
            font-size: 0.875em;
        }

        .date-block__year {
            font-size: 0.875em;
        }

        .date-block__item:first-child {
            margin-right: 50px;

            &:after {
                content: '';
                position: absolute;
                right: -35px;
                height: 2px;
                width: 16px;
                background-color: currentColor;
                position: absolute;
                top: 50%;
                margin-top: -2px;
            }
        }
    }
}

.date-block--type-vertical {
    justify-content: flex-end;
    flex-direction: column;

    .date-block__date {
        width: 63px;
        text-align: center;
    }

    .date-block__item {
        &:first-child {
            margin-right: 0;

            &:after {
                display: none;
            }
        }

        + .date-block__item {
            padding-top: 15px;
            margin-top: 15px;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 30px;
                width: 6px;
                height: 6px;
                background: currentColor;
                border-radius: 50%;
            }
        }
    }
}

@include breakpoint(mobile) {
    .date-block--size-sm {
        flex-wrap: wrap;
    }

    .date-block__item {
        + .date-block__item {
            margin-left: 0;
            width: 100%;
        }
    }
}
</style>
