/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'payments': {
    width: 72,
    height: 76,
    viewBox: '0 0 72 76',
    data: '<ellipse pid="0" cx="36" cy="37.601" rx="36" ry="37.601" _fill="#F5F5F5"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M34.108 50.991c-2.592-.288-5.364-1.44-7.2-3.384l2.484-3.492c1.548 1.476 4.068 2.7 6.372 2.7 1.944 0 3.744-1.008 3.744-3.096 0-1.847-2.225-2.826-4.772-3.946-3.41-1.499-7.396-3.252-7.396-7.682 0-3.708 2.772-6.588 6.768-7.236v-3.384h3.06v3.312c2.304.18 4.536 1.008 6.336 2.628l-2.016 3.78c-1.404-1.224-3.6-2.232-5.616-2.232-2.16 0-3.888 1.26-3.888 3.06 0 1.806 2.163 2.716 4.664 3.77 3.433 1.444 7.504 3.158 7.504 7.822 0 3.672-2.556 6.804-6.984 7.38v3.312h-3.06v-3.312z" _fill="#FF8C00"/>'
  }
})
