var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data && _vm.data.total > 1)?_c('div',{staticClass:"z-pagination"},[_c('div',{staticClass:"z-pagination__list"},[(_vm.showPrev)?_c('li',{key:"prev",class:[
                'z-pagination__item',
                { 'is-disabled' : _vm.data.current === 1 }
            ],on:{"click":function($event){return _vm.changePage(_vm.prevPage)}}},[_c('z-icon',{staticClass:"z-pagination__icon",attrs:{"name":"arrow-up","width":"24","height":"24","dir":"left"}})],1):_vm._e(),_vm._v(" "),(_vm.hasFirst)?[_c('li',{key:"first",staticClass:"z-pagination__item",on:{"click":function($event){return _vm.changePage(1)}}},[_c('span',{staticClass:"z-pagination__number z-pagination__number--single"},[_vm._v("1")])]),_vm._v(" "),(_vm.isDividerShown('start'))?_c('li',{staticClass:"z-pagination__item is-disabled"},[_c('span',{staticClass:"z-pagination__divider"},[_vm._v("\n                    ...\n                ")])]):_vm._e()]:_vm._e(),_vm._v(" "),_vm._l((_vm.pages),function(item,index){return _c('li',{key:(item + "-" + index),class:[
                'z-pagination__item',
                { 'is-active' : item === _vm.data.current }
            ],on:{"click":function($event){return _vm.changePage(item)}}},[_c('span',{class:['z-pagination__number', _vm.getNumberClass(item)]},[_vm._v(_vm._s(item))])])}),_vm._v(" "),(_vm.hasLast)?[(_vm.isDividerShown('end'))?_c('li',{staticClass:"z-pagination__item is-disabled"},[_c('span',{staticClass:"z-pagination__divider z-pagination__divider--last"},[_vm._v("\n                    ...\n                ")])]):_vm._e(),_vm._v(" "),_c('li',{key:"last",staticClass:"z-pagination__item",on:{"click":function($event){return _vm.changePage(_vm.data.total)}}},[_c('span',{class:['z-pagination__number', _vm.getNumberClass(_vm.data.total)]},[_vm._v(_vm._s(_vm.data.total))])])]:_vm._e(),_vm._v(" "),(_vm.showNext)?_c('li',{key:"next",class:[
                'z-pagination__item',
                { 'is-disabled' : _vm.data.current === _vm.data.total }
            ],on:{"click":function($event){return _vm.changePage(_vm.nextPage)}}},[_c('z-icon',{staticClass:"z-pagination__icon",attrs:{"name":"arrow-up","width":"24","height":"24","dir":"right"}})],1):_vm._e()],2)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }