/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'quotes': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M0 6.545C0 4.035 2.061 2 4.604 2 15.248 2 10.954 22 0 22v-2.121c6.138 0 9.207-8.788 4.604-8.788C2.06 11.09 0 9.056 0 6.545zm13.263 0c0-2.51 2.061-4.545 4.604-4.545 10.644 0 6.35 20-4.604 20v-2.121c6.138 0 9.208-8.788 4.604-8.788-2.543 0-4.604-2.035-4.604-4.546z" _fill="#FF8200"/>'
  }
})
