<template>
    <label class="z-switch" :for="id">
        <span class="z-switch__view">
            <span class="z-switch__view-item" v-if="changed">
                <slot name="left" />
            </span>
            <span class="z-switch__view-item" v-else-if="!changed">
                <slot name="right" />
            </span>
        </span>
        <span class="z-switch__controls">
            <span class="z-switch__item" :class="{'z-switch__item--disabled': disabled}">
                <span class="z-switch__wrapper">
                    <input
                        class="z-switch__input"
                        type="checkbox"
                        :name="id"
                        :id="id"
                        :checked="changed"
                        :disabled="disabled"
                        @change="onChange($event.target.checked)"
                    />
                    <span class="z-switch__slide"></span>
                </span>
                <span class="z-switch__text" v-html="title"></span>
            </span>
        </span>
    </label>
</template>

<script>
export default {
    name: 'z-switch',
    props: {
        check: {
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        id: {
            type: String,
            required: true
        }
    },
    data () {
        return {
            changed: this.check
        }
    },
    methods: {
        onChange (checked) {
            this.$emit('change', checked)
            this.changed = !this.changed
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
