/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'flock': {
    width: 72,
    height: 76,
    viewBox: '0 0 72 76',
    data: '<ellipse pid="0" cx="36" cy="37.601" rx="36" ry="37.601" _fill="#2F363F"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M11.377 37.782c0-5.099 4.348-9.313 9.811-9.313 5.464 0 9.812 4.214 9.812 9.313 0 5.1-4.348 9.314-9.812 9.314-5.463 0-9.811-4.214-9.811-9.313zm9.811-11.313c-6.478 0-11.811 5.021-11.811 11.313 0 6.293 5.333 11.314 11.811 11.314C27.667 49.096 33 44.075 33 37.783c0-6.293-5.333-11.314-11.812-11.314zm17.276.228h-1V48.93H60.91V26.697H38.464zm1 20.233V28.697H58.91V46.93H39.464z" _fill="#fff"/>'
  }
})
