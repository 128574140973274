<template>
    <div class="z-wide-section">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'z-wide-section'
}
</script>

<style lang="scss" src="./index.scss"></style>
