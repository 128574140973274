<template>
    <transition
        @before-enter="appearAnimationBefore"
        @enter="appearAnimationEnter"
        @leave="appearAnimationLeave"
    >
        <div
            class="cookie-popup"
            v-if="visible"
        >
            <div class="cookie-popup__body">
                <div class="cookie-popup__text" v-html="text.description">
                </div>
                <div class="cookie-popup__buttons">
                    <div class="cookie-popup__button">
                        <z-button
                            @click="submit"
                        >{{ text.button.agree }}</z-button>
                    </div>
                    <div class="cookie-popup__button">
                        <z-button
                            tag="a"
                            target="_blank"
                            kind="secondary"
                            href="/cookie-policy/"
                        >{{ text.button.more }}</z-button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import Velocity from 'velocity-animate'
const cookie = require('cookie_js')

export default {
    name: 'cookie-popup',
    data () {
        return {
            duration: 500,
            visible: false,
            text: {
                description: this.$root.lang === 'ru' ? '«QIWI» использует файлы «cookie» для удобства пользования веб-сайтом. «Cookie» представляют собой небольшие файлы, содержащие информацию о предыдущих посещениях веб-сайта. Продолжая использовать наш сайт, вы даете согласие на их обработку.' : 'QIWI uses “strictly necessary” cookies, that are always active, to&nbsp;make our site work. We&nbsp;would also like with your consent to&nbsp;set optional “analytics” cookies to&nbsp;gather anonymous site visitation data and “marketing” cookies to&nbsp;help&nbsp;us understand which content visitors value most. For more information about QIWI&nbsp;cookies, see our <a href="/cookie-policy/">Cookie Policy.</a>',
                button: {
                    agree: this.$root.lang === 'ru' ? 'Согласен' : 'I agree',
                    more: this.$root.lang === 'ru' ? 'Подробнее' : 'Read more'
                }
            }
        }
    },
    mounted () {
        this.visible = !cookie.get('cookies_policy')
    },
    methods: {
        submit () {
            cookie.set({
                cookies_policy: true
            }, {
                domain: '.' + location.host,
                path: '/',
                expires: 365
            })

            this.$nextTick(() => { this.visible = false })
        },
        appearAnimationBefore (el) {
            el.style.opacity = 0
        },
        appearAnimationEnter (el, done) {
            Velocity(el, { opacity: 1 }, { duration: this.duration, delay: this.duration }, { complete: done })
        },
        appearAnimationLeave (el, done) {
            Velocity(el, { opacity: 0 }, { duration: this.duration })
            Velocity(el, { display: 'none' }, { complete: done })
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
