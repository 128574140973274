/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'vision': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M22 12s-3.556 6-10 6c-5.983 0-10-6-10-6s3.692-6 10-6c6.412 0 10 6 10 6zm-3.92-1.5C16.296 8.922 14.266 8 12 8c-2.219 0-4.244.924-6.047 2.504-.532.466-1.01.961-1.432 1.46.457.519.972 1.034 1.536 1.517C7.916 15.072 9.925 16 12 16c2.279 0 4.311-.922 6.09-2.498.54-.477 1.023-.986 1.441-1.498a13.171 13.171 0 00-1.45-1.505zM9 12a3 3 0 106 0 3 3 0 00-6 0z" _fill="#2F363F"/>'
  }
})
