/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'database': {
    width: 27,
    height: 27,
    viewBox: '0 0 27 27',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7.045 7.352c-.26.262-.295.437-.295.523 0 .086.036.261.295.523.263.268.7.56 1.33.83 1.254.537 3.065.897 5.125.897s3.871-.36 5.125-.898c.63-.27 1.067-.561 1.33-.829.26-.262.295-.437.295-.523 0-.086-.036-.261-.295-.523-.263-.268-.7-.56-1.33-.83-1.254-.537-3.065-.897-5.125-.897s-3.871.36-5.125.898c-.63.27-1.067.561-1.33.829zm15.455.523c0-.846-.405-1.559-.943-2.104-.534-.54-1.25-.975-2.045-1.317-1.596-.683-3.722-1.079-6.012-1.079-2.29 0-4.416.396-6.012 1.08-.796.34-1.512.776-2.045 1.316-.538.545-.943 1.258-.943 2.104V14.626h.251H4.5v4.5c0 .846.405 1.559.943 2.104.533.54 1.25.975 2.045 1.317 1.596.684 3.722 1.079 6.012 1.079 2.29 0 4.416-.395 6.012-1.08.796-.34 1.511-.776 2.045-1.316.538-.545.943-1.258.943-2.104V14.625h-.251.251V7.875zm-2.25 3.063c-.236.129-.483.248-.738.357-1.596.684-3.722 1.08-6.012 1.08-2.29 0-4.416-.396-6.012-1.08a8.506 8.506 0 01-.738-.357V13.5c0 .086.036.261.295.524.263.267.7.559 1.33.828 1.254.538 3.065.898 5.125.898s3.871-.36 5.125-.898c.63-.27 1.067-.561 1.33-.829.26-.262.295-.437.295-.523V10.938zm0 5.625c-.236.129-.483.248-.738.358C17.916 17.605 15.79 18 13.5 18c-2.29 0-4.416-.395-6.012-1.08a8.506 8.506 0 01-.738-.357V19.125c0 .086.036.261.295.523.263.268.7.56 1.33.83 1.254.537 3.065.897 5.125.897s3.871-.36 5.125-.898c.63-.27 1.067-.561 1.33-.829.26-.262.295-.437.295-.523V16.563z" _fill="#FF8C00"/>'
  }
})
