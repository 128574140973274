<template>
    <div
        class="z-image"
        :class="classObject"
        :style="backgroundStyles"
    >
        <img
            v-if="!background"
            :src="src"
            class="z-image__img"
            :style="imageStyles"
        >

        <div
            v-if="overlay"
            class="z-image__overlay"
            :style="{background: overlay}"
        ></div>
    </div>
</template>

<script>
export default {
    name: 'z-image',
    props: {
        src: {
            type: String,
            required: true
        },
        overlay: String,
        background: {
            type: Boolean,
            default: false
        },
        width: String,
        height: String,
        decorRight: Boolean,
        decorLeft: Boolean,
        decorSize: {
            type: String,
            default: 'm',
            validator: prop => ['s', 'm', 'l', 'xl'].includes(prop)
        }
    },
    computed: {
        classObject () {
            return [
                'z-image',
                {
                    'z-image--background': this.background,
                    'z-image--decor-left': this.decorLeft,
                    'z-image--decor-right': this.decorRight,
                    [`z-image--decor-${this.decorSize}`]: this.decorSize
                }
            ]
        },
        backgroundStyles () {
            return {
                width: this.background ? this.width : null,
                height: this.background ? this.height : null,
                'background-image': this.background ? `url(${this.src})` : null
            }
        },
        imageStyles () {
            return {
                width: !this.background ? this.width : null,
                height: !this.background ? this.height : null
            }
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
