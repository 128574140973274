<template src="./index.html"></template>

<script>
import { mixinDevice } from '@/utils/mixin'

export default {
    name: 'z-logo',
    mixins: [mixinDevice],
    props: {
        isIndex: {
            type: Boolean
        },
        siteDir: {
            type: String,
            default: '/',
            required: true
        },
        theme: {
            type: String,
            default: ''
        },
        size: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            title: this.$root.lang === 'ru' ? 'Перейти на главную страницу' : 'Go to mainpage'
        }
    },
    computed: {
        getBurgerMenu () {
            return this.$store.getters.burgerMenu
        },
        buildSrc () {
            let src = './images/logo'

            src += `-${this.$root.lang}`

            if (this.device !== 'desktop') {
                src = './images/logo--size-m'
            }

            return require(`${src}.svg`)
        },
        buildSrcSticky () {
            let src = './images/logo--size-s'

            return require(`${src}.svg`)
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
