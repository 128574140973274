/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'headphones': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12 4a8 8 0 00-8 8v1h2a3 3 0 013 3v3a3 3 0 01-3 3H5a3 3 0 01-3-3v-7a10 10 0 0120 0v7a3 3 0 01-3 3h-1a3 3 0 01-3-3v-3a3 3 0 013-3h2v-1a8 8 0 00-8-8zM4 18v-3h2a1 1 0 011 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-1zm14-3h2v4a1 1 0 01-1 1h-1a1 1 0 01-1-1v-3a1 1 0 011-1zm-5 .75a.75.75 0 000-1.5h-2a.75.75 0 000 1.5h2zm.75 2.25a.75.75 0 01-.75.75h-2a.75.75 0 010-1.5h2a.75.75 0 01.75.75zM13 21.75a.75.75 0 000-1.5h-2a.75.75 0 000 1.5h2z" _fill="#FF8C00"/>'
  }
})
