/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'link': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M9.592 13.98a5.093 5.093 0 007.196-.306l2.191-2.386a5.093 5.093 0 10-7.502-6.89l-.678.738a1 1 0 101.473 1.353l.678-.738a3.093 3.093 0 014.556 4.184l-2.19 2.386a3.093 3.093 0 01-4.925-.489 1 1 0 10-1.71 1.038c.244.403.549.778.911 1.11zm3.887-4.67a5.093 5.093 0 00-7.196.306l-2.191 2.386a5.093 5.093 0 007.503 6.89l.677-.738a1 1 0 00-1.473-1.353l-.678.738a3.093 3.093 0 11-4.556-4.184l2.19-2.386a3.093 3.093 0 014.926.489 1 1 0 001.71-1.037 5.093 5.093 0 00-.912-1.111z" _fill="#FF8C00"/>'
  }
})
