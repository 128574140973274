/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'people': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<g clip-path="url(#clip0_4409_41783)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8.652 12.219c0-5.796 7.438-9.233 11.818-3.843-3.417 1.347-6.442 5.61-5.15 10.374a.372.372 0 00-.062.007c-.02.003-.04.007-.061.007a6.543 6.543 0 01-6.545-6.545zm21.89 4.4a6.545 6.545 0 11-13.09 0 6.545 6.545 0 0113.09 0zm6.544 18.528c0 7.63-26.166 7.63-26.166 0 0-13.49 26.166-13.352 26.166 0zM16.079 20.73C3.992 19.073-3.777 33.098 7.568 35.49c.11-6.441 5.004-10.36 10.992-11.804a8.892 8.892 0 01-2.482-2.956zm15.862.029c12.086-1.657 19.855 12.368 8.51 14.76-.11-6.441-5.004-10.36-10.992-11.804a8.893 8.893 0 002.482-2.956zm7.426-8.511c0-5.796-7.438-9.234-11.818-3.844 3.417 1.348 6.442 5.61 5.15 10.375.02 0 .04.003.062.007.02.003.04.006.061.006a6.543 6.543 0 006.545-6.545z" _fill="#FF8200"/></g><defs><clipPath id="clip0_4409_41783"><path pid="1" _fill="#fff" d="M0 0h48v48H0z"/></clipPath></defs>'
  }
})
