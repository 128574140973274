<template>
    <div
        :class="[
            'tag',
            { 'tag--inverted': inverted }
        ]"
    >
        <span v-html="text"></span>
    </div>
</template>

<script>
export default {
    name: 'tag',
    props: {
        text: String,
        inverted: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss" scoped>
.tag {
    padding: 4px $token-spacers-2-xs;
    color: inherit;
    border-radius: 6px 6px 6px 0px;
    font-size: 16px;
    border: 1px solid $token-colors-tertiary;
    color: $token-colors-tertiary;
    display: inline-block;

    &--inverted {
        border: 1px solid $token-colors-primary;
        color: $token-colors-primary;
    }
}
</style>
