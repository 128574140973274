var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stock-data"},[(_vm.ticker.nasdaq && _vm.ticker.moex)?_c('z-table',{staticClass:"u-bottom-margin--xl",attrs:{"columns":['Share', 'Date', 'Last', 'High', 'Low', 'Changes', 'Changes %', 'Bid', 'Ask', 'Volume'],"financial":""}},[_c('z-table-row',[_c('z-table-col',{attrs:{"index":"0"}},[_vm._v("QIWI (NASDAQ)")]),_vm._v(" "),_c('z-table-col',{attrs:{"index":"0"}},[_vm._v(_vm._s(_vm.ticker.nasdaq.Date))]),_vm._v(" "),_c('z-table-col',{attrs:{"index":"1","is-highlighted":""}},[_vm._v("\n                "+_vm._s(_vm.ticker.nasdaq.last)+" "),_c('small',[_vm._v(_vm._s(_vm.ticker.nasdaq.Currency))])]),_vm._v(" "),_c('z-table-col',{attrs:{"index":"2"}},[_vm._v(_vm._s(parseFloat(_vm.ticker.nasdaq.High).toFixed(2)))]),_vm._v(" "),_c('z-table-col',{attrs:{"index":"3"}},[_vm._v(_vm._s(parseFloat(_vm.ticker.nasdaq.Low).toFixed(2)))]),_vm._v(" "),_c('z-table-col',{attrs:{"index":"4"}},[_c('span',{style:({
                        'color': _vm.ticker.nasdaq.percentIsNegative ? '#D0021B' : '#4BBD5C'
                    })},[_vm._v(_vm._s(parseFloat(_vm.ticker.nasdaq.Change).toFixed(2)))])]),_vm._v(" "),_c('z-table-col',{attrs:{"index":"4"}},[_c('span',{style:({
                        'color': _vm.ticker.nasdaq.percentIsNegative ? '#D0021B' : '#4BBD5C'
                    })},[_vm._v(_vm._s(_vm.ticker.nasdaq.percent))])]),_vm._v(" "),_c('z-table-col',{attrs:{"index":"5"}},[_vm._v(_vm._s(parseFloat(_vm.ticker.nasdaq.Bid).toFixed(2)))]),_vm._v(" "),_c('z-table-col',{attrs:{"index":"5"}},[_vm._v(_vm._s(parseFloat(_vm.ticker.nasdaq.Ask).toFixed(2)))]),_vm._v(" "),_c('z-table-col',{attrs:{"index":"5"}},[_vm._v(_vm._s(_vm._f("numberFilter")(_vm.ticker.nasdaq.Volume)))])],1),_vm._v(" "),_c('z-table-row',[_c('z-table-col',{attrs:{"index":"0"}},[_vm._v("QIWI (MOEX)")]),_vm._v(" "),_c('z-table-col',{attrs:{"index":"0"}},[_vm._v(_vm._s(_vm.ticker.moex.Date))]),_vm._v(" "),_c('z-table-col',{attrs:{"index":"1","is-highlighted":""}},[_vm._v(_vm._s(_vm.ticker.moex.last)+" "),_c('small',[_vm._v(_vm._s(_vm.ticker.moex.Currency))])]),_vm._v(" "),_c('z-table-col',{attrs:{"index":"2"}},[_vm._v(_vm._s(parseFloat(_vm.ticker.moex.High).toFixed(2)))]),_vm._v(" "),_c('z-table-col',{attrs:{"index":"3"}},[_vm._v(_vm._s(parseFloat(_vm.ticker.moex.Low).toFixed(2)))]),_vm._v(" "),_c('z-table-col',{attrs:{"index":"4"}},[_c('span',{style:({
                        'color': _vm.ticker.moex.percentIsNegative ? '#D0021B' : '#4BBD5C'
                    })},[_vm._v(_vm._s(parseFloat(_vm.ticker.moex.Change).toFixed(2)))])]),_vm._v(" "),_c('z-table-col',{attrs:{"index":"4"}},[_c('span',{style:({
                        'color': _vm.ticker.moex.percentIsNegative ? '#D0021B' : '#4BBD5C'
                    })},[_vm._v(_vm._s(_vm.ticker.moex.percent))])]),_vm._v(" "),_c('z-table-col',{attrs:{"index":"5"}},[_vm._v(_vm._s(parseFloat(_vm.ticker.moex.Bid).toFixed(2)))]),_vm._v(" "),_c('z-table-col',{attrs:{"index":"5"}},[_vm._v(_vm._s(parseFloat(_vm.ticker.moex.Ask).toFixed(2)))]),_vm._v(" "),_c('z-table-col',{attrs:{"index":"5"}},[_vm._v(_vm._s(_vm._f("numberFilter")(_vm.ticker.moex.Volume)))])],1)],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-default-6 col-mobile-12"},[_c('z-caption',{attrs:{"tag":"h2"}},[_vm._v("QIWI (NASDAQ)")]),_vm._v(" "),(_vm.optionsHistoricalNasdaq)?_c('z-chart',{attrs:{"options":_vm.optionsHistoricalNasdaq}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"col-default-6 col-mobile-12"},[_c('z-caption',{attrs:{"tag":"h2"}},[_vm._v("QIWI (MOEX)")]),_vm._v(" "),(_vm.optionsHistoricalMoex)?_c('z-chart',{attrs:{"options":_vm.optionsHistoricalMoex}}):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }