/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'audio': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11.75.75a5 5 0 00-5 5v5a5 5 0 0010 0v-5a5 5 0 00-5-5zm-3 5a3 3 0 116 0v5a3 3 0 11-6 0v-5zm-3.25 4a.75.75 0 00-1.5 0v1a7.75 7.75 0 007 7.714V21H7.75a.75.75 0 000 1.5h8a.75.75 0 000-1.5H12.5v-2.536a7.75 7.75 0 007-7.714v-1a.75.75 0 00-1.5 0v1a6.25 6.25 0 11-12.5 0v-1z" _fill="#FF8C00"/>'
  }
})
