<template>
    <div class="mp-banner">
        <div class="mp-banner__container main-page-container container">
            <div class="row">
                <div class="mp-banner__info col-default-8 col-v-tablet-12">
                    <z-caption tag="h1" size="5xl">
                        <span class="primary-text">QIWI Global</span> is&nbsp;an&nbsp;innovative provider of&nbsp;cutting-edge fintech&nbsp;services
                    </z-caption>
                    <p>
                        QIWI Global is&nbsp;an&nbsp;innovative provider of&nbsp;cutting-edge fintech services. We&nbsp;stand at&nbsp;the forefront of&nbsp;fintech innovations to&nbsp;facilitate and secure the digitalization of&nbsp;payments. Our mission is&nbsp;to&nbsp;create adaptive fintech solutions that connect companies and millions of&nbsp;people in&nbsp;the changing world.
                    </p>
                    <z-button href="/company/at-a-glance/about/" tag="a" size="m">QIWI Global at&nbsp;a&nbsp;glance</z-button>
                </div>
                <div class="mp-banner__stock col-default-4 col-v-tablet-12">
                    <iframe class="mp-banner__stock-iframe" :src="`https://qiwi-${$root.lang}.injector.3ebra.net/qiwi/${$root.lang}/`" frameborder="0"></iframe>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'mp-banner'
}
</script>

<style lang="scss">
.mp-banner {
    background: url(/images/main-page/banner.jpg);
    background-size: cover;
    background-position: 100% 0;
    background-repeat: no-repeat;
    padding-top: 128px; // header height
    @include padding-level(bottom, XL);

    @include breakpoint (tablet) {
        background-position: 50% 100%;
        background-image: url(/images/main-page/banner--table.jpg);
    }

    @include breakpoint (v-tablet) {
        background-image: url(/images/main-page/banner--mobile.jpg);
    }

    &__container {
        @include padding-level(top, XL);
    }

    .z-caption {
        font-size: 36px;
        line-height: 1.5;
        margin-bottom: 24px;
        font-weight: 600;
        color: $token-colors-black;

        @include breakpoint(laptop) {
            font-size: 32px;
        }

        @include breakpoint(tablet) {
            font-size: 22px;
        }

        @include breakpoint(mobile) {
            font-size: 18px;
        }
    }

    p {
        font-size: 22px;

        @include breakpoint(tablet) {
            font-size: 18px;
        }

        @include breakpoint(mobile) {
            font-size: 16px;
        }
    }

    &__stock {
        min-height: 200px;
    }

    &__stock-iframe {
        max-width: 330px;
        width: 100%;
        margin-left: auto;
        height: 100%;
        display: block;

        @include breakpoint (v-tablet) {
            margin-left: 0;
        }
    }
}
</style>
