/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'graphick': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<g clip-path="url(#clip0_4409_41803)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M35.18 15.408v-1.66l-7.355 7.334a2.27 2.27 0 01-1.59.654h-.006l-7.833-.014-6.082 8.093c-1.814 2.285-5.302-.21-3.6-2.7l6.743-8.986c.429-.57 1.09-.9 1.8-.9h.007l8.03.014 6.7-6.672h-1.66c-3.037-.204-3.008-4.395 0-4.557h7.124c1.35 0 2.278 1.02 2.278 2.278v7.116c-.014 2.904-4.339 3.108-4.556 0zm2.85 6.474h-3.706c-1.025 0-1.853 1-1.853 2.236v15.647C32.47 41 33.299 42 34.324 42h3.705c1.025 0 1.853-.999 1.853-2.235V24.118c0-1.237-.828-2.236-1.853-2.236zm-16.412 6.353h3.706c1.024 0 1.852 1.026 1.852 2.294v9.177c0 1.269-.828 2.294-1.852 2.294h-3.706c-1.025 0-1.853-1.025-1.853-2.294v-9.177c0-1.268.828-2.294 1.853-2.294zM9.97 35.647h3.705c1.025 0 1.853.946 1.853 2.118v2.117c0 1.172-.828 2.118-1.853 2.118H9.971c-1.025 0-1.853-.946-1.853-2.118v-2.117c0-1.172.828-2.118 1.853-2.118z" _fill="#FF8200"/></g><defs><clipPath id="clip0_4409_41803"><path pid="1" _fill="#fff" d="M0 0h48v48H0z"/></clipPath></defs>'
  }
})
