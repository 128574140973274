<template>
    <div class="sustainability">
        <div class="sustainability__hero-files">
            <databook-card :data="presentation" blank></databook-card>
            <databook-card></databook-card>
        </div>
        <slot name="content"></slot>
    </div>
</template>

<script>
export default {
    name: 'sustainability',
    data () {
        return {
            presentation: this.$root.app.components.sustainability.presentation
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
