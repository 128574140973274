<template>
    <div
        v-show="state"
        class="z-tabs-content"
    >
        <slot />
    </div>
</template>

<script>
export default {
    name: 'z-tabs-content',
    props: {
        id: {
            type: String,
            default: '',
            required: true
        }
    },
    data () {
        return {
            state: false,
            name: 'z-tabs-content',
            itemId: `tabs-${this.id}`
        }
    },
    watch: {
        state: {
            handler () {
                this.update()
            }
        }
    },
    methods: {
        update () {
            let event = document.createEvent('Event')
            event.initEvent('resize', true, true)
            window.dispatchEvent(event)
        }
    }
}
</script>
