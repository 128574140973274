/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'prepaid_card': {
    width: 72,
    height: 76,
    viewBox: '0 0 72 76',
    data: '<ellipse pid="0" cx="36" cy="37.601" rx="36" ry="37.601" _fill="#F5F5F5"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M45 24.162h-9.133c-1.4 0-2.534 1.184-2.534 2.646v1.114h29.534v-1.114c0-1.462-1.134-2.646-2.534-2.646H48.667v-8.704c0-1.601-1.267-2.924-2.8-2.924H23.8c-1.533 0-2.8 1.323-2.8 2.924v44.355c0 1.532 1.267 2.855 2.8 2.855h22.133c1.534 0 2.8-1.323 2.8-2.925V49.16H45v3.83a.611.611 0 01-.6.627H25.333a.611.611 0 01-.6-.627V17.06c-.066-.348.267-.696.6-.696H44.4c.333 0 .6.348.6.697v7.102zm-6.733 32.727h-6.8c-.667 0-1.2.557-1.2 1.253 0 .696.533 1.253 1.2 1.253h6.8c.666 0 1.2-.557 1.2-1.253 0-.696-.534-1.254-1.2-1.254zm-2.4-11.56c-1.4 0-2.534-1.183-2.534-2.645V31.82h29.534v10.863c0 1.462-1.134 2.646-2.534 2.646H35.867zM53 36.14c-.667-1.045-1.8-1.741-3.067-1.741-2.066 0-3.733 1.74-3.666 3.83 0 2.088 1.6 3.83 3.666 3.83 1.267 0 2.4-.697 3.067-1.742.667 1.045 1.8 1.741 3.067 1.741 2 0 3.666-1.671 3.666-3.83 0-2.088-1.6-3.83-3.666-3.83-1.267 0-2.4.697-3.067 1.742z" _fill="#FF8C00"/>'
  }
})
