/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'self_service_kiosk': {
    width: 72,
    height: 76,
    viewBox: '0 0 72 76',
    data: '<ellipse pid="0" cx="36" cy="37.601" rx="36" ry="37.601" _fill="#F5F5F5"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M45.87 61.99v.678H32.12V31.006h16.605L43.32 16.419H27.751l-1.427-3.884h20.238l6.573 18.472V41.983h.562c1.211 0 2.249.768 2.681 1.897h.303c1.6 0 2.854 1.355 2.854 2.98v7.273c0 4.832-.994 6.413-2.205 7.813-.044.045-.087.102-.13.158-.043.057-.086.113-.13.158v.407h-1.73v-.858c0-.136.044-.226.087-.316.173-.271.39-.542.605-.768.822-.949 1.774-2.078 1.774-6.594V46.86c0-.678-.52-1.175-1.125-1.175h-.778v2.981h-1.038v-3.613c0-.677-.519-1.22-1.168-1.22H52.4v4.156h-1.038V43.11c0-.677-.519-1.22-1.167-1.22h-1.298v5.601H47.86v-9.8c0-.904-.735-1.356-1.47-1.356s-1.427.452-1.47 1.31v12.692h-1.038v-1.581c-.605.045-1.081.27-1.427.632-.562.587-.605 1.445-.605 1.716.043 4.969 1.34 8.537 3.805 10.479a.48.48 0 01.216.406zM22 62.668V17.23l2.724-3.613 5.535 16.575v32.475H22z" _fill="#FF8C00"/>'
  }
})
