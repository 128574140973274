/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'check-circle': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10.017 3.216a9 9 0 015.646.557 1 1 0 10.814-1.826A11 11 0 1023 12v-.921a1 1 0 10-2 0V12a9 9 0 11-10.983-8.784zm12.69 1.49a1 1 0 00-1.415-1.413L12 12.595l-2.293-2.292a1 1 0 10-1.414 1.414l3 3a1 1 0 001.415 0l10-10.01z" _fill="#FF8C00"/>'
  }
})
