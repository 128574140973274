<template>
    <component
        :href="data.link"
        class="bio-item"
        v-if="data"
        :is="data.link ? 'a' : 'div'"
    >
        <div
            :class="[
                'bio-item__image',
                { 'bio-item__image--round' : imageType === 'round' }
            ]"
            v-if="data.image"
        >
            <img :src="data.image" />
        </div>
        <z-caption tag="p" class="bio-item__title" size="5xl">
            <span v-html="data.name"></span>
        </z-caption>
        <p class="bio-item__position" v-html="data.position"></p>
    </component>
</template>

<script>
export default {
    name: 'bio-item',
    props: {
        data: Object,
        imageType: {
            type: String,
            default: 'square',
            validator: prop => ['square', 'round'].includes(prop)
        }
    }
}
</script>

<style lang="scss">
.bio-item {
    color: inherit;
    text-decoration: none;
    transition: color $transition;
    text-align: center;
    @include margin-level(bottom, S);
    display: block;

    &__image {
        @include margin-level(bottom, XS);
        padding-top: 67%;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;
        background-color: $token-colors-gray-10;
        border-radius: 24px;
        position: relative;
        overflow: hidden;

        img {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            min-height: 100%;
            min-width: 100%;
            object-fit: cover;
        }

        &--round {
            border-radius: 50%;
            margin-left: auto;
            margin-right: auto;
            width: 300px;
            height: 300px;
            border-radius: 50%;
            padding: 0;

            @include breakpoint(tablet) {
                width: 240px;
                height: 240px;
            }
        }
    }

    &__title {
        margin-bottom: 10px !important;

        > span {
            border-bottom: 2px solid $token-colors-primary;
        }
    }

    &__position {
        color: $token-colors-gray-60;
        line-height: 1.4;
    }
}
</style>
