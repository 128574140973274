<template>
    <div class="latest-news" v-if="data">
        <div class="row">
            <div
                class="col-default-4 col-v-tablet-6 col-mobile-12"
                v-for="(item, index) in data"
                :key="index"
            >
                <div class="latest-news__item">
                    <span class="latest-news__date">
                        {{ item.date | momentFilter('MM/DD/YYYY') }}
                    </span>
                    <z-link
                        theme="big"
                        :href="item.link"
                    >
                        <span v-html="item.name"></span>
                    </z-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'

export default {
    name: 'latest-news',
    data () {
        return {
            lang: this.$root.lang,
            data: this.$root.app.components['latest-news']
        }
    },
    created () {
        moment.locale(this.lang)
    }
}
</script>

<style lang="scss">
.latest-news {
    &__date {
        display: block;
        margin-bottom: $token-spacers-3-xs;
        @include typo-level(S);
        color: $token-colors-gray-40;
    }

    &__item {
        @include padding-level(right, XL);

        @include breakpoint (mobile) {
            padding-right: 0;
        }
    }
}
</style>
