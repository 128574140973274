/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'coin_usd': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<path pid="0" d="M24 5.385c10.265 0 18.615 8.35 18.615 18.615 0 10.265-8.35 18.615-18.615 18.615-10.265 0-18.615-8.35-18.615-18.615 0-10.265 8.35-18.615 18.615-18.615zM24 46c12.13 0 22-9.87 22-22S36.13 2 24 2 2 11.87 2 24s9.87 22 22 22z" _fill="#FF8C00"/><path pid="1" d="M17.23 29.077a1.36 1.36 0 011.936-1.234c.32.15.662.292 1.027.428 1.26.469 2.444.691 3.555.691 1.753 0 2.642-.444 2.642-1.358 0-.518-.272-.74-.667-1.012-.197-.173-.814-.345-1.11-.444l-1.556-.47-1.26-.37-1.134-.444c-.494-.198-.865-.37-1.136-.543-.494-.37-1.309-.963-1.679-1.58a5.668 5.668 0 01-.617-2.543c0-2.79 1.876-4.567 5.012-5.036v-2.444h3.801v2.469c1.334.222 2.617.642 3.827 1.259v2.431c0 .809-.815 1.364-1.565 1.064-.37-.148-.761-.284-1.176-.41-1.061-.32-2.073-.493-3.06-.493-1.507 0-2.272.37-2.272 1.136 0 .32.198.567.716.79l.79.271c.519.173 1.654.47 2.37.691 1.654.543 2.963 1.235 3.9 2.05.939.814 1.408 1.974 1.408 3.455 0 2.765-1.95 4.74-4.938 5.333v2.518h-3.801v-2.395c-1.95-.222-3.63-.79-5.012-1.752v-2.058zM24 40.44c9.08 0 16.44-7.36 16.44-16.44 0-9.08-7.36-16.44-16.44-16.44-9.08 0-16.44 7.36-16.44 16.44 0 9.08 7.36 16.44 16.44 16.44z" _fill="#FF8C00"/>'
  }
})
