<template>
    <div class="z-key-indicator" :class="classObject">
        <div
            v-if="!!this.$slots['icon']"
            class="z-key-indicator__icon"
        >
            <slot name="icon" />
        </div>
        <div class="z-key-indicator__body">
            <span
                v-if="name"
                v-html="name"
                class="z-key-indicator__name"
            ></span>
            <div
                v-if="value || valueText"
                :class="[
                    'z-key-indicator__group',
                    { 'z-key-indicator__group--column' : column }
                ]"
            >
                <div class="z-key-indicator__container">
                    <div class="z-key-indicator__value-wrapper">
                        <span
                            v-if="value"
                            v-html="value"
                            class="z-key-indicator__value"
                            :class="{'u-right-margin--no-important' : !spaceBeetween}"
                        ></span>
                        <span
                            v-if="valueText"
                            v-html="valueText"
                            class="z-key-indicator__value-text"
                        ></span>
                    </div>
                </div>
                <span
                    v-html="trendText"
                    v-if="trend && trendText"
                    :class="[
                        'z-key-indicator__trend',
                        { 'z-key-indicator__trend--down' : trend === 'down' }
                    ]"
                >
                </span>
                <span
                    v-if="caption"
                    v-html="caption"
                    class="z-key-indicator__caption"
                ></span>
            </div>
            <div
                v-if="!!this.$slots['footer']"
                class="z-key-indicator__footer"
            >
                <slot name="footer" />
            </div>
        </div>
    </div>
</template>

<script>
import verge from 'verge'
import { CountUp } from 'countup.js'

export default {
    name: 'z-key-indicator',
    data () {
        return {
            options: {
                ru: {
                    duration: 5,
                    useEasing: true,
                    useGrouping: true,
                    decimalPlaces: this.getDecimals(),
                    separator: '',
                    decimal: ',',
                    prefix: '',
                    suffix: ''
                },
                en: {
                    duration: 5,
                    useEasing: true,
                    useGrouping: true,
                    decimalPlaces: this.getDecimals(),
                    separator: '',
                    decimal: '.',
                    prefix: '',
                    suffix: ''
                }
            },
            viewport: false,
            counter: null
        }
    },
    props: {
        value: [String, Number],
        valueText: String,
        name: String,
        caption: String,
        column: Boolean,
        trend: String,
        inverted: Boolean,
        trendText: String,
        spaceBeetween: {
            type: Boolean,
            default: true
        },
        animation: {
            type: Boolean,
            default: false
        },
        fullHeight: Boolean,
        theme: {
            type: String,
            validator: prop => ['orange'].includes(prop)
        }
    },
    computed: {
        classObject () {
            let arrClass = []

            if (this.fullHeight) {
                arrClass.push('z-key-indicator--fullHeight')
            }

            if (this.inverted) {
                arrClass.push('z-key-indicator--inverted')
            }

            if (this.theme) {
                arrClass.push(`z-key-indicator--theme-${this.theme}`)
            }

            return arrClass
        },
        valueParsed () {
            return Number.parseFloat(this.value.replace(',', '.'))
        }
    },
    methods: {
        getDecimals () {
            if (this.value) {
                let valueParsed = Number.parseFloat(this.value.replace(',', '.'))
                return valueParsed.toString().includes('.') ? (valueParsed.toString().split('.').pop().length) : (0)
            }
            return 0
        },
        inView () {
            if (verge.inViewport(this.$el)) {
                this.viewport = true
                if (this.counter) {
                    this.counter.start()
                }
            }
            this.viewport && window.removeEventListener('scroll', this.inView)
        }
    },
    // Only if design system
    watch: {
        animation: function (val, oldVal) {
            if (val === true) {
                window.addEventListener('scroll', this.inView)
                if (this.value) {
                    this.counter = new CountUp(this.$el.getElementsByClassName('z-key-indicator__value')[0], this.valueParsed, this.options[this.$root.lang])
                }
                this.inView()
            }
        }
    },
    // End if
    mounted () {
        if (this.animation) {
            window.addEventListener('scroll', this.inView)
            if (this.value) {
                this.counter = new CountUp(this.$el.getElementsByClassName('z-key-indicator__value')[0], this.valueParsed, this.options[this.$root.lang])
            }
        }
        this.inView()
    },
    beforeDestroy () {
        window.removeEventListener('scroll', this.inView)
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
