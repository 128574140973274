<template>
    <div class="financial-statements">
        <div
            class="row"
            v-for="(type, index) in types"
            :key="index"
        >
            <div class="col-default-12" v-if="constructed(type).tabs().length">
                <h4>{{ text.title[type] }}</h4>
                <z-tabs class="u-bottom-margin--quad">
                    <template v-slot:label>
                        <z-tabs-label
                            v-for="(year, index) in constructed(type).tabs()"
                            :id="`${type}-${year}`"
                            :key="index"
                        >
                            {{ year }}
                        </z-tabs-label>
                        <z-tabs-label
                            :id="`${type}-archive`"
                            rightSide
                            v-if="constructed(type).archive().length"
                        >
                            {{ text.archive }}
                        </z-tabs-label>
                    </template>
                    <template v-slot:content>
                        <z-tabs-content
                            v-for="(year, index) in constructed(type).tabs()"
                            :id="`${type}-${year}`"
                            :key="index"
                        >
                            <z-filelist-item
                                v-for="(el, i) in constructed(type).content(year)"
                                :key="`document-${i}`"
                                :date="el.date"
                                :info="`${el.size}`"
                            >
                                <template v-slot:icon>
                                    <z-icon :name="el.type" width="28" height="28"></z-icon>
                                </template>
                                <z-link :href="el.link" theme="black" v-html="el.name"></z-link>
                            </z-filelist-item>
                        </z-tabs-content>
                        <z-tabs-content
                            :id="`${type}-archive`"
                            v-if="constructed(type).archive().length"
                        >
                            <z-accordion>
                                <z-accordion-item
                                    v-for="(year, index) in constructed(type).archive()"
                                    :id="`${type}-${year}`"
                                    :key="index"
                                >
                                    <template v-slot:icon>
                                        <z-icon name="slider" dir="down" height="10" />
                                    </template>
                                    <template v-slot:header>{{ year }}</template>
                                    <template v-slot:body>
                                        <z-filelist-item
                                            v-for="(el, i) in constructed(type).archiveContent(year)"
                                            :key="`document-${i}`"
                                            :date="el.date"
                                            :info="`${el.size}`"
                                        >
                                            <template v-slot:icon>
                                                <z-icon :name="el.type" width="28" height="28"></z-icon>
                                            </template>
                                            <z-link :href="el.link" theme="black" v-html="el.name"></z-link>
                                        </z-filelist-item>
                                    </template>
                                </z-accordion-item>
                            </z-accordion>
                        </z-tabs-content>
                    </template>
                </z-tabs>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'financial-statements',
    data () {
        return {
            data: this.$root.app.components['financial-statements'],
            text: {
                archive: this.$root.lang === 'ru' ? 'Архив' : 'Archive',
                title: {
                    'fs-1': this.$root.lang === 'ru' ? 'МСФО' : 'IFRS Reports',
                    'fs-2': this.$root.lang === 'ru' ? 'РСБУ' : 'Accounting Reports'
                }
            }
        }
    },
    computed: {
        types () {
            return Object.keys(this.data)
        }
    },
    methods: {
        constructed (type) {
            let data = this.data[type]

            return {
                tabs: () => Object.keys(data.years).reverse(),
                archive: () => Object.keys(data.archive).reverse(),
                content: year => data.years[year],
                archiveContent: year => data.archive[year]
            }
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
