<template>
    <z-link
        class="back-link"
        :href="backLink"
        icon="back"
        v-if="backLink"
    >
        <span v-html="backText"></span>
    </z-link>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'back-link',
    computed: {
        ...mapGetters([
            'backLink',
            'backText'
        ])
    }
}
</script>

<style lang="scss">
.back-link {

    &:hover {
        .z-icon {
            transform: translateX(-2px);

            path {
                fill: $token-colors-primary !important;
            }
        }
    }

    .z-icon {
        transition: $transition;

        path {
            fill: $token-colors-black !important;
        }
    }
}
</style>
