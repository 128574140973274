/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'moscow_exchange': {
    width: 72,
    height: 76,
    viewBox: '0 0 72 76',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M0 0h72v75.201H0V0z" _fill="#C7102D"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M23.931 20.465l-6.094 3.513v26.783l6.094 4.037 12.056-12.607 8.177 8.508.002-.002 3.992 4.101 6.064-4.16V24.009l-6.094-3.575-12.142 12.65-10.283-10.742-1.772-1.877zm-.03 9.152v16.059l7.712-8.035-7.712-8.024zm16.442 8.019l7.726 8.07v-16.15l-7.726 8.08z" _fill="#FEFEFE"/>'
  }
})
