<template>
    <div class="reports-and-results">
        <!-- begin: results layout -->
        <div class="row" v-if="constructed('results').tabs().length">
            <div class="col-default-12">
                <z-tabs class="u-bottom-margin--quad">
                    <template v-slot:label>
                        <z-tabs-label
                            v-for="(year, index) in constructed('results').tabs()"
                            :id="`results-${year}`"
                            :key="index"
                        >{{ year }}
                        </z-tabs-label>
                        <z-tabs-label
                            id="results-archive"
                            rightSide
                            v-if="constructed('results').archive().length"
                        >{{ text.archive }}
                        </z-tabs-label>
                    </template>
                    <template v-slot:content>
                        <z-tabs-content
                            v-for="(year, index) in constructed('results').tabs()"
                            :id="`results-${year}`"
                            :key="index"
                        >
                            <z-table
                                :columns="text.columns.results"
                                class="reports-and-results__table"
                            >
                                <z-table-row
                                    v-for="(item, index) in constructed('results').content(year)"
                                    :key="index"
                                >
                                    <z-table-col index="0">
                                        <span v-html="item.name"></span>
                                    </z-table-col>
                                    <z-table-col index="1">
                                        <z-link
                                            v-if="item['press-release'] !== null"
                                            :href="item['press-release'].hasOwnProperty('link') ? item['press-release'].link : item['press-release']"
                                            target="_blank"
                                        >
                                            <z-icon
                                                class="reports-and-results__icon"
                                                name="release"
                                                height="24"
                                                width="24"
                                            />
                                        </z-link>
                                        <span v-else>­­­&mdash;</span>
                                    </z-table-col>
                                    <z-table-col index="2">
                                        <z-link
                                            v-if="item.presentation !== null"
                                            :href="item.presentation.hasOwnProperty('link') ? item.presentation.link : item.presentation"
                                            target="_blank"
                                        >
                                                <z-icon
                                                    class="reports-and-results__icon"
                                                    name="presentation"
                                                    height="24"
                                                    width="24"
                                                />
                                        </z-link>
                                        <span v-else>­­­&mdash;</span>
                                    </z-table-col>
                                    <z-table-col index="3">
                                        <z-link
                                            v-if="item.accountability !== null"
                                            :href="item.accountability.hasOwnProperty('link') ? item.accountability.link : item.accountability"
                                            target="_blank"
                                        >
                                            <z-icon
                                                class="reports-and-results__icon"
                                                name="report"
                                                height="24"
                                                width="24"
                                            />
                                        </z-link>
                                        <span v-else>­­­&mdash;</span>
                                    </z-table-col>
                                    <z-table-col index="4">
                                        <z-link
                                            v-if="item.audio !== null"
                                            :href="item.audio.hasOwnProperty('link') ? item.audio.link : item.audio"
                                            target="_blank"
                                        >
                                            <z-icon
                                                class="reports-and-results__icon"
                                                name="audio"
                                                height="24"
                                                width="24"
                                            />
                                        </z-link>
                                        <span v-else>­­­&mdash;</span>
                                    </z-table-col>
                                </z-table-row>
                            </z-table>
                        </z-tabs-content>
                        <z-tabs-content
                            id="results-archive"
                            v-if="constructed('results').archive().length"
                        >
                            <z-accordion>
                                <z-accordion-item
                                    v-for="(year, index) in constructed('results').archive()"
                                    :id="`results-${year}`"
                                    :key="index"
                                >
                                    <template v-slot:header>{{ year }}</template>
                                    <template v-slot:body>
                                        <z-table
                                            :columns="text.columns.results"
                                            class="reports-and-results__table"
                                        >
                                            <z-table-row
                                                v-for="(item, index) in constructed('results').archiveContent(year)"
                                                :key="index"
                                            >
                                                <z-table-col index="0">
                                                    <span v-html="item.name"></span>
                                                </z-table-col>
                                                <z-table-col index="1">
                                                    <z-link
                                                        v-if="item['press-release'] !== null"
                                                        :href="item['press-release'].hasOwnProperty('link') ? item['press-release'].link : item['press-release']"
                                                        target="_blank"
                                                    >
                                                        <z-icon
                                                            class="reports-and-results__icon"
                                                            name="release"
                                                            height="24"
                                                            width="24"
                                                        />
                                                    </z-link>
                                                    <span v-else>­­­&mdash;</span>
                                                </z-table-col>
                                                <z-table-col index="2">
                                                    <z-link
                                                        v-if="item.presentation !== null"
                                                        :href="item.presentation.hasOwnProperty('link') ? item.presentation.link : item.presentation"
                                                        target="_blank"
                                                    >
                                                        <z-icon
                                                            class="reports-and-results__icon"
                                                            name="presentation"
                                                            height="24"
                                                            width="24"
                                                        />
                                                    </z-link>
                                                    <span v-else>­­­&mdash;</span>
                                                </z-table-col>
                                                <z-table-col index="3">
                                                    <z-link
                                                        v-if="item.accountability !== null"
                                                        :href="item.accountability.hasOwnProperty('link') ? item.accountability.link : item.accountability"
                                                        target="_blank"
                                                    >
                                                            <z-icon
                                                                class="reports-and-results__icon"
                                                                name="report"
                                                                height="24"
                                                                width="24"
                                                            />
                                                    </z-link>
                                                    <span v-else>­­­&mdash;</span>
                                                </z-table-col>
                                                <z-table-col index="4">
                                                    <z-link
                                                        v-if="item.audio !== null"
                                                        :href="item.audio.hasOwnProperty('link') ? item.audio.link : item.audio"
                                                        target="_blank"
                                                    >
                                                            <z-icon
                                                                class="reports-and-results__icon"
                                                                name="audio"
                                                                height="24"
                                                                width="24"
                                                            />
                                                    </z-link>
                                                    <span v-else>­­­&mdash;</span>
                                                </z-table-col>
                                            </z-table-row>
                                        </z-table>
                                    </template>
                                </z-accordion-item>
                            </z-accordion>
                        </z-tabs-content>
                    </template>
                </z-tabs>
            </div>
        </div>
        <!-- end: results layout -->
    </div>
</template>

<script>
import { mixinDevice } from '@/utils/mixin'

export default {
    name: 'reports-and-results',
    mixins: [mixinDevice],
    data () {
        return {
            data: this.$root.app.components['reports-and-results-list'],
            text: {
                archive: this.$root.lang === 'ru' ? 'Архив' : 'Archive',
                columns: {
                    results: [
                        this.$root.lang === 'ru' ? 'Название' : 'Name',
                        this.$root.lang === 'ru' ? 'Пресс-релиз' : 'Press-release',
                        this.$root.lang === 'ru' ? 'Презентация' : 'Presentation',
                        this.$root.lang === 'ru' ? 'Отчетность' : 'SEC form',
                        this.$root.lang === 'ru' ? 'Аудиозапись' : 'Audio'
                    ]
                }
            }
        }
    },
    methods: {
        constructed (type) {
            let data = this.data[type]

            return {
                tabs: () => Object.keys(data.years).reverse(),
                archive: () => Object.keys(data.archive).reverse(),
                content: year => data.years[year],
                archiveContent: year => data.archive[year]
            }
        }
    }
}

</script>

<style lang="scss" src="./index.scss"></style>
