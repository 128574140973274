<template>
    <div class="reports-and-result" v-if="data">
        <div class="row">
            <div class="col-default-3 col-tablet-12">
                <div class="reports-and-result__title">
                    <z-caption class="u-collapse--all" tag="p" size="4xl" weight="700">
                        Results and&nbsp;Reports
                    </z-caption>
                    <div class="hide-me--mobile">
                        <z-link
                            size="s"
                            href="/results-and-reports/reports-and-presentations/"
                        >Learn more</z-link>
                    </div>
                </div>
            </div>
            <div class="col-default-9 col-tablet-12">
                <div class="reports-and-result__content">
                    <div
                        class="reports-and-result__item"
                        v-if="data"
                        v-for="(item, index) in data"
                        :key="index"
                    >
                        <z-caption class="reports-and-result__caption" tag="span" size="s" weight="600">
                            <span v-html="item.name"></span>
                        </z-caption>
                        <span class="reports-and-result__links-list">
                            <z-link
                                class="reports-and-result__link"
                                :href="item['press-release']"
                                target="_blank"
                                theme="gray"
                                v-if="item['press-release']"
                                icon="release"
                            >Press-release</z-link>
                            <z-link
                                class="reports-and-result__link"
                                :href="item.presentation.link"
                                target="_blank"
                                theme="gray"
                                v-if="item.presentation"
                                icon="presentation"
                            >Presentation</z-link>
                            <z-link
                                class="reports-and-result__link"
                                :href="item.accountability"
                                target="_blank"
                                theme="gray"
                                v-if="item.accountability"
                                icon="report"
                            >SEC form</z-link>
                            <z-link
                                class="reports-and-result__link"
                                :href="item.audio"
                                target="_blank"
                                theme="gray"
                                v-if="item.audio"
                                icon="audio"
                            >Audio</z-link>
                        </span>
                    </div>
                    <div class="reports-and-result__item" v-if="dataBook">
                        <z-caption class="reports-and-result__caption" tag="span" size="s" weight="600">
                            <span v-html="dataBook.name"></span>
                        </z-caption>
                        <span class="reports-and-result__links-list reports-and-result__links-list--databook">
                            <z-link
                                class="reports-and-result__link"
                                :href="dataBook.link"
                                icon="database"
                                theme="gray"
                            >{{ dataBook.type }}</z-link>
                        </span>
                    </div>
                </div>
                <div class="hide-me--default show-me--mobile u-top-margin--s">
                    <z-link
                        size="s"
                        href="/results-and-reports/reports-and-presentations/"
                    >Learn more</z-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'reports-and-result',
    computed: {
        data () {
            if (!this.$root.app.components['main-page'] && !this.$root.app.components['main-page']['reports-and-result']) return
            return this.$root.app.components['main-page']['reports-and-result']
        },
        dataBook () {
            if (!this.$root.app.components['main-page'] && !this.$root.app.components['main-page']['data-book']) return
            return this.$root.app.components['main-page']['data-book']
        }
    }
}
</script>

<style lang="scss" scoped>
.reports-and-result {
    width: 100%;
    background-color: $token-colors-white;
    border-radius: 10px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
    @include padding-level(top, S);
    @include padding-level(bottom, S);
    @include padding-level(right, S);
    @include padding-level(left, S);

    &__links-list {
        display: flex;
        margin-left: -$token-spacers-2-xs;
        margin-right: -$token-spacers-2-xs;
        flex-wrap: wrap;

        @include breakpoint (tablet) {
            margin-left: -34px;
        }

        @include breakpoint (mobile) {
            display: flex;
            flex-direction: column;
            margin-top: -32px;
            padding-top: 24px;
        }

        &--databook {
            display: inline-block;
            margin-top: -10px !important;
            padding-top: 0 !important;
            margin-left: -3px;

            @include breakpoint (tablet) {
                margin-left: -19px;
            }

            @include breakpoint (mobile) {
                display: flex;
                flex-direction: column;
                margin-left: -34px;
            }
        }
    }

    &__link {
        margin: 0 $token-spacers-2-xs;
        margin-top: 10px;
        font-size: 14px;

        @include breakpoint (mobile) {
            margin-top: 32px;
            font-size: 16px;
        }
    }

    &__caption {
        margin-right: $token-spacers-2-xs;

        @include breakpoint (mobile) {
            font-size: 18px;
        }
    }

    &__item {
        position: relative;
        padding-left: 18px;

        & + & {
            @include margin-level(top, XS);

            @include breakpoint (mobile) {
                margin-top: 48px;
            }
        }

        &:after {
            content: '';
            position: absolute;
            width: 6px;
            height: 6px;
            background: $token-colors-primary;
            border-radius: 50%;
            left: 0;
            top: 0.65em;
        }
    }

    &__title {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include breakpoint (tablet) {
            flex-direction: row;
        }
    }
}
</style>
