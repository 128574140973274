<template>
    <td :class="[
        'z-table-col z-table-col--body',
        buildClass
    ]">
        <span
            class="z-table-col__title"
            v-if="isMobile() || isTablet()"
            v-html="data[index]"
        ></span>
        <span class="z-table-col__content">
            <slot />
        </span>
    </td>
</template>

<script>
import { mixinDevice } from '@/utils/mixin'

export default {
    name: 'z-table-col',
    mixins: [mixinDevice],
    props: {
        index: {
            type: [String, Number],
            required: true
        },
        isHighlighted: Boolean
    },
    data () {
        return {
            data: this.$parent.$parent.columns
        }
    },
    computed: {
        buildClass () {
            return {
                'z-table-col--highlighted': this.isHighlighted
            }
        }
    }
}
</script>
