<template>
    <a
        :href="href"
        class="z-link"
        :class="classObject"
        @click="$emit('click')"
        :target="external ? '_blank' : false"
        :download="setDownloadFile() ? downloadTitle : false"
    >
        <z-icon
            v-if="iconName && position === 'left'"
            :name="iconName"
            :width="width"
            :height="height"
            class="z-link__icon z-link__icon--left"
        /><span><slot></slot></span><z-icon
            class="z-link__icon z-link__icon--right"
            v-if="iconName && position === 'right'"
            :name="iconName"
            :width="width"
            :height="height"
        ></z-icon>
    </a>
</template>

<script>
export default {
    name: 'z-link',
    props: {
        href: {
            type: String
        },
        disabled: Boolean,
        size: {
            type: String,
            default: 'l'
        },
        internal: [Boolean, String],
        external: [Boolean, String],
        icon: String,
        iconWidth: {
            type: [String, Number],
            default: '24'
        },
        iconHeight: {
            type: [String, Number],
            default: '24'
        },
        iconPosition: {
            type: String,
            default: 'left',
            validator: prop => ['left', 'right'].includes(prop)
        },
        underline: {
            type: Boolean,
            default: false
        },
        theme: String,
        downloadFile: Boolean,
        downloadTitle: {
            type: String,
            default: ''
        },
        inline: {
            type: Boolean,
            default: true
        },
        inverted: Boolean
    },
    methods: {
        setDownloadFile () {
            return Modernizr.adownload && this.downloadFile
        }
    },
    computed: {
        classObject: function () {
            return [
                {
                    'z-link--external': this.external,
                    'z-link--internal': this.internal,
                    'z-link--disabled': this.disabled,
                    'z-link--underline': this.underline,
                    'z-link--left-icon': this.icon && this.position === 'left',
                    'z-link--right-icon': this.icon && this.position === 'right',
                    'z-link--inline': this.inline && !this.icon,
                    'z-link--inverted': this.inverted,
                    [`z-link--theme-${this.theme}`]: this.theme
                },
                `z-link--size-${this.size}`
            ]
        },
        iconName () {
            let icon = this.icon
            if (this.external) {
                icon = 'external'
            }

            if (this.internal) {
                icon = 'link'
            }

            return icon
        },
        width () {
            let width = this.iconWidth

            return width
        },
        height () {
            let height = this.iconHeight

            return height
        },
        position () {
            let position = this.iconPosition

            if (this.external && !position) {
                position = 'right'
            }

            return position
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
