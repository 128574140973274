<template>
    <div class="site-map">
        <ul class="site-map__list">
            <li
                class="site-map__item"
                v-for="(item, index) in data"
                :key="index"
            >
                <h4>
                    <z-link
                        :size="isMobile() ? 'm' : 'xl'"
                        :href="item.link"
                        theme="black"
                    >{{ item.name }}</z-link>
                </h4>
                <ul class="site-map__sublist" v-if="sublist(item)">
                    <li
                        class="site-map__subitem"
                        v-for="(item, index) in item.items"
                        :key="index"
                    >
                        <z-link
                            :size="isMobile() ? 'm' : 'l'"
                            :href="item.link"
                            weight="600"
                            theme="black"
                        >{{ item.name }}</z-link>

                        <ul class="site-map__sublist" v-if="item.items.length">
                            <li
                                class="site-map__subitem"
                                v-for="(item, index) in item.items"
                                :key="index"
                            >
                                <z-link
                                    :size="isMobile() ? 's' : 'm'"
                                    :href="item.link"
                                    theme="black"
                                >{{ item.name }}</z-link>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</template>

<script>
import { mixinDevice } from '@/utils/mixin'

export default {
    name: 'site-map',
    mixins: [mixinDevice],
    data () {
        return {
            data: this.$root.app.components.sitemap
        }
    },
    created () {
        this.data = this.filterData(this.data, (item) => {
            return !(item.hasOwnProperty('params') && !Array.isArray(item.params) && item.params.hidePage)
        })
    },
    methods: {
        filterData (data, predicate) {
            return !data ? null : data.reduce((list, entry) => {
                let clone = null
                if (predicate(entry)) {
                    clone = Object.assign({}, entry)
                }
                if (entry.items != null) {
                    let items = this.filterData(entry.items, predicate)
                    if (items.length > 0) {
                        clone = Object.assign({}, entry, {items: items})
                    }
                }

                clone && list.push(clone)
                return list
            }, [])
        },
        sublist (item) {
            return item.hasOwnProperty('items') ? item.items.length > 0 : false
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
