<template>
    <div
        class="news-slider"
        v-if="data.length"
    >
        <div
            class="news-slider__container"
            ref="newsSliderContainer"
        >
            <div class="news-slider__wrapper">
                <div
                    class="news-slider__slide"
                    v-for="(slide, index) in data"
                    :key="index"
                >
                    <card
                        class="card--not-img u-entire-height"
                        theme="news"
                        :tag="slide.tag"
                        :detail="slide.name"
                        :date="buildDate(slide.date)"
                        :link="slide.link"
                        target="_blank"
                    />
                </div>
            </div>
            <div class="news-slider__navigation container container--wide hide-me--tablet">
                <button
                    class="news-slider__button news-slider__button--prev"
                    ref="newsSliderNavPrev"
                >
                    <z-icon
                        name="slider"
                        dir="down"
                        width="16"
                        height="16"
                    />
                </button>
                <button
                    class="news-slider__button news-slider__button--next"
                    ref="newsSliderNavNext"
                >
                    <z-icon
                        name="slider"
                        dir="up"
                        width="16"
                        height="16"
                    />
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import Swiper from 'swiper/dist/js/swiper.js'

export default {
    name: 'news-slider',
    props: {
        data: Array,
        default: () => []
    },
    data () {
        return {
            slider: Object
        }
    },
    mounted () {
        this.init()
    },
    methods: {
        buildDate (timestamp) {
            moment.locale(`${this.$root.lang}`)
            return moment.unix(timestamp).format('DD MMMM YYYY')
        },
        init () {
            let container = this.$refs.newsSliderContainer
            let navigationPrev = this.$refs.newsSliderNavPrev
            let navigationNext = this.$refs.newsSliderNavNext

            this.slider = new Swiper(container, {
                speed: 1000,
                spaceBetween: 16,
                slidesPerView: 3,
                mousewheel: false,
                simulateTouch: true,
                slideActiveClass: 'is-active',
                slideClass: 'news-slider__slide',
                wrapperClass: 'news-slider__wrapper',
                containerModifierClass: 'news-slider__container',
                navigation: {
                    nextEl: navigationNext,
                    prevEl: navigationPrev,
                    disabledClass: 'is-disabled'
                },
                breakpoints: {
                    320: {
                        slidesPerView: 1
                    },
                    480: {
                        slidesPerView: 1
                    },
                    768: {
                        slidesPerView: 2
                    }
                }
            })
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
