/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7 2h6.172a3 3 0 012.12.879l3.83 3.828A3 3 0 0120 8.828V19a3 3 0 01-3 3H7a3 3 0 01-3-3V5a3 3 0 013-3zm0 2a1 1 0 00-1 1v14a1 1 0 001 1h10a1 1 0 001-1v-9h-4a2 2 0 01-2-2V4H7zm7 .414V8h3.586L14 4.414z" _fill="#FF8C00"/>'
  }
})
