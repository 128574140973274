<template>
    <z-list
        class="z-filelist"
        :class="buildClass"
        :styled="false"
        :size="size"
        tag="ul"
    >
        <slot/>
    </z-list>
</template>

<script>
export default {
    name: 'z-filelist',
    props: {
        size: {
            type: String,
            default: 'm'
        },
        theme: {
            type: String,
            default: 'default'
        },
        horizontal: Boolean,
        border: Boolean
    },
    computed: {
        buildClass () {
            return [
                `z-filelist--size-${this.size}`,
                `z-filelist--theme-${this.theme}`,
                {
                    'z-filelist--horizontal': this.horizontal,
                    'z-filelist--vertical': !this.horizontal,
                    'z-filelist--border': this.border
                }
            ]
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
