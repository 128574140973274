<template>
    <div class="cmd-card">
        <div class="cmd-card__body">
            <tag class="u-bottom-margin--s" :text="data.preview.format" />
            <div class="cmd-card__date-block u-bottom-margin--s" v-if="data.date || data.time">
                <date-block class="cmd-card__date" :date="data.date" v-if="data.date"/>
                <span class="cmd-card__time" v-if="data.time">
                    <z-icon
                        name="clock"
                        width="24"
                        height="24"
                        color="#2F363F"
                    />
                    <span v-html="decodeHtml(data.time)"></span>
                </span>
            </div>

            <z-caption class="cmd-card__caption" size="5xl" tag="h2">
                <span v-html="data.name"></span>
            </z-caption>
        </div>
    </div>
</template>

<script>
export default {
    name: 'cmd-card',
    props: {
        data: Object
    },
    methods: {
        decodeHtml (html) {
            var txt = document.createElement('textarea')
            txt.innerHTML = html
            return txt.value
        }
    }
}
</script>

<style lang="scss" scoped>
.cmd-card {
    position: relative;
    background: #ffb861;
    color: $token-colors-tertiary;
    @include padding-level(top, M);
    @include padding-level(bottom, M);
    @include padding-level(left, M);
    @include padding-level(right, M);
    border-radius: 20px;

    &:after {
        content: '';
        background-image: url(/images/logo/qiwi.svg);
        background-repeat: no-repeat;
        background-position: 90% 160%;
        opacity: 0.4;
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-size: auto 118%;
        z-index: 0;
    }

    &__body {
        position: relative;
        z-index: 2;
    }

    &__caption {
        color: $token-colors-black;
    }

    &__date {
        margin-right: 100px;
    }

    &__date-block {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
    }

    &__time {
        margin-top: 4px;
        display: flex;

        .z-icon {
            margin-right: $token-spacers-3-xs;
            margin-top: 2px;

            path {
                fill: $token-colors-tertiary;
            }
        }
    }
}
</style>
