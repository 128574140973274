/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'help': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12 3c4.968 0 9 4.031 9 9 0 4.968-4.032 9-9 9-4.969 0-9-4.032-9-9 0-4.969 4.031-9 9-9zm10 9c0-5.52-4.48-10-10-10S2 6.48 2 12s4.48 10 10 10 10-4.48 10-10z" _fill="#C4C4C4"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M10.31 7.612c-.185.121-.252.191-.305.447-.053.256.315.79.315.79.09.166.226.25.409.25a.464.464 0 00.243-.075c.08-.05.176-.104.285-.165a1.85 1.85 0 01.956-.239c.293 0 .525.08.693.24.168.159.253.373.253.642 0 .233-.05.433-.15.602-.1.168-.223.32-.368.457a4.45 4.45 0 01-.467.383 3.73 3.73 0 00-.45.377c-.135.132-.24.28-.317.444a.978.978 0 00-.064.575l.061.472a.7.7 0 00.694.61h.161a.7.7 0 00.688-.569l.07-.372c.03-.14.1-.265.209-.377.108-.112.236-.225.383-.34.147-.114.303-.238.467-.373a2.503 2.503 0 00.79-1.126c.09-.249.134-.544.134-.884 0-.359-.06-.685-.182-.979a2.107 2.107 0 00-.521-.753 2.318 2.318 0 00-.822-.48A3.281 3.281 0 0012.391 7c-.285 0-.55.026-.796.077a3.605 3.605 0 00-.687.216 3.957 3.957 0 00-.598.319zm.767 7.996A.995.995 0 0011 16a1.017 1.017 0 00.287.713c.089.09.194.16.315.211.121.05.254.076.398.076a1.005 1.005 0 00.71-.287.962.962 0 00.213-.318c.051-.123.077-.255.077-.395a.995.995 0 00-.29-.708c-.09-.09-.197-.16-.318-.213A.974.974 0 0012 15a.988.988 0 00-.923.608z" _fill="#000"/>'
  }
})
