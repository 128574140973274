window.onscroll = function () {
    myFunction()
}

function myFunction () {
    var scrollHeight = document.documentElement.scrollHeight - window.innerHeight
    var navbar = document.querySelector('.header')
    // var sticky = navbar.offsetTop

    if (scrollHeight > 130) {
        if (window.pageYOffset >= 50) {
            navbar.classList.add('sticky')
        } else {
            navbar.classList.remove('sticky')
        }
    }
}
