<template>
    <div>
        <form class="subscribe-form" @submit.prevent="onSubmitForm" v-if="!isFormHidden">
            <div
                v-if="!edit"
                class="subscribe-form__container u-bottom-margin--m"
                style="max-width: 700px"
            >
                <z-input
                    name="email"
                    type="email"
                    :value="params.email"
                    @change="onChange"
                    :placeholder="text.labelEmail[lang]"
                ></z-input>
            </div>
            <div class="subscribe-form__container u-bottom-margin--m">
                <z-checkbox
                    v-for="item in rubrics"
                    :key="item.id"
                    :name="item.id"
                    :checked="item.selected"
                    @change="onChange({
                        name: 'rubrics',
                        value: item.id
                    })"
                >{{item.name}}</z-checkbox>
            </div>
            <div class="subscribe-form__container u-bottom-margin--m" v-if="!edit">
                <z-checkbox
                    @change="onChange"
                    v-model="params.agreement"
                    name="agreement"
                ><span v-html="text.agreement[lang]"></span></z-checkbox>
            </div>
            <z-button
                kind="primary"
                size="m"
                type="submit"
                class="subscribe-form__btn"
                :disabled="!isSubmitAvalible"
            >
                <span v-html="text.submitBtn[lang]"></span>
            </z-button>
        </form>

        <template v-if="isSendSuccess">
            <p
                :class="['subscribe-form-msg', { 'subscribe-form-msg--green' : edit }]"
            >
                <span v-html="successMsg"></span>
            </p>
        </template>
    </div>
</template>

<script>
// import axios from 'axios'
// import moment from 'moment'
// import { localize } from '@/utils/i18n'
import { debounce } from 'throttle-debounce'

export default {
    name: 'subscription',
    props: {
        edit: {
            type: Boolean,
            default: false
        },
        initial: {
            type: Object
        }
    },
    data () {
        return {
            lang: this.$root.lang,
            apiUrl: {
                add: `/${this.$root.lang}/api/subscribe/add`,
                edit: `/${this.$root.lang}/api/subscribe/edit`
            },
            rubrics: this.$root.app.components['subscribe-rubrics'],
            error: {
                email: null
            },
            text: {
                agreement: {
                    ru: 'Я ознакомлен(-а) с <a href="/privacy-notice/" target="_blank">Положением о конфиденциальности</a> и даю согласие на передачу и обработку моих персональных данных',
                    en: 'I have read the <a href="/privacy-notice/" target="_blank">Privacy Notice</a> and I consent to the transfer and processing of my personal data.'
                },
                submitBtn: {
                    ru: 'Сохранить',
                    en: 'Save'
                },
                labelEmail: {
                    ru: 'Ваш e-mail',
                    en: 'E-mail'
                },
                error: {
                    email: {
                        empty: {
                            ru: 'Пожалуйста, укажите ваш Email',
                            en: 'Enter your e-mail'
                        },
                        incorrect: {
                            ru: 'Неверный формат e-mail',
                            en: 'Enter your e-mail correctly'
                        }
                    }
                },
                successMsg: '', // приходит с бека
                socialLinksText: {
                    ru: 'Следите за новостями в наших соцсетях:',
                    en: 'Follow the news on our social networks:'
                }
            },
            params: {
                email: '',
                rubrics: [],
                agreement: false,
                captcha: ''
            },
            isFormHidden: false,
            isSendSuccess: false,
            gKey: '6Lf_0lEeAAAAAHcUzv6e1HH6_ljF_NTdBqaizvbO'
        }
    },
    computed: {
        isSubmitAvalible () {
            if (!this.edit) {
                return this.validateEmail(this.params.email) &&
                    this.params.rubrics.length &&
                    this.params.agreement
            } else {
                return true
            }
        }
    },
    methods: {
        onChange (data) {
            if (data.name === 'rubrics') {
                this.params[data.name] = this.params[data.name].map(val => val.toString())

                if (this.params[data.name].includes(data.value)) {
                    this.params[data.name] = this.params[data.name].filter(e => e !== data.value)
                } else {
                    this.params[data.name].push(data.value)
                }
            } else {
                this.params[data.name] = data.value
            }
        },
        onSubmitForm () {
            this.sendForm(this)
        },
        sendForm: debounce(1000, function (_this) {
            grecaptcha.ready(function () {
                grecaptcha
                    .execute(_this.gKey, {
                        action: 'subscriptionCaptcha'
                    })
                    .then(function (token) {
                        let url
                        _this.edit ? url = _this.apiUrl.edit : url = _this.apiUrl.add
                        _this.params.captcha = token

                        let formData = new FormData()
                        for (let key in _this.params) {
                            if (Array.isArray(_this.params[key])) {
                                _this.params[key].forEach((item) => {
                                    formData.append(`${key}[]`, item)
                                })
                            } else {
                                formData.append(key, _this.params[key])
                            }
                        }

                        fetch(
                            url,
                            {
                                method: 'POST',
                                body: formData
                            })
                            .then(
                                response => {
                                    if (response.ok) {
                                        return response.json()
                                    }
                                    throw new Error('Request failed!')
                                },
                                networkError => console.log(networkError.message)
                            )
                            .then(
                                jsonResponse => {
                                    if (jsonResponse.status === 'error') {
                                        _this.error.email = jsonResponse.message
                                    } else {
                                        _this.isSendSuccess = true
                                        _this.successMsg = jsonResponse.message
                                        _this.edit ? _this.isFormHidden = false : _this.isFormHidden = true
                                    }
                                }
                            )
                    }, function (reason) {
                        _this.isSendSuccess = false
                        console.log(reason)
                    })
            })
        }),
        checkEmail () {
            if (this.params.email !== '') {
                this.validateEmail(this.params.email) ? this.error.email = '' : this.error.email = this.text.error.email.incorrect[this.lang]
            } else {
                this.error.email = this.text.error.email.empty[this.lang]
            }
        },
        clearEmailError () {
            this.error.email = ''
        },
        validateEmail (email) {
            // eslint-disable-next-line no-useless-escape
            let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return regex.test(email)
        }
    },
    created () {
        const script = document.createElement('script')

        script.async = true
        script.src = `https://www.google.com/recaptcha/api.js?render=${this.gKey}`

        document.head.appendChild(script)

        if (this.edit) {
            this.rubrics.forEach((item) => {
                if (item.selected) {
                    this.params.rubrics.push(Number(item.id))
                }
            })

            let params = Object.assign({}, this.params)
            params['CONFIRM_CODE'] = this.$root.app.system.params.get['CONFIRM_CODE']
            params['ID'] = this.$root.app.system.params.get['ID']

            this.params = params
        }

        if (this.initial?.email) {
            this.params.email = this.initial.email
        }

        if (this.initial?.rubrics) {
            this.params.rubrics = this.initial.rubrics
        }
    }
}
</script>

<style lang="scss">
    .subscribe-form-msg {
        b {
            color: $token-colors-primary;
        }

        &--green {
            color: $token-colors-green;
        }

        &--red {
            color: $token-colors-red;
        }
    }
    .subscribe-form + .subscribe-form-msg {
        margin-top: 16px;
    }
</style>
