/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'presentation': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M0 4a1 1 0 011-1h22a1 1 0 110 2v12a2 2 0 01-2 2h-8v2a1 1 0 110 2h-2a1 1 0 110-2v-2H3a2 2 0 01-2-2V5a1 1 0 01-1-1zm21 1H3v12h18V5zM10.12 6.828A.75.75 0 009 7.48v6.54a.75.75 0 001.12.652l5.75-3.27a.75.75 0 000-1.304l-5.75-3.27zm3.863 3.922L10.5 12.73V8.77l3.483 1.98z" _fill="#FF8C00"/>'
  }
})
