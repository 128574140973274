<template>
    <div class="main-page">
        <banner />
        <!-- <div class="main-page__container">
            <div class="container main-page-container">
                <div class="main-page-title">
                    <z-caption class="u-collapse--all" tag="h2" size="5xl">
                        Key numbers
                    </z-caption>
                    <z-link class="main-page-title__link hide-me--mobile" size="s" href="/results-and-reports/key-figures/">Learn more</z-link>
                </div>
                <div class="row">
                    <div class="col-default-9 col-v-tablet-12">
                        <div class="row">
                            <div class="col-default-4 col-tablet-6 col-mobile-12">
                                <z-key-indicator
                                    value="41%"
                                    caption="Adjusted Net Profit margin"
                                >
                                    <template v-slot:icon>
                                        <z-icon
                                            name="graphick"
                                            width="48"
                                            height="48"
                                        />
                                    </template>
                                </z-key-indicator>
                            </div>
                            <div class="col-default-4 col-tablet-6 col-mobile-12">
                                <z-key-indicator
                                    value="156 billion RUB"
                                    caption="Payments monthly"
                                >
                                    <template v-slot:icon>
                                        <z-icon
                                            name="coin_rub"
                                            width="48"
                                            height="48"
                                        />
                                    </template>
                                </z-key-indicator>
                            </div>
                            <div class="col-default-4 col-tablet-6 col-mobile-12">
                                <z-key-indicator
                                    value="ESG"
                                    caption="committed"
                                >
                                    <template v-slot:icon>
                                        <z-icon
                                            name="esg"
                                            width="48"
                                            height="48"
                                        />
                                    </template>
                                </z-key-indicator>
                            </div>
                        </div>
                    </div>

                    <div v-if="presentation" class="col-default-3 col-v-tablet-12">
                        <z-card class="mp-presentation" :link="presentation.link" full-height bordered decor-right decor-padding="none">
                            <template v-slot:title>
                                <z-caption class="u-collapse--all" size="3xl">{{presentation.name}}</z-caption>
                            </template>
                            <template v-slot:content>
                                <z-filelist>
                                    <z-filelist-item icon="file">
                                        {{presentation.type}}
                                    </z-filelist-item>
                                </z-filelist>
                            </template>
                        </z-card>
                    </div>
                </div>

                <z-link class="hide-me--default show-me--mobile u-top-margin--s" size="s" href="/company/at-a-glance/">Learn more</z-link>
            </div>
        </div> -->

        <investment-case />

        <!-- <div class="container main-page-container">
            <products-and-services class="main-page__container main-page__container--border-bottom" />
        </div> -->

        <div class="main-page__container">
            <div class="container main-page-container">
                <div class="main-page-title">
                    <z-caption class="u-collapse--all" tag="h2" size="5xl">
                        News
                    </z-caption>
                    <z-link class="main-page-title__link hide-me--mobile" size="s" href="/news-and-events/press-releases/">Read more</z-link>
                </div>
                <latest-news />
                <z-link class="hide-me--default show-me--mobile u-top-margin--s" size="s" href="/news-and-events/press-releases/">Read more</z-link>
            </div>
        </div>

    </div>
</template>

<script>
import {
    Banner,
    InvestmentCase,
    ProductsAndServices
} from './components'

export default {
    name: 'main-page',
    components: {
        Banner,
        InvestmentCase,
        ProductsAndServices
    },
    data () {
        return {
            data: {
                link: '#',
                name: 'QIWI Strategy',
                size: '0 Б',
                type: ''
            }
        }
    },
    computed: {
        presentation () {
            if (!this.$root.app.components['main-page'] && !this.$root.app.components['main-page'].presentation) return
            return this.$root.app.components['main-page'].presentation
        }
    },
    created () {
    }
}
</script>

<style lang="scss">
.main-page {
    &__container {
        @include padding-level(top, 2XL);
        @include padding-level(bottom, 2XL);

        &--border-bottom {
            border-bottom: 1px solid $token-colors-gray-20;
        }
    }
}

.main-page-title {
    display: flex;
    align-items: flex-end;
    @include margin-level(bottom, XL);

    &__link {
        flex-shrink: 0;
        margin-left: auto;
        @include margin-level(top, S);
    }
}

.main-page-container {
    max-width: 1516px;
}

.mp-presentation {
    .z-caption {
        width: 100%;

        &__text {
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
        }
    }
}
</style>
