<template>
    <tippy-component
        class="z-tooltip"
        :interactive="setInteractive()"
        :theme="theme"
        :arrow="arrow"
        :trigger="trigger"
        :placement="tooltipPlacement"
        :class="{ 'z-tooltip--click': trigger === 'click' }"
        ref="tippy-tooltip"
        @show="stateTooltip"
        @hide="stateTooltip"
    >
        <template v-slot:trigger>
            <slot />
            <z-icon
                v-show="!iconHidden"
                width="20"
                height="20"
                name="help"
                class="z-tooltip__icon"
            />
        </template>
        <span>
            <div class="z-tooltip__close" @click="hideTooltip" v-show="trigger === 'click'">
                <z-icon
                    width="12"
                    height="12"
                    name="cross"
                />
            </div>
            <slot v-if="!!this.$slots['content']" name="content"></slot>
            <span v-else v-html="content"></span>
        </span>
    </tippy-component>
</template>

<script>
import { TippyComponent } from 'vue-tippy'
import { mixinDevice } from '@/utils/mixin'

export default {
    name: 'z-tooltip',
    mixins: [mixinDevice],
    components: {
        TippyComponent
    },
    props: {
        text: String,
        theme: {
            type: String,
            default: 'light'
        },
        arrow: {
            type: Boolean,
            default: true
        },
        placement: {
            type: String,
            default: 'bottom-start'
        },
        content: {
            type: String
        },
        trigger: {
            type: String,
            default: 'mouseenter'
        },
        iconHidden: Boolean
    },
    data () {
        return {
            isInteractive: false
        }
    },
    computed: {
        tooltipPlacement () {
            if (this.device === 'mobile') {
                return 'bottom'
            } else {
                return this.placement
            }
        }
    },
    methods: {
        stateTooltip () {
            const tippy = this.$refs['tippy-tooltip']
            const icon = tippy.tip.reference.querySelector('.z-tooltip__icon')

            if (!tippy.tip.state.isShown) {
                icon.classList.add('z-tooltip__icon--active')
            } else {
                icon.classList.remove('z-tooltip__icon--active')
            }
        },
        hideTooltip () {
            const tippy = this.$refs['tippy-tooltip']
            tippy.tip.hide()
        },
        setInteractive () {
            if (this.trigger === 'click') {
                this.isInteractive = true
            } else {
                this.isInteractive = false
            }
            return this.isInteractive
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
