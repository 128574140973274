<template>
    <z-card
        class="databook-card"
        :link="dataComputed.link"
        bordered
        small
        v-if="dataComputed"
        :target="blank ? '__blank' : false"
    >
        <template v-slot:content>
            <div class="databook-card__content">
                <z-caption
                    class="u-collapse--top u-collapse--bottom u-right-margin--2xs"
                    size="3xl"
                >
                    <span v-html="dataComputed.name"></span>
                </z-caption>
                <z-filelist>
                    <z-filelist-item :icon="dataComputed.type === 'pdf' ? 'file' : 'database'">
                        <span v-html="dataComputed.type"></span>
                    </z-filelist-item>
                </z-filelist>
            </div>
        </template>
    </z-card>
</template>

<script>
export default {
    name: 'databook',
    props: {
        data: Object,
        blank: Boolean
    },
    data () {
        return {
            dataComputed: null
        }
    },
    mounted () {
        if (this.data) {
            this.dataComputed = this.data
        } else {
            this.dataComputed = this.$root.app.components['data-book']
        }
    }
}
</script>

<style lang="scss">
.databook-card {
    &__content {
        display: flex;
        align-items: center;
    }

    &.databook-card {
        border-width: 1px;

        .z-card__body {
            padding: 16px 20px;
        }

        .z-filelist-item--with-icon .z-filelist-item__icon {
            margin-top: 0;
        }

        .z-filelist-item__slot {
            margin-top: 0;
        }

        .z-filelist-item__body {
            align-items: center;
        }
    }
}

.page__title + .databook-card {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: -12px;

    @media (max-width: 768px) {
        position: relative;
        margin-top: 0;
        margin-bottom: 24px;
    }
}
</style>
