const qs = require('query-string')

function queryString (params) {
    let string = ''
    let keys = Object.keys(params)
    let hash = window.location.hash
    let url = window.location.pathname

    delete params.page
    delete params.type
    delete params.code

    url += `?`

    if (keys.length > 0) string = qs.stringify(params, { arrayFormat: 'bracket' })

    url += `${string}${hash}`

    if (history.pushState) {
        try {
            history.pushState({}, '', url)
            return
        } catch (err) {
            console.error('Error in queryString!')
        }
    } else {
        document.location.href = url
    }
}

export { queryString }
