/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tochka_bank': {
    width: 72,
    height: 76,
    viewBox: '0 0 72 76',
    data: '<ellipse pid="0" cx="36" cy="37.601" rx="36" ry="37.601" _fill="#F5F5F5"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M29.423 37.011c0-1.647-.885-3.213-2.27-4.017a4.86 4.86 0 00-3.73-.562c-1.038.2-1.962.763-2.654 1.607-.73.924-1.154 2.089-1.115 3.294-.077 1.446.461 2.852 1.5 3.816a4.823 4.823 0 003.423 1.286c1.307.04 2.538-.483 3.5-1.406 1-1.085 1.5-2.531 1.346-4.018zm-3.077 1.929a2.043 2.043 0 01-2.154 1.004 2.09 2.09 0 01-1.73-1.607 3.162 3.162 0 010-1.888c.269-1.004 1.153-1.687 2.153-1.687.808-.04 1.539.482 1.846 1.245a3.056 3.056 0 01-.115 2.933zm33.346.883v-3.615c0-.362 0-.723-.077-1.085-.23-1.406-1.154-2.21-2.423-2.53-1.192-.282-2.462-.322-3.692-.04-.577.12-1.154.28-1.693.562l.346 1.968c1.308-.643 2.77-.763 4.116-.321.423.12.73.442.808.883.038.282.038.563 0 .804-1.616-.603-2-.683-3.27-.482-2 .321-3 1.888-2.654 3.937.193 1.285 1.424 2.37 2.924 2.49 1.384.08 2.653-.24 3.5-1.566 0 .066.011.132.022.192a.808.808 0 01.016.13c.038.441.308.803.692 1.003a3.164 3.164 0 002.193.08v-1.526c-.693-.08-.77-.16-.808-.884zm-3.615.201c-.423.201-.885.241-1.308.201a1.137 1.137 0 01-1-.763c-.154-.522 0-1.045.423-1.366a1.75 1.75 0 011.462-.321c.5.04 1 .24 1.384.522.154.723-.27 1.486-.961 1.727zm-12.231-1.647l1.423-.08 2.115 3.937h2.77c-.077-.201-.154-.402-.231-.563-.366-.642-.74-1.295-1.116-1.948v-.001c-.375-.653-.75-1.305-1.115-1.947-.038-.06-.067-.12-.096-.181a1.65 1.65 0 00-.096-.18c1.846-1.086 2.192-2.853 2.154-4.822H47.23c-.115.683-.23 1.326-.384 1.969a2.004 2.004 0 01-1.346 1.446c-.539.12-1.116.16-1.693.12v-3.535h-2.615v9.561h2.654v-3.776zm-7.308-5.785h2.577v9.642H36.5V39.14c-.462.12-.923.24-1.385.32a3.86 3.86 0 01-3.27-.803 4.223 4.223 0 01-1.307-2.45c-.154-.776-.137-1.57-.12-2.37.01-.4.018-.803.005-1.205h2.692v.442c0 .763 0 1.566.039 2.33.038.964.807 1.727 1.73 1.727.577 0 1.154-.16 1.616-.522v-4.017h.038zm-22.807 9.561h2.577v-7.15H19v-2.41h-8v2.45h2.73v7.11z" _fill="#000"/>'
  }
})
