<template>
    <label
        :for="name"
        class="z-textarea"
        :class="{
            'is-error': error,
            'is-filled': val,
            'is-focused': focus,
            'is-resizable': resize,
            'is-disabled': disabled
        }"
    >
        <textarea
            :id="name"
            :name="name"
            :disabled="disabled"
            @blur="focus = false"
            @focus="focus = true"
            @change="change($event.target.value)"
            :value="val"
            cols="30"
            rows="10"
        ></textarea>
        <span
            v-if="placeholder && !focus && !val"
            v-html="placeholder"
            class="z-textarea__placeholder"
        ></span>
        <span
            class="z-textarea__error"
            v-if="required && error"
        >{{ text.errors.required }}</span>
    </label>
</template>

<script>
export default {
    name: 'z-textarea',
    props: {
        name: {
            type: String,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        resize: {
            type: Boolean,
            default: false
        },
        required: Boolean,
        placeholder: String,
        value: [Number, String]
    },
    data () {
        return {
            val: this.value,
            error: false,
            focus: false,
            text: {
                errors: {
                    required: this.$root.lang === 'ru' ? 'Поле обязательно для заполнения' : 'Required field'
                }
            }
        }
    },
    mounted () {
        this.$root.$bus.$on('clear', name => {
            if (this.name === name) this.change('')
        })
    },
    beforeDestroy () {
        this.$root.$bus.$off('clear', name => {
            if (this.name === name) this.change('')
        })
    },
    methods: {
        change (value) {
            if (this.required && !value) {
                this.val = ''
                this.error = true
            } else {
                this.val = value
                this.error = false
            }

            this.$nextTick(() => this.$emit('change', { value: value, name: this.name }))
        }
    }
}

</script>

<style lang="scss" src="./index.scss"></style>
