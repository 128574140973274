<template>
    <nav
        class="navigation-bottom"
        aria-label="Bottom navigation"
    >
        <ul
            :class="`navigation-bottom__${type}`"
        >
            <li
                v-for="(item, index) in data[type]"
                :class="[
                    'navigation-bottom_item',
                    {
                        'is-hide': (item.hasOwnProperty('params') && !Array.isArray(item.params) && item.params.hidePage),
                        'navigation-bottom__item--pb': (item.hasOwnProperty('params') && !Array.isArray(item.params) && item.params.paddingBottom)
                    }
                ]"
                :key="index"
            >
                <z-link
                    :href="item.link"
                    :external="item.external"
                    theme="black"
                >{{ item.name }}</z-link>
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    name: 'navigation-bottom',
    props: {
        type: {
            type: String,
            default: 'second'
        }
    },
    data () {
        return {
            data: this.$root.app.components.navigation.bottom
        }
    }
}

</script>

<style lang="scss" src="./index.scss"></style>
