/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'social/vk': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<circle pid="0" cx="16" cy="16" r="16" _fill="#2F363F"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M23.755 11.046c.625 0 .757.322.625.761-.263 1.208-2.785 4.761-2.786 4.76-.218.352-.307.526 0 .922.108.151.339.377.603.636.271.266.578.566.823.855.89 1 1.56 1.846 1.746 2.427.17.584-.125.88-.717.88h-2.07c-.554 0-.833-.312-1.437-.983-.256-.285-.57-.634-.987-1.051-1.229-1.184-1.756-1.339-2.062-1.339-.416 0-.542.12-.542.71v1.857c0 .51-.163.806-1.478.806-2.195 0-4.604-1.332-6.316-3.788-2.567-3.597-3.268-6.317-3.268-6.867 0-.306.118-.585.71-.585h2.07c.529 0 .728.232.926.805 1.013 2.95 2.72 5.528 3.422 5.528.265 0 .385-.122.385-.79v-3.049c-.048-.869-.351-1.244-.576-1.523-.138-.171-.247-.306-.247-.496 0-.232.198-.476.527-.476h3.255c.439 0 .59.235.59.761v4.102c0 .44.192.592.323.592.262 0 .483-.153.964-.637 1.492-1.665 2.546-4.233 2.546-4.233.132-.306.373-.585.9-.585h2.07z" _fill="#fff"/>'
  }
})
