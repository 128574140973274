<template>
    <div class="z-table" :class="tableClass">
        <div class="z-table__header" v-if="hasHeaderSlotData">
            <slot name="header" />
        </div>
        <div class="z-table__container">
            <table>
                <colgroup v-if="colgroup && colgroup.length">
                    <col
                        v-for="(item, index) in colgroup"
                        :key="index"
                        span="1"
                        :style="`width: ${item};`"
                    >
                </colgroup>
                <thead>
                    <tr class="z-table-row z-table-row--head">
                        <th
                            class="z-table-col z-table-col--head"
                            v-for="(item, index) in columns"
                            :class="columnClass(index)"
                            @click="sort(index)"
                            :key="index"
                        >
                            <vue-raw :raw="item" />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <template v-if="hasDefaultSlotData">
                        <slot />
                    </template>
                    <template v-else>
                        <z-table-row
                            v-for="(row, rowIndex) in tableData"
                            :key="rowIndex"
                        >
                            <z-table-col
                                v-for="(col, columnIndex) in row"
                                :index="columnIndex"
                                :key="columnIndex"
                            >
                                <span v-html="col"></span>
                            </z-table-col>
                        </z-table-row>
                    </template>
                </tbody>
            </table>
        </div>
        <div class="z-table__footer" v-if="hasFooterSlotData">
            <slot name="footer" />
        </div>
    </div>
</template>

<script>
import { mixinDevice } from '@/utils/mixin'

export default {
    name: 'z-table',
    mixins: [mixinDevice],
    props: {
        columns: {
            type: Array,
            default: () => []
        },
        colgroup: {
            type: Array,
            default: () => []
        },
        data: {
            type: Array,
            default: () => []
        },
        sortableColumn: {
            type: Number,
            default: 0
        },
        sortingDefault: {
            type: String,
            default: 'desc'
        },
        financial: Boolean,
        sortable: Boolean,
        responsive: Boolean,
        scrollable: {
            type: Boolean,
            default: true
        },
        highlightedLastColumn: Boolean,
        native: Boolean // for default auto width column
    },
    data () {
        return {
            tableData: this.data,
            sorting: this.sortingDefault
        }
    },
    mounted () {
        if (!this.sortable) return

        this.desc(this.sortableColumn)
    },
    computed: {
        hasHeaderSlotData () {
            return this.$slots.header
        },
        hasFooterSlotData () {
            return this.$slots.footer
        },
        hasDefaultSlotData () {
            return this.$slots.default
        },
        tableClass () {
            return {
                'z-table--responsive': this.responsive,
                'z-table--financial': this.financial,
                'z-table--scrollable': this.scrollable,
                'z-table--native': this.native,
                'z-table--highlighted-last-column': this.highlightedLastColumn
            }
        }
    },
    methods: {
        columnClass (index) {
            let arr = [
                {
                    'z-table-col--sortable': this.sortable && this.sortableColumn === index
                }
            ]

            if (this.sortable && this.sortableColumn === index) arr.push(`z-table-col--sortable-${this.sorting}`)

            return arr
        },
        sort (index) {
            if (!this.sortable) return
            if (this.sortableColumn !== index) return

            if (this.sorting === 'desc') {
                this.asc(index)
                this.sorting = 'asc'
            } else {
                this.desc(index)
                this.sorting = 'desc'
            }
        },
        desc (index) {
            this.tableData.sort((a, b) => {
                if (this.toNumber(a[index]) < this.toNumber(b[index])) return 1
                if (this.toNumber(a[index]) > this.toNumber(b[index])) return -1
                return 0
            })
        },
        asc (index) {
            this.tableData.sort((a, b) => {
                if (this.toNumber(a[index]) > this.toNumber(b[index])) return 1
                if (this.toNumber(a[index]) < this.toNumber(b[index])) return -1
                return 0
            })
        },
        toNumber (value) {
            return parseInt(value.replace(/\s/g, ''))
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
