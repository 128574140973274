// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import { TippyComponent } from 'vue-tippy'
import App from './App'
import store from './store/'

import '@/utils/filter' // global
import '@/utils/modernizr-custom' // global
import '@/utils/polyfills/object-fit' // global
import '@/utils/correctExtensions' // global
import { VueAxios } from '@/utils/request'
import { AppDeviceEnquire } from '@/utils/mixin'
// import { showNotyfications } from '@/utils/notifications'

/* Components */
import NavigationTop from './components/NavigationTop'
import NavigationMain from './components/NavigationMain'
import NavigationMobile from './components/NavigationMobile'
import NavigationBottom from './components/NavigationBottom'
import Social from './components/Social'
import SiteSelector from './components/SiteSelector'
import NavigationLeft from './components/NavigationLeft'
import PageTools from './components/PageTools'
import CardListItem from './components/CardListItem'
import CardMedia from './components/CardMedia'
import ListFilters from './components/ListFilters'
import ListFiltersMobile from './components/ListFiltersMobile'
import CookiePopup from './components/CookiePopup'
import Remind from './components/Remind'
import MainSlider from './components/MainSlider'
import LastUpdates from './components/LastUpdates'
import NewsSlider from './components/NewsSlider'
import FastSearch from './components/FastSearch'
import DateBlock from './components/DateBlock'
import { Slider, Slide } from './components/Slider'
import AnchorMenu from './components/AnchorMenu'
import BackLink from './components/BackLink'
import Tag from './components/Tag'

/* Views */
import { Bio, BioItem, BioDetail } from './views/Bio'
import News from './views/News'
import SiteMap from './views/SiteMap'
import Calendar from './views/Calendar'
import Documents from './views/Documents'
import ContactsList from './views/ContactsList'
import AnnualReports from './views/AnnualReports'
import ReportsAndResults from './views/ReportsAndResults'
import FinancialStatements from './views/FinancialStatements'
import DetailPage from './views/DetailPage'
import Search from './views/Search'
import MainPage from './views/MainPage'
import LatestNews from './views/LatestNews'
import StockData from './views/StockData'
import InvestmentCase from './views/InvestmentCase'
import { SecFillings, SecFillingsDetail } from './views/SecFillings'
import Committees from './views/Committees'
import GeneralMeetings from './views/GeneralMeetings'
import Cmd from './views/Cmd'
import History from './views/History'
import Subscription from './views/Subscription'
import DatabookCard from './views/ReportsAndResults/components/DatabookCard'
import Sustainability from './views/Sustainability'

/* Components */
Vue.component('tippy-tooltip', TippyComponent)
Vue.component('navigation-top', NavigationTop)
Vue.component('navigation-main', NavigationMain)
Vue.component('navigation-mobile', NavigationMobile)
Vue.component('navigation-bottom', NavigationBottom)
Vue.component('social', Social)
Vue.component('site-selector', SiteSelector)
Vue.component('navigation-left', NavigationLeft)
Vue.component('page-tools', PageTools)
Vue.component('card-list-item', CardListItem)
Vue.component('card-media', CardMedia)
Vue.component('list-filters', ListFilters)
Vue.component('list-filters-mobile', ListFiltersMobile)
Vue.component('cookie-popup', CookiePopup)
Vue.component('remind', Remind)
Vue.component('main-slider', MainSlider)
Vue.component('last-updates', LastUpdates)
Vue.component('news-slider', NewsSlider)
Vue.component('fast-search', FastSearch)
Vue.component('date-block', DateBlock)
Vue.component('slider', Slider)
Vue.component('slide', Slide)
Vue.component('anchor-menu', AnchorMenu)
Vue.component('back-link', BackLink)
Vue.component('tag', Tag)

/* Views */
Vue.component('bio', Bio)
Vue.component('bio-item', BioItem)
Vue.component('bio-detail', BioDetail)
Vue.component('news', News)
Vue.component('site-map', SiteMap)
Vue.component('calendar', Calendar)
Vue.component('documents', Documents)
Vue.component('contacts-list', ContactsList)
Vue.component('annual-reports', AnnualReports)
Vue.component('reports-and-results', ReportsAndResults)
Vue.component('financial-statements', FinancialStatements)
Vue.component('detail-page', DetailPage)
Vue.component('search', Search)
Vue.component('main-page', MainPage)
Vue.component('latest-news', LatestNews)
Vue.component('investment-case', InvestmentCase)
Vue.component('stock-data', StockData)
Vue.component('sec-fillings', SecFillings)
Vue.component('sec-fillings-detail', SecFillingsDetail)
Vue.component('committees', Committees)
Vue.component('general-meetings', GeneralMeetings)
Vue.component('cmd', Cmd)
Vue.component('history', History)
Vue.component('subscription', Subscription)
Vue.component('databook-card', DatabookCard)
Vue.component('sustainability', Sustainability)

// https://webpack.js.org/guides/dependency-management/#require-context
const requireComponent = require.context(
    './components',
    true,
    /Z[\w-]+\/index\.js$/
)

requireComponent.keys().forEach(fileName => {
    const componentConfig = requireComponent(fileName)
    const componentName = fileName.split('/')[1]
    Vue.component(componentName, componentConfig.default || componentConfig)
})

if (process.env.NODE_ENV !== 'production') {
    // window.onerror = function (msg, url, lineNo, columnNo, error) {
    //     showNotyfications(`${msg}<br>${url}#${lineNo}`, {
    //         type: 'error'
    //     })
    // }

    // Vue.config.errorHandler = function (err, vm, info) {
    //     console.error(`Error: ${err.toString()}\nInfo: ${info}\nVm component: ${vm.$options._componentTag}`)
    //     showNotyfications(`Error: ${err.toString()}<br>Info: ${info}<br>Vm component: ${vm.$options._componentTag}`, {
    //         type: 'error'
    //     })
    // }

    // Vue.config.warnHandler = function (msg, vm, trace) {
    //     console.warn(`Warn: ${msg.toString()}\nInfo: ${trace}\nVm component: ${vm.$options._componentTag}`)
    //     showNotyfications(`Warn: ${msg.toString()}<br>Info: ${trace}<br>Vm component: ${vm.$options._componentTag}`, {
    //         type: 'alert'
    //     })
    // }

    Vue.config.productionTip = false
}

Object.defineProperty(Vue.prototype, '$bus', {
    get () {
        return this.$root.bus
    }
})

Vue.config.productionTip = false

Vue.use(VueAxios)

/* eslint-disable no-new */
new Vue({
    el: '#app',
    mixins: [AppDeviceEnquire],
    store,
    data: {
        lang: window.App.page.lang,
        bus: new Vue({}),
        app: window.App
    },
    components: {
        App
    },
    mounted () {
        this.$el.classList.add('is-loaded')
    }
})
