import { localize } from '@/utils/i18n'

const app = {
    state: {
        device: 'desktop',
        burgerMenu: false,
        fastSearch: false,
        backLink: '',
        backText: localize({
            ru: 'Назад',
            en: 'Back'
        })
    },
    mutations: {
        ToggleDevice: (state, device) => {
            state.device = device
        },
        BurgerMenu: (state, bool) => {
            state.burgerMenu = bool
        },
        FastSearch: (state, bool) => {
            state.fastSearch = bool
        },
        BackLink: (state, path) => {
            state.backLink = path
        },
        BackText: (state, value) => {
            state.backText = value
        }
    },
    actions: {
        ToggleDevice ({ commit }, device) {
            commit('ToggleDevice', device)
        },
        BurgerMenu ({ commit }, bool) {
            commit('BurgerMenu', bool)
        },
        FastSearch ({ commit }, bool) {
            commit('FastSearch', bool)
        },
        BackLink: ({ commit }, path) => {
            commit('BackLink', path)
        },
        BackText: ({ commit }, value) => {
            commit('BackText', value)
        }
    }
}

export default app
