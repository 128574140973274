/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'search': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3 10.5a7.5 7.5 0 0012.05 5.963l3.9 3.901a1 1 0 001.414-1.414l-3.9-3.9A7.5 7.5 0 103 10.5zm7.5 5.5a5.5 5.5 0 100-11 5.5 5.5 0 000 11z" _fill="#2F363F"/>'
  }
})
