/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'contact': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M22.846 5.653a2.917 2.917 0 00-3.564-2.055c-8.762 2.295-14.88 10.176-14.88 19.17 0 10.932 8.972 19.824 20 19.823 11.028 0 20-8.892 20-19.825a19.57 19.57 0 00-.676-5.129 2.92 2.92 0 00-3.569-2.047 2.896 2.896 0 00-1.768 1.342 2.884 2.884 0 00-.296 2.196c.319 1.18.48 2.404.48 3.64 0 7.745-6.355 14.046-14.169 14.046-.74 0-1.468-.058-2.178-.167 3.294-.747 5.754-3.663 5.754-7.154 0-4.054-3.317-7.344-7.41-7.344-4.09 0-7.408 3.288-7.408 7.344 0 .768.12 1.53.359 2.26a13.915 13.915 0 01-3.288-8.987c0-6.372 4.333-11.956 10.54-13.579a2.895 2.895 0 001.772-1.337 2.883 2.883 0 00.3-2.197zm9.72-.676c-2.655-.26-5.02 1.663-5.283 4.294h.002c-.264 2.632 1.675 4.975 4.329 5.237 2.654.26 5.02-1.663 5.282-4.294.263-2.631-1.675-4.975-4.33-5.237z" _fill="#FF8C00"/>'
  }
})
