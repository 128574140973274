<template>
    <div class="products-and-services">
        <div class="main-page-title">
            <z-caption class="u-collapse--all" tag="h2" size="5xl">
                Products and services
            </z-caption>
            <z-link class="main-page-title__link hide-me--mobile" size="s" href="/company/products-and-services/">Learn more</z-link>
        </div>
        <slider
            theme="products"
            :options="{
                slidesPerView: 5,
                loop: true,
                breakpoints: {
                    600: {
                        slidesPerView: 1
                    },
                    1023: {
                        slidesPerView: 2
                    },
                    1359: {
                        slidesPerView: 4
                    }
                }
            }"
        >
            <slide>
                <div class="products-and-services__slide">
                    <z-link rel="noopener noreferrer" href="//qiwi.com/" target="_blank" class="products-and-services__link">
                        <z-image src="/images/main-page/products/wallet.svg"></z-image>
                    </z-link>
                </div>
            </slide>
            <slide>
                <div class="products-and-services__slide">
                    <z-link rel="noopener noreferrer" href="//qiwi.business/en/" target="_blank" class="products-and-services__link">
                        <z-image src="/images/main-page/products/business.svg"></z-image>
                    </z-link>
                </div>
            </slide>
            <slide>
                <div class="products-and-services__slide">
                    <z-link rel="noopener noreferrer" href="//www.contact-sys.com/" target="_blank" class="products-and-services__link">
                        <z-image src="/images/main-page/products/contact.svg"></z-image>
                    </z-link>
                </div>
            </slide>
            <slide>
                <div class="products-and-services__slide">
                    <z-link rel="noopener noreferrer" href="//rowi.com/" target="_blank" class="products-and-services__link products-and-services__link--dark">
                        <z-image src="/images/main-page/products/rowi.svg"></z-image>
                    </z-link>
                </div>
            </slide>
            <slide>
                <div class="products-and-services__slide">
                    <z-link rel="noopener noreferrer" href="//www.flocktory.com/en_US/" target="_blank" class="products-and-services__link products-and-services__link--dark">
                        <z-image src="/images/main-page/products/flocktory.svg"></z-image>
                    </z-link>
                </div>
            </slide>
        </slider>
        <z-link class="hide-me--default show-me--mobile u-top-margin--s" size="s" href="/company/products-and-services/">Learn more</z-link>
    </div>
</template>

<script>
export default {
    name: 'products-and-services'
}
</script>

<style lang="scss">
.products-and-services {
    &__slide {
        @include padding-level(left, S);
        @include padding-level(right, S);
    }

    &__link {
        .z-image {
            display: flex;
            align-items: center;
            filter: grayscale(100%);
            transition: .3s;

            &:hover {
                filter: grayscale(0);
            }
        }

        &--dark {
            .z-image {
                opacity: .7;
                transition: .3s;

                &:hover {
                    opacity: 1;
                }
            }
        }
    }

    .swiper-slide {
        align-self: center;
    }

}
</style>
