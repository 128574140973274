/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'load': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M22 12a1 1 0 11-2 0 8 8 0 10-8 8 1 1 0 110 2C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10z" _fill="#FF8C00"/>'
  }
})
