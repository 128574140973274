/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'visa': {
    width: 72,
    height: 76,
    viewBox: '0 0 72 76',
    data: '<ellipse pid="0" cx="36" cy="37.601" rx="36" ry="37.601" _fill="#F5F5F5"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M41.555 33.592c0 .852.95 1.42 2.513 2.27 2.581 1.278 3.804 2.838 3.736 5.038-.068 3.83-3.125 6.243-7.88 6.243-2.037-.071-3.939-.497-5.026-.993l.611-4.115.612.284c1.426.71 2.377.993 4.211.993 1.29 0 2.717-.568 2.717-1.774 0-.78-.543-1.348-2.31-2.27-1.697-.922-4.007-2.341-3.939-4.966 0-3.547 3.125-6.03 7.608-6.03 1.766 0 3.192.425 4.075.78l-.611 3.902-.408-.213c-.815-.355-1.834-.78-3.328-.71-1.766 0-2.581.781-2.581 1.561zM27.63 46.788l2.785-18.233h4.55l-2.852 18.233H27.63zm32.4-18.233h-3.464c-1.087 0-1.902.355-2.377 1.49l-6.725 16.743h4.755s.747-2.27.95-2.767h5.774c.136.639.544 2.767.544 2.767h4.211L60.03 28.555zm-5.57 11.777l.023-.07h3.781c-.136-.852-1.019-5.25-1.019-5.25l-.34-1.562a30.62 30.62 0 00-.454 1.324c-.094.287-.157.48-.157.45 0 0-1.432 3.876-1.81 5.037h-.024v.071zm-35.049.639l4.415-12.416h4.823l-7.132 18.233h-4.755l-4.007-15.82A19.503 19.503 0 008 28.91l.068-.426h7.268c.95.071 1.766.426 2.038 1.561l1.562 8.372.475 2.554z" _fill="url(#paint0_linear_5267_46347)"/><defs><linearGradient id="paint0_linear_5267_46347" x1="8" y1="37.635" x2="28.627" y2="37.635" gradientUnits="userSpaceOnUse"><stop stop-color="#271D64"/><stop offset="1" stop-color="#25449D"/></linearGradient></defs>'
  }
})
