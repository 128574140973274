<template>
    <div class="sec-fillings-wrapper">
        <!-- begin: filters -->
        <list-filters
            :data="sec.filters"
            v-if="Object.keys(sec.filters).length"
            @change="onChange"
            :class="{'is-disabled': loading}"
            @clear="clearFilters"
            :show-clear-btn="isParamsNotEmpty"
            :show-search-input="false"
        />
        <!-- end: filters -->

        <!-- <div class="news__divider news__divider--transparent" v-if="sec.list.length">
            <div class="news__title u-medium">{{ text.founded }}:&nbsp;</div>
            <div class="news__counter u-medium">{{ nav.count }}</div>
        </div> -->

        <div class="sec-fillings" v-if="sec.list && sec.list.length">
            <z-table
                class="sec-fillings__table"
                :columns="['Form', 'Description', 'Filing date', 'View']"
                :colgroup="['5%', '55%', '10%', '30%']"
            >
                <z-table-row
                    v-for="(item, index) in sec.list"
                    :key="index"
                >
                    <z-table-col index="0">
                        <span v-html="item.messageType"></span>
                    </z-table-col>
                    <z-table-col index="1">
                        <z-link :href="item.link">
                            <span v-html="item.name"></span>
                        </z-link>
                    </z-table-col>
                    <z-table-col index="2">
                        <span>{{ item.date | momentFilter('MM/DD/YYYY') }}</span>
                    </z-table-col>
                    <z-table-col index="3">
                        <z-link
                            v-for="(file, fileIndex) in item.files"
                            :key="fileIndex"
                            class="sec-fillings__link u-right-margin--2xs"
                            :href="file.link"
                            :icon="file.type === 'html' ? 'link' : 'file'"
                            theme="gray"
                            :inline="false"
                            target="_blank"
                            :download="buildFileName(file)"
                        >{{ file.type }}</z-link>
                    </z-table-col>
                </z-table-row>
            </z-table>

            <!-- <div class="sec-fillings__pagination">
                <z-pagination
                    @change-page="onChangePage"
                    :data="nav"
                />
                <z-preloader class="sec-fillings__preloader" v-if="loading" />
            </div> -->
        </div>

        <!-- begin: preloader -->
        <z-preloader v-if="loading" />
        <!-- end: preloader -->

        <!-- begin: not found -->
        <z-not-found
            v-if="!sec.list.length && !loading"
            :text="text.notFoundText"
            :title="text.notFoundTitle"
        />
        <!-- end: not found -->
    </div>
</template>

<script>
import axios from 'axios'
import { getSecFilings } from '@/api/sec-filings'
import { mixinDevice } from '@/utils/mixin'
import { debounce } from 'throttle-debounce'
import { queryString } from '@/utils/queryString'

export default {
    name: 'sec-fillings',
    mixins: [mixinDevice],
    props: {
        initial: {
            type: Object,
            default: () => {
                return {
                    years: '',
                    search: ''
                }
            }
        }
    },
    data () {
        return {
            ajax: 'ajax/sec.php',
            sec: {
                list: this.$root.app.components['sec-fillings'].items,
                filters: this.$root.app.components['sec-fillings'].filters
            },
            nav: {
                count: this.$root.app.components['sec-fillings'].nav.count,
                total: this.$root.app.components['sec-fillings'].nav.total,
                current: this.$root.app.components['sec-fillings'].nav.current
            },
            text: {
                clear: this.$root.lang === 'ru' ? 'Сбросить' : 'Clear',
                filters: this.$root.lang === 'ru' ? 'Фильтр' : 'Filter',
                founded: this.$root.lang === 'ru' ? 'Найдено' : 'Found',
                notFoundTitle: this.$root.lang === 'ru' ? 'Ничего не найдено' : 'No results found',
                notFoundText: this.$root.lang === 'ru' ? 'По&nbsp;Вашему запросу ничего не&nbsp;нашлось.' : 'Sorry, no matches were found for your request. Try changing your search terms.'
            },
            page: 1,
            loading: false,
            notFound: false,
            params: this.initial
        }
    },
    computed: {
        isParamsNotEmpty () {
            let params = Object.assign({}, this.params)

            delete params.page

            let arr = Object.values(params)

            for (let i = 0; i < arr.length; i++) {
                if (arr[i].toString().length > 0) {
                    return true
                }
            }
        }
    },
    mounted () {
        this.listenScroll()
        this.sec.list.forEach(report => {
            if (report.files.length === 0) {
                axios.get(`https://tools.eurolandir.com/tools/services/?func=GetPressReleaseById&companycode=cy-qiwi&v=gnw&format=json&id=${report.code}`)
                    .then((res) => {
                        if (res.data) {
                            if (res.data[0].attachments) {
                                report.files = res.data[0].attachments.map(obj => {
                                    let arrType = obj.filename.split('.')
                                    return {
                                        link: obj.url,
                                        name: obj.filename,
                                        type: arrType[arrType.length - 1],
                                        size: null
                                    }
                                })
                            }
                        }

                        report.files.sort((a, b) => {
                            if (a.type < b.type) { return -1 }
                            if (a.type > b.type) { return 1 }
                            return 0
                        })
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }

            report.files.sort((a, b) => {
                if (a.type < b.type) { return -1 }
                if (a.type > b.type) { return 1 }
                return 0
            })
        })
    },
    methods: {
        buildFileName (file) {
            if (file.hasOwnProperty('filename')) return file.filename

            let name = 'report'

            if (file.hasOwnProperty('name')) {
                name = file.name
            }

            // if (file.hasOwnProperty('type')) {
            //     name += `.${file.type}`
            // }

            return name
        },
        onChange (data) {
            this.page = 1
            this.params.page = this.page
            this.params[data.name] = data.value
            this.send(this.clearEmptyParams(this.params))
        },
        send: debounce(1000, function (params) {
            this.loading = true

            if (this.page === 1) this.sec.list = []

            getSecFilings(params).then(res => {
                this.updateNav(res)
                this.updateList(res)
                this.updateFilters(res)

                queryString(params)

                this.loading = false
            })
        }),
        updateNav (res) {
            if (res.hasOwnProperty('nav')) {
                this.nav.current = res.nav.current
                this.nav.total = res.nav.total
                this.nav.count = res.nav.count
            }
        },
        updateList (res) {
            if (res.hasOwnProperty('items')) {
                if (res.items.length === 0) this.notFound = true

                res.items.forEach(report => {
                    if (report.files.length === 0) {
                        axios.get(`https://tools.eurolandir.com/tools/services/?func=GetPressReleaseById&companycode=cy-qiwi&v=gnw&format=json&id=${report.code}`)
                            .then((res) => {
                                if (res.data) {
                                    if (res.data[0].attachments) {
                                        report.files = res.data[0].attachments.map(obj => {
                                            let arrType = obj.filename.split('.')
                                            return {
                                                link: obj.url,
                                                name: obj.filename,
                                                type: arrType[arrType.length - 1],
                                                size: null
                                            }
                                        })
                                    }
                                }

                                report.files.sort((a, b) => {
                                    if (a.type < b.type) { return -1 }
                                    if (a.type > b.type) { return 1 }
                                    return 0
                                })
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }

                    report.files.sort((a, b) => {
                        if (a.type < b.type) { return -1 }
                        if (a.type > b.type) { return 1 }
                        return 0
                    })
                })

                if (this.nav.current < 2) {
                    this.sec.list = res.items
                } else {
                    this.sec.list = this.sec.list.concat(res.items)
                }
            }
        },
        updateFilters (res) {
            if (res.hasOwnProperty('filters')) {
                if (Object.keys(res.filters).length) this.sec.filters = res.filters
            }
        },
        clearEmptyParams () {
            let params = Object.assign({}, this.params)

            Object.keys(params).forEach(function (key, index) {
                if (Array.isArray(params[key])) {
                    if (!params[key].length) delete params[key]
                } else if (!params[key]) {
                    delete params[key]
                }
            })

            return params
        },
        clearFilters () {
            this.params.years = ''
            this.$root.$bus.$emit('clear', 'search')
        },
        listenScroll () {
            window.addEventListener('scroll', debounce(500, e => {
                let offsetHeight = this.$el.offsetHeight
                let bottom = this.$el.offsetTop + offsetHeight
                let scroll = window.pageYOffset || document.documentElement.scrollTop
                let scrolled = scroll + document.documentElement.clientHeight
                let condition = scrolled >= (bottom - 100) && !this.loading && this.nav.current < this.nav.total

                if (condition) {
                    this.page++
                    this.params.page = this.page
                    this.send(this.clearEmptyParams(this.params))
                }
            }))
        }
    }
}
</script>

<style lang="scss">
.sec-fillings {
    $parent: &;
    position: relative;

    // &:after {
    //     content: '';
    //     position: absolute;
    //     left: 0;
    //     top: 0;
    //     background-color: #fff;
    //     width: 100%;
    //     height: 100%;
    //     opacity: 1;
    //     transition: opacity 0.25s;
    //     pointer-events: none;
    // }

    // &.is-loaded:after {
    //     opacity: 0;
    // }

    &__pagination {
        @include margin-level(top, M);
        position: relative;
    }

    &.is-loading {
        .z-pagination {
            opacity: 0.2;
            pointer-events: none;
        }
    }

    .z-table-col {
        &:nth-of-type(2) {
            min-width: 14em;
        }
    }

    &__preloader {
        position: absolute;
        height: 100% !important;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    &__link {
        white-space: nowrap;
    }
}
</style>
