<template>
    <div class="cmd-timelist" v-if="data.length">
        <div
            :class="[
                'cmd-timelist__item',
                { 'cmd-timelist__item--last': index + 1 === data.length }
            ]"
            v-for="(time, index) in data"
            :key="index"
        >
            <div class="cmd-timelist__counter">
                <div class="cmd-timelist__number">
                    <span>{{ index + 1 }}</span>
                </div>
            </div>

            <span class="cmd-timelist__item-content">
                <z-caption size="6xl" tag="p" class="u-bottom-margin--xs-important">
                    {{ time.value }}
                </z-caption>
                <z-caption class="u-collapse--all" size="5xl" tag="p" weight="600">
                    <span v-html="time.title"></span>
                </z-caption>
            </span>
        </div>

    </div>
</template>

<script>
export default {
    name: 'cmd-timelist',
    props: {
        data: {
            type: Array,
            defailt: () => []
        }
    }
}
</script>

<style lang="scss" scoped>
.cmd-timelist {
    $parent: &;

    &__item {
        display: flex;

        &--last {
            #{$parent}__counter {
                &:after,
                &:before {
                    display: none;
                }
            }

            #{$parent}__item-content {
                padding-bottom: 0;
            }
        }
    }

    &__item-content {
        @include padding-level(left, XL);
        padding-bottom: $token-spacers-2-xl;

        @include breakpoint (v-tablet) {
            padding-bottom: $token-spacers-m;
        }

        @include breakpoint (mobile) {
            padding-left: $token-spacers-2-xs;
        }
    }

    &__counter {
        flex-shrink: 0;
        position: relative;
        overflow: hidden;

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 60px;
            @include margin-level(top, XS);
            width: 1px;
            height: 100%;
            background-color: $token-colors-primary;
            left: 50%;

            @include breakpoint (mobile) {
                top: 40px;
            }
        }

        &:after {
            content: '';
            width: 100%;
            @include padding-level(top, XS);
            background: $token-colors-white;
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }

    &__number {
        width: 60px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $token-colors-primary;
        color: $token-colors-white;
        @include typo-level(XL);
        font-weight: 400;
        border-radius: 50%;

        @include breakpoint (mobile) {
            width: 40px;
            height: 40px;
            font-size: 22px;
        }
    }
}
</style>
