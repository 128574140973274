/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'rialweb_circle': {
    width: 72,
    height: 76,
    viewBox: '0 0 72 76',
    data: '<ellipse pid="0" cx="36" cy="37.601" rx="36" ry="37.601" _fill="#F5F5F5"/><g clip-path="url(#clip0_6436_49025)"><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M61.389 22h1.456l-.734 1.26-16.88 28.993H10l.734-1.26L27.615 22h33.773zm-32.414 1.26h31.68L44.917 50.29 28.975 23.26zm-1.047.704L12.191 50.992h31.68L27.927 23.964z" _fill="#000"/></g><defs><clipPath id="clip0_6436_49025"><path pid="2" _fill="#fff" transform="translate(10 22)" d="M0 0h53v30.797H0z"/></clipPath></defs>'
  }
})
