<template>
    <div class="fast-search">
        <a href="javascript: void(0)" @click="showSearchList(getFastSearch)">
            <z-icon
                :color="buildColor"
                :name="isIcon"
                width="24"
                height="24"
            />
        </a>
        <transition name="fade">
            <div class="fast-search__content" v-if="getFastSearch">
                <div class="fast-search__content--input">
                    <z-input
                        name="q"
                        theme="search"
                        @enter="goToFullSearch"
                        @input="onInput"
                        :class="{ 'fast-search__z-input' : data.items.length }"
                    ></z-input>
                    <ul class="fast-search__content--list" v-if="data.items.length">
                        <li v-for="(item, index) of data.items" :key="index">
                            <a :href="item.link" v-html="item.name"></a>
                        </li>
                        <li class="u-top-margin--m" v-if="data.items.length > 2">
                            <z-button
                                tag="a"
                                kind="text"
                                size="m"
                                :href="allResultsLink"
                            >
                                {{ text.allResults }} ({{ data.count }})
                            </z-button>
                        </li>
                    </ul>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { debounce } from 'throttle-debounce'
import { getFastSearch } from '@/api/fast-search'
import { mixinDevice } from '@/utils/mixin'

export default {
    name: 'fast-search',
    mixins: [mixinDevice],
    data () {
        return {
            data: {
                items: [],
                count: 0
            },
            query: '',
            lang: this.$root.lang,
            text: {
                allResults: this.$root.lang === 'ru' ? 'Показать все' : 'Show all'
            },
            params: {}
        }
    },
    methods: {
        goToFullSearch ({value}) {
            window.location = `/search/?q=${value}`
        },
        onInput (data) {
            this.params[data.name] = data.value

            if (data.value.length >= 3) {
                this.send(this.clearEmptyParams(this.params))
                this.query = data.value
            } else {
                this.data.items = []
                this.data.count = 0
                this.query = ''
            }
        },
        send: debounce(500, function (params) {
            getFastSearch(params)
                .then(res => {
                    this.data.items = res.items
                    this.data.count = res.nav.count
                    // let count = 0
                    // res.category.forEach(category => {
                    //     count += category.count
                    // })
                    // this.data.count = count
                })
        }),
        clearEmptyParams () {
            let params = Object.assign({}, this.params)

            Object.keys(params).forEach(function (key, index) {
                if (Array.isArray(params[key])) {
                    if (!params[key].length) delete params[key]
                } else if (!params[key]) {
                    delete params[key]
                }
            })

            return params
        },
        showSearchList (state) {
            this.$store.commit('BurgerMenu', false)
            this.$store.commit('FastSearch', !state)
            this.data.items = []
            this.data.count = 0
            this.query = ''

            if (this.show) {
                this.focusInput()
            }
        },
        focusInput () {
            this.$nextTick(() => this.$el.querySelector('[name="q"]').focus())
        }
    },
    computed: {
        allResultsLink () {
            return `/search/?q=${this.query}`
        },
        buildColor () {
            let isInvert = document.body.classList.contains('invert')
            return isInvert ? 'white' : 'black'
        },
        isIcon () {
            return !this.getFastSearch ? 'search' : 'cross'
        },
        getFastSearch () {
            return this.$store.getters.fastSearch
        },
        getBurgerMenu () {
            return this.$store.getters.burgerMenu
        }
    },
    watch: {
        getFastSearch (next, prev) {
            if (this.device !== 'mobile') return
            let header = document.querySelector('.header')

            if ((next && header) || this.getBurgerMenu) {
                header.classList.add('is-open')
                document.body.classList.add('is-disabled')
            } else {
                header.classList.remove('is-open')
                document.body.classList.remove('is-disabled')
            }
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
