<template>
    <div class="stock-tabs" v-if="ticker[currentTabID]">
        <div class="stock-tabs__nav">
            <button
                v-for="tab in tabs"
                :key="tab.id"
                class="stock-tabs__nav-item"
                :class="{
                    'stock-tabs__nav-item--active': tab.id === currentTabID
                }"
                @click="currentTabID = tab.id"
            >{{tab.text}}</button>
        </div>
        <div
            v-if="ticker[currentTabID]"
            :style="{
                'border-top-left-radius': currentTabID === tabs[0].id ? '0' : ''
            }"
            class="stock-data-ticker"
        >
            <span class="stock-data-ticker__title">Stock Data</span>
            <span class="stock-data-ticker__date">{{ticker[currentTabID].date}}</span>
            <div class="stock-data-ticker__value">
                <span class="stock-data-ticker__last">{{ticker[currentTabID].last}} <small>{{ticker[currentTabID].currency}}</small></span>
                <div class="stock-data-ticker__changes">
                    <span
                        class="stock-data-ticker__changes-percent"
                        :style="{
                            'color': ticker[currentTabID].percentIsNegative ? '#D0021B' : '#4BBD5C'
                        }"
                    >
                        <template v-if="ticker[currentTabID].percent !== '0.00%' && !ticker[currentTabID].percentIsNegative">+&nbsp;</template>{{ticker[currentTabID].percent}}
                    </span>
                    <span
                        class="stock-data-ticker__changes-number"
                        :style="{
                            'color': ticker[currentTabID].percentIsNegative ? '#D0021B' : '#4BBD5C'
                        }"
                    >{{ticker[currentTabID].change}}&nbsp;{{ticker[currentTabID].currency}}</span>
                </div>
            </div>

            <z-link
                class="stock-data-ticker__link"
                :href="`${prefixUrl}/investors/stock-data/`"
            >{{text.more}}</z-link>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import { localize } from '@/utils/i18n'
import { getFormatedStockData } from '@/utils/stockData.js'

const nasdaqData = {
    'Currency': 'USD',
    'Bid': '4.9400',
    'Ask': '5.0400',
    'Change': '0.0000',
    'ChangePercent': '0.0000',
    'Last': '5.6700',
    'High': '5.9473',
    'Low': '5.5300',
    'Volume': '0',
    'PreviousClose': '5.6700',
    'Date': '2022-02-25 16:00:01',
    'ISIN': 'US74735M1080',
    'Symbol': 'QIWI',
    'MarketName': 'NASDAQ',
    'NoShares': '62712975',
    'MarketCap': '355582568',
    'Open': '5.6000'
}

export default {
    name: 'stock-tabs',
    data () {
        return {
            ticker: {
                nasdaq: {
                    date: moment(nasdaqData.Date, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY'),
                    symbol: nasdaqData.Symbol,
                    last: `${parseFloat(nasdaqData.Last).toFixed(2)}`,
                    currency: nasdaqData.Currency,
                    change: `${parseFloat(nasdaqData.Change).toFixed(2)}`,
                    percent: `${parseFloat(nasdaqData.ChangePercent).toFixed(2)}%`,
                    percentIsNegative: nasdaqData.ChangePercent[0] === '-'
                },
                moex: null
            },
            tabs: [
                {
                    text: 'MOEX',
                    id: 'moex'
                },
                {
                    text: 'NASDAQ',
                    id: 'nasdaq'
                }
            ],
            currentTabID: 'moex',
            prefixUrl: this.$root.lang === 'en' ? '' : '/ru',
            text: {
                more: localize({
                    'ru': 'Подробнее',
                    'en': 'Learn more'
                })
            },
            langRequest: this.$root.lang === 'ru' ? 'rus' : 'eng',
            polling: null
        }
    },
    created () {
        this.loadStock()
        this.pollData()
    },
    beforeDestroy () {
        clearInterval(this.polling)
    },
    methods: {
        pollData () {
            this.polling = setInterval(this.loadStock, 300000)
        },
        async loadStock () {
            const { lang } = this.$root
            const url = 'https://jsons.injector.3ebra.net/qiwi-ru.json'
            let stocksResponse = await fetch(url).then(r => r.json())
            let stockData = getFormatedStockData(stocksResponse, lang)

            this.ticker.moex = {
                change: stockData.moex?.change,
                percent: stockData.moex?.percent,
                last: Number(stockData.moex?.last).toFixed(0),
                percentIsNegative: stockData.moex?.isNegative,
                date: stockData.moex?.date,
                currency: 'RUB'
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .stock-tabs {
        max-width: 480px;
    }

    .stock-tabs__nav-item {
        background: transparent;
        border: none;
        padding: 5px 32px;
        border-radius: 10px 10px 0px 0px;
        font-size: 14px;

        &--active {
            background-color: #fff;
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
        }
    }

    .stock-data-ticker {
        background-color: #fff;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
        padding: 24px 32px;
        border-radius: 10px;
        line-height: 1;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;

        &__title {
            font-size: 14px;
            line-height: 1.4;
            text-transform: uppercase;
        }

        &__date {
            margin-left: auto;
            font-size: 12px;
            color: #555;
        }

        &__last {
            margin-top: 8px;
            font-size: 42px;
            line-height: 1;
            color: #FF8C00;
            padding-right: 16px;
            font-weight: 300;

            @include breakpoint(mobile) {
                font-size: 38px;
            }

            small {
                font-size: 24px;
                line-height: 1;
                display: inline-block;

                @include breakpoint(mobile) {
                    font-size: 20px;
                }
            }
        }

        &__value {
            display: flex;
            align-items: center;
            margin-top: 8px;
            margin-bottom: 24px;
        }

        &__changes {
            margin-left: auto;
            margin-top: 8px;

             > span {
                display: block;
                font-size: 14px;
                line-height: 1.15;

                + span {
                    margin-top: 4px;
                }
            }
        }
    }
</style>
