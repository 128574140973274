/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'checked': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M20.707 6.293a1 1 0 00-1.414 0L9 16.586l-4.293-4.293a1 1 0 00-1.414 1.414l5 5a1 1 0 001.414 0l11-11a1 1 0 000-1.414z" _fill="#2F363F"/>'
  }
})
