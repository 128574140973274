<template>
    <div class="contacts-list">
        <div class="row">
            <div
                class="col-default-4 col-mobile-12"
                v-for="(item, index) in data"
                :key="index"
            >
                <div class="contacts-list__image" v-if="item.image">
                    <img
                        :src="item.image"
                        :alt="item.name"
                    />
                </div>
                <div class="contacts-list__info">
                    <span
                        class="contacts-list__name"
                        v-if="item.name"
                    >{{ item.name }}</span>
                    <span
                        class="contacts-list__surname"
                        v-if="item.surname"
                    >{{ item.surname }}</span>
                    <span
                        class="contacts-list__post"
                        v-if="item.position"
                    >{{ item.position }}</span>
                    <div
                        class="contacts-list__phone"
                        v-if="item.phone"
                    >
                        <z-link
                            :href="`tel:${item.phone}`"
                            icon="phone"
                        >
                            <span v-html="item.phone"></span>
                        </z-link>
                    </div>
                    <div
                        class="contacts-list__email"
                        v-if="item.email"
                    >
                        <z-link
                            :href="`mailto:${item.email}`"
                            icon="mail"
                        >
                            <span v-html="item.email"></span>
                        </z-link>
                    </div>
                </div>
            </div>
            <div class="col-default-12 u-collapse--top" v-if="additional">
                <z-card
                    decor-right
                    decor-padding="right"
                    decor-size="m"

                >
                    <template v-slot:content>
                        <z-caption size="3xl">Only Investor Relations related enquiries will be considered</z-caption>
                    </template>
                    <template v-slot:bottom>
                        <z-link href="mailto:ir@qiwi.global" icon="mail" target="_blank">ir@qiwi.global</z-link>
                    </template>
                </z-card>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'contacts-list',
    props: {
        data: {
            type: Array,
            default: () => []
        },
        additional: Boolean
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
