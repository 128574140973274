/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'phone': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M6.969 2.586a2 2 0 00-2.829 0l-.09.09a7 7 0 00-.65 9.15l1.622 2.162a24.001 24.001 0 004.8 4.8l2.162 1.622a7 7 0 009.15-.65l.09-.09a2 2 0 000-2.83l-3.063-3.062a2 2 0 00-2.828 0l-1.02 1.02a12.725 12.725 0 01-5.3-5.302l1.019-1.02a2 2 0 000-2.828L6.969 2.586zM5.465 4.09l.09-.09 3.062 3.063L7.39 8.29a1.597 1.597 0 00-.299 1.843 14.726 14.726 0 006.586 6.585 1.597 1.597 0 001.843-.299l1.227-1.227 3.063 3.063-.09.09a5 5 0 01-6.536.465l-2.162-1.622a22.001 22.001 0 01-4.4-4.4L5 10.626a5 5 0 01.465-6.536z" _fill="#FF8C00"/>'
  }
})
