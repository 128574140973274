<template>
    <div class="z-checkbox-wrapper">
        <label
            :for="name"
            class="z-checkbox"
            :class="{
                'is-checked': check,
                'is-disabled': disabled
            }"
        >
            <input
                :id="name"
                :name="name"
                :checked="checked"
                :disabled="disabled"
                @change="change($event.target.checked)"
                type="checkbox"
            />
            <span class="z-checkbox__box"></span>
            <span class="z-checkbox__text">
                <slot />
            </span>
        </label>
        <span
            class="z-checkbox__error"
            v-if="required && error"
        >
            <span v-if="!check">{{ text.error }}</span>
        </span>
    </div>
</template>

<script>
export default {
    name: 'z-checkbox',
    props: {
        name: {
            type: String,
            required: true
        },
        checked: Boolean,
        disabled: Boolean,
        required: Boolean
    },
    data () {
        return {
            error: false,
            focus: false,
            check: this.checked,
            text: {
                error: this.$root.lang === 'ru' ? 'Поле обязательно для заполнения' : 'Required field'
            }
        }
    },
    mounted () {
        this.$root.$bus.$on('clear', name => {
            if (this.name === name) this.change(false)
        })
    },
    beforeDestroy () {
        this.$root.$bus.$off('clear', name => {
            if (this.name === name) this.change(false)
        })
    },
    methods: {
        change (value) {
            this.check = value
            this.$emit('change', { value: value, name: this.name })
        },
        validate () {
            if (this.required && !this.check) {
                this.error = true
            } else {
                this.error = false
            }
        }
    }
}

</script>

<style lang="scss" src="./index.scss"></style>
