/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'osmp': {
    width: 72,
    height: 76,
    viewBox: '0 0 72 76',
    data: '<g clip-path="url(#clip0_5268_46221)" fill-rule="evenodd" clip-rule="evenodd"><path pid="0" d="M36.002 37.509H0v12.562h15.542L2.289 63.907l8.528 8.904 13.252-13.837v16.227H36.1V37.51H36z" _fill="#CF3739"/><path pid="1" d="M36.002 0H72v37.612C72 58.304 55.905 75.143 36.101 75.2v-3.594c17.906-.057 32.457-15.283 32.457-33.993V3.593H36.001C18.081 3.594 3.498 18.8 3.444 37.51H.002C.055 16.817 16.184 0 36.002 0zM59.46 8.994h.24S40.867 28.658 40.728 28.805l3.393 3.543.291.304L63.386 12.84v24.773c0 15.732-12.231 28.534-27.285 28.59V62.61c13.155-.055 23.841-11.246 23.841-24.996V21.49l-1.548 1.617-3.623 3.782v10.724c0 10.772-8.366 19.54-18.67 19.597v-3.594c8.406-.057 15.229-7.212 15.229-16.003v-7.128a18251.912 18251.912 0 01-5.31 5.543c.073.524.113 1.05.113 1.585 0 5.797-4.492 10.52-10.032 10.576v-3.57c3.656-.057 6.614-3.176 6.614-7.007 0-.68-.097-1.336-.268-1.958l-4.602-4.803a6.423 6.423 0 00-1.843-.276c-3.671 0-6.658 3.1-6.712 6.933h-3.42c.053-5.8 4.577-10.502 10.131-10.502.508 0 1.004.044 1.491.12a23582.622 23582.622 0 015.311-5.546h-6.801c-8.42 0-15.274 7.137-15.329 15.927h-3.442c.054-10.759 8.452-19.495 18.77-19.495H46.22c2.781-2.905 5.07-5.293 5.197-5.425H36c-13.17 0-23.888 11.17-23.942 24.92H8.618C8.67 21.776 20.934 8.994 36 8.994H59.46z" _fill="#007BAC"/></g><defs><clipPath id="clip0_5268_46221"><path pid="2" _fill="#fff" d="M0 0h72v75.201H0z"/></clipPath></defs>'
  }
})
