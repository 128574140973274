/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'rocketbank': {
    width: 72,
    height: 76,
    viewBox: '0 0 72 76',
    data: '<g clip-path="url(#clip0_5267_46397)"><path pid="0" d="M35.97 0C16.304 0 .364 16.835.364 37.6c0 20.767 15.94 37.601 35.604 37.601 19.663 0 35.604-16.834 35.604-37.6C71.573 16.835 55.633 0 35.97 0zm16.742 20.202L37.674 58.47c-.181.383-.543.67-.996.67h-.09c-.454-.096-.816-.48-.907-.957-.905-5.358-3.261-10.237-6.975-14.16-3.714-3.921-8.334-6.409-13.407-7.366-.453-.095-.815-.478-.906-.956-.09-.479.182-.957.634-1.148l36.236-15.881c.09-.096.271-.096.362-.096h.09c.544 0 .997.478 1.088 1.148 0 .191 0 .383-.091.478z" _fill="#FF8C23"/></g><defs><clipPath id="clip0_5267_46397"><path pid="1" _fill="#fff" d="M0 0h72v75.201H0z"/></clipPath></defs>'
  }
})
