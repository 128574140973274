/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'nasdaq': {
    width: 72,
    height: 76,
    viewBox: '0 0 72 76',
    data: '<ellipse pid="0" cx="36" cy="37.601" rx="36" ry="37.601" _fill="#F5F5F5"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M50.012 14.64L38.594 47.403a2.285 2.285 0 01-.713 1.02 2.14 2.14 0 01-1.123.47v.016h12.07c.945 0 1.754-.627 2.055-1.505L62.305 14.64H50.012zM35.773 48.412c.7 0 1.313-.363 1.688-.914l.001-.002c.04-.059.176-.258.272-.528l4.188-12.024-2.45-7.022a2.152 2.152 0 00-.67-.896 1.991 1.991 0 00-2.087-.241 2.092 2.092 0 00-.84.721 2.47 2.47 0 00-.273.53l-4.188 12.02 2.461 7.05c.16.388.425.718.762.95.337.232.732.356 1.136.356zM24.465 26.095h12.203v.004c-.434.018-.853.171-1.203.439-.35.267-.617.638-.765 1.064L23.281 60.366H10.988L22.406 27.6c.305-.881 1.114-1.506 2.059-1.506z" _fill="#0996C7"/>'
  }
})
