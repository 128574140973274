/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cookies': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M15.418 3.419a.893.893 0 00-.232-.612.685.685 0 00-.643-.22 7.725 7.725 0 109.236 7.577c0-.313-.3-.486-.555-.428a2.03 2.03 0 01-2.485-1.98c0-.38-.261-.74-.64-.838a2.542 2.542 0 01-1.493-1.086.857.857 0 00-.847-.376 2.03 2.03 0 01-2.341-2.007v-.03zm-5.685 6.745a6.324 6.324 0 014.353-6.009 3.436 3.436 0 003.542 2.724 3.948 3.948 0 001.73 1.274 3.437 3.437 0 002.94 3.006 6.322 6.322 0 01-12.565-.995z" _fill="#FF8C00"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M6.14 8.033a6.25 6.25 0 013.464-.074l.385-1.45a7.75 7.75 0 104.896 11.049l-1.333-.689A6.25 6.25 0 116.14 8.033zm6.367-.023a.633.633 0 100-1.267.633.633 0 000 1.267zm4.688 0a.76.76 0 11-1.52 0 .76.76 0 011.52 0zm-4.941 4.561a.887.887 0 100-1.774.887.887 0 000 1.774zm4.18 3.04a.76.76 0 100-1.52.76.76 0 000 1.52zm-.38-3.8a.634.634 0 100-1.267.634.634 0 000 1.267zm4.688.127a.633.633 0 11-1.267 0 .633.633 0 011.267 0zM7.06 9.404a.633.633 0 11-1.267 0 .633.633 0 011.267 0zm-.127 3.927a.633.633 0 100-1.267.633.633 0 000 1.267zm-2.407-1.266a.76.76 0 11-1.52 0 .76.76 0 011.52 0zm4.941 5.574a.633.633 0 100-1.267.633.633 0 000 1.267zm-2.914-1.267a1.014 1.014 0 11-2.027 0 1.014 1.014 0 012.027 0z" _fill="#FF8C00"/>'
  }
})
