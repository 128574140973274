/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'calendar': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7 3a1 1 0 012 0v1h6V3a1 1 0 112 0v1h1.006A2.995 2.995 0 0121 7.01v9.98A3.004 3.004 0 0118.006 20H5.994A2.995 2.995 0 013 16.99V7.01A3.004 3.004 0 015.994 4H7V3zm0 3H5.994C5.45 6 5 6.45 5 7.01v9.98c0 .566.443 1.01.994 1.01h12.012c.545 0 .994-.45.994-1.01V7.01A.995.995 0 0018.006 6H17v1a1 1 0 11-2 0V6H9v1a1 1 0 01-2 0V6zm2 5a1 1 0 11-2 0 1 1 0 012 0zm3 1a1 1 0 100-2 1 1 0 000 2zm5-1a1 1 0 11-2 0 1 1 0 012 0zm-9 5a1 1 0 100-2 1 1 0 000 2zm5-1a1 1 0 11-2 0 1 1 0 012 0zm3 1a1 1 0 100-2 1 1 0 000 2z" _fill="#2F363F"/>'
  }
})
