<template>
    <div :class="[
        'z-radio',
        { 'is-error': error }
    ]">
        <div class="z-radio__list">
            <div
                class="z-radio__item"
                v-for="(item, index) in data"
                :key="index"
            >
                <label class="z-radio__label">
                    <input
                        class="z-radio__input"
                        type="radio"
                        :name="name"
                        :value="item.value"
                        v-model="radioValue"
                        @change="onChange"
                    >
                    <span class="z-radio__circle"></span>
                    <span class="z-radio__text" v-html="item.text"></span>
                </label>
            </div>
        </div>
        <span
            :class="['z-radio__error', errorClass]"
            v-if="error"
            v-html="error"
        ></span>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'z-radio',
    props: {
        data: {
            type: Array,
            default: () => []
        },
        required: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            required: true
        },
        errorClass: {
            type: String,
            default: ''
        },
        value: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            localValue: this.value,
            error: '',
            text: {
                error: {
                    ru: 'Поле обязательно для заполнения',
                    en: 'Required field'
                }
            }
        }
    },
    computed: {
        radioValue: {
            get () {
                // if (typeof this.value !== 'undefined') return this.value // для работы без v-model
                return this.localValue
            },
            set (value) {
                this.$emit('input', value)
                this.localValue = value
            }
        }
    },
    methods: {
        onChange () {
            this.$nextTick(this.validate)
            this.$emit('change', this.localValue)
        },
        validate () {
            if (this.required && !this.radioValue) {
                this.error = localize(this.text.error)
            } else {
                this.error = ''
            }
        }
    }
}
</script>

<style lang="scss">
$errorColor: $token-colors-red;

.z-radio {
    $parent: &;
    overflow: hidden;
    font-size: 16px;

    &__list {
        margin-top: -1rem;
    }

    &__item {
        display: flex;
        margin-top: 1rem;
    }

    &__label {
        display: inline-flex;
        cursor: pointer;

        &:hover {
            #{$parent}__circle {
                background-color: $token-colors-primary;
                border-color: $token-colors-primary;
            }

            #{$parent}__text {
                color: $token-colors-primary;
            }

            #{$parent}__input {
                &:checked {
                    + #{$parent}__circle {
                        &:after {
                            background-color: $token-colors-white;
                        }
                    }
                }
            }
        }
    }

    &__circle {
        display: block;
        width: 24px;
        height: 24px;
        background-color: $token-colors-white;
        border: 1px solid $token-colors-gray-60;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: 0.75em auto;
        transition: all 0.2s ease-in;
        flex-shrink: 0;
        cursor: pointer;
        border-radius: 50%;
        position: relative;

        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 10px;
            height: 10px;
            opacity: 0;
            background: $token-colors-primary;
            transition: opacity 0.2s ease-in;
            border-radius: 50%;
        }
    }

    &__input {
        display: none;

        &:checked {
            + #{$parent}__circle {
                border-color: $token-colors-primary;

                &:after {
                    opacity: 1;
                }
            }
        }
    }

    &__text {
        font-size: 1em;
        margin-left: 1em;
    }

    &__error {
        display: block;
        margin-top: 10px;
        font-size: 0.8125em;
        color: $errorColor;
        position: relative;
    }

    // error state
    &.is-error {
        #{$parent}__circle {
            border-color: $errorColor;
            background-color: rgba($errorColor, 0.3);
        }

        #{$parent}__text {
            color: $errorColor;
        }
    }
}
</style>
