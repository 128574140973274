/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'qiwi': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M42.458 21.982C42.458 10.945 33.4 2 22.229 2 11.057 2 2 10.946 2 21.982s9.057 19.982 20.229 19.982c1.343 0 2.682-.117 4.146-.245 1.766-.154 3.714-.324 6.073-.324 3.877 0 8.085 1.312 12.74 5.388.466.408 1.087-.1.68-.606-4.575-5.707-8.807-6.794-13.028-7.712-5.162-1.123-7.817-3.993-9.668-7.143-.367-.627-.534-.516-.566.289a17.582 17.582 0 00.252 3.572c-.18.008-.358.008-.539.008h-.09c-7.386 0-13.372-5.914-13.372-13.21 0-7.294 5.986-13.208 13.372-13.208 7.385 0 13.372 5.914 13.372 13.209 0 .518-.025 1.031-.085 1.535-.976-.174-2.898-.193-4.247-.078-.506.043-.434.276-.052.345 4.421.789 7.458 3.495 8.153 8.405.015.113.165.147.224.05a19.706 19.706 0 002.864-10.257zM33.955 34.14c-.446-.373-.963-.578-1.457-.578-.407 0-.746.14-.954.395-.535.65-.295 1.795.524 2.502a2.16 2.16 0 001.377.493c.514 0 .943-.191 1.207-.537.476-.63.202-1.524-.697-2.275zm4.151-1.909c-.079-.523-.373-1.658-1.123-1.938-.389-.145-.695-.03-.842.236-.223.4-.059 1.194.39 1.886.454.7.84 1.054 1.147 1.054.281 0 .561-.362.428-1.238z" _fill="#FF8C00"/>'
  }
})
