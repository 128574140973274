var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"date-block",class:{
        'date-block--size-sm' : _vm.small,
        'date-block--type-vertical' : _vm.isVertical
    }},[(_vm.date)?_c('div',{staticClass:"date-block__item"},[_c('span',{staticClass:"date-block__date"},[_vm._v("\n            "+_vm._s(_vm._f("momentFilter")(_vm.date,'DD'))+"\n        ")]),_vm._v(" "),_c('span',{staticClass:"date-block__wrapper"},[_c('span',{staticClass:"date-block__month"},[_vm._v("\n                "+_vm._s(_vm._f("momentFilter")(_vm.date,'MMMM'))+"\n            ")]),_vm._v(" "),_c('span',{class:[
                'date-block__year',
                { 'date-block__year--hidden' : !_vm.year }
            ]},[_vm._v("\n                "+_vm._s(_vm._f("momentFilter")(_vm.date,'YYYY'))+"\n            ")])])]):_vm._e(),_vm._v(" "),(_vm.dateEnd)?_c('div',{staticClass:"date-block__item"},[_c('span',{staticClass:"date-block__date"},[_vm._v("\n            "+_vm._s(_vm._f("momentFilter")(_vm.dateEnd,'DD'))+"\n        ")]),_vm._v(" "),_c('span',{staticClass:"date-block__wrapper"},[_c('span',{staticClass:"date-block__month"},[_vm._v("\n                "+_vm._s(_vm._f("momentFilter")(_vm.dateEnd,'MMMM'))+"\n            ")]),_vm._v(" "),_c('span',{class:[
                'date-block__year',
                { 'date-block__year--hidden' : !_vm.year }
            ]},[_vm._v("\n                "+_vm._s(_vm._f("momentFilter")(_vm.dateEnd,'YYYY'))+"\n            ")])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }