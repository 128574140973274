<template>
    <div class="z-lead">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'z-lead'
}
</script>

<style lang="scss" src="./index.scss"></style>
