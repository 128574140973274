/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'qiwi_circle': {
    width: 72,
    height: 76,
    viewBox: '0 0 72 76',
    data: '<ellipse pid="0" cx="36" cy="37.601" rx="36" ry="37.601" _fill="#F5F5F5"/><path pid="1" d="M55.082 50.483c.144 1.124-.18 1.612-.538 1.612-.36 0-.862-.45-1.4-1.35-.539-.9-.754-1.912-.467-2.437.18-.338.538-.487 1.04-.3.898.337 1.257 1.8 1.365 2.474zm-5.062 2.474c1.113.975 1.436 2.137.862 2.925-.323.45-.861.675-1.472.675-.61 0-1.256-.226-1.687-.638-1.005-.9-1.292-2.4-.646-3.224.251-.338.682-.525 1.149-.525.61.037 1.256.3 1.794.787zm-1.83 9.373c4.738 0 9.872 1.688 15.543 6.974.575.525 1.328-.112.826-.787-5.6-7.386-10.77-8.774-15.903-9.973-6.317-1.463-9.548-5.174-11.81-9.224-.466-.825-.646-.675-.682.375a24.017 24.017 0 00.323 4.612h-.754c-9.01 0-16.333-7.649-16.333-17.06 0-9.41 7.323-17.06 16.333-17.06 9.01 0 16.334 7.65 16.334 17.06 0 .675-.036 1.35-.108 1.987-1.185-.224-3.554-.262-5.205-.112-.61.037-.539.375-.072.45 5.385 1.012 9.118 4.5 9.944 10.873.035.15.215.188.287.075 2.226-3.862 3.482-8.399 3.482-13.235 0-14.248-11.056-25.796-24.698-25.796C22.057 11.49 11 23.037 11 37.285 11 51.532 22.056 63.08 35.697 63.08c3.626-.037 7.216-.75 12.493-.75z" _fill="#FF8C00"/>'
  }
})
