<template>
    <div class="documents">
        <z-switch
            id="documents-switch"
            :check="currentType === 'archive'"
            @change="onChange"
            v-if="types.length && types.length !== 1"
        >
            <template v-slot:left>
                <span>{{ text.archive }}</span>
            </template>
            <template v-slot:right>
                <span>{{ text.archive }}</span>
            </template>
        </z-switch>
        <z-accordion
            :initial="opened ? 'opened' : 'closed'"
        >
            <z-accordion-item
                v-for="item in selectedData(currentType)"
                :id="item.id"
                :key="item.id"
            >
                <template v-slot:icon>
                    <z-icon name="slider" dir="down" height="10"></z-icon>
                </template>
                <template v-slot:header>{{ item.name }}</template>
                <template v-slot:body>
                    <z-filelist>
                        <z-filelist-item
                            v-for="(el, i) in item.items"
                            :key="`document-${i}`"
                            :date="el.date"
                        >
                            <template v-slot:icon>
                                <z-icon :name="el.type" width="28" height="28"></z-icon>
                            </template>
                            <z-link :href="el.link" theme="black" v-html="el.name"></z-link>
                        </z-filelist-item>
                    </z-filelist>
                </template>
            </z-accordion-item>
        </z-accordion>
    </div>
</template>

<script>
export default {
    name: 'documents',
    props: {
        opened: Boolean,
        source: [Object, Array]
    },
    data () {
        return {
            currentType: 'actual',
            text: {
                archive: this.$root.lang === 'ru' ? 'Архив документов' : 'Documents archive'
            }
        }
    },
    computed: {
        types () {
            return Object.keys(this.data)
        },
        data () {
            return this.source ? this.source : this.$root.app.components.documents
        }
    },
    methods: {
        selectedData (type) {
            return this.data[type]
        },
        onChange (status) {
            this.currentType = status ? 'archive' : 'actual'
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
