/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'money_transfers': {
    width: 72,
    height: 76,
    viewBox: '0 0 72 76',
    data: '<g clip-path="url(#clip0_5267_46249)"><ellipse pid="0" cx="36" cy="37.601" rx="36" ry="37.601" _fill="#F5F5F5"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M34.433 13.385c-.567-2.193-2.73-3.496-4.837-2.913-11.89 3.252-20.195 14.424-20.195 27.172 0 15.498 12.177 28.102 27.144 28.1 14.966 0 27.142-12.604 27.142-28.102a28.902 28.902 0 00-.917-7.27c-.573-2.19-2.738-3.487-4.843-2.901a3.954 3.954 0 00-2.4 1.901 4.245 4.245 0 00-.402 3.113c.433 1.672.65 3.407.65 5.16 0 10.979-8.623 19.91-19.227 19.91-1.005 0-1.992-.082-2.956-.236 4.47-1.06 7.808-5.192 7.808-10.141 0-5.748-4.501-10.41-10.055-10.41-5.552 0-10.055 4.66-10.055 10.41 0 1.088.164 2.169.487 3.203-2.783-3.453-4.462-7.898-4.462-12.74 0-9.031 5.88-16.947 14.304-19.246a3.953 3.953 0 002.406-1.896c.528-.94.675-2.06.408-3.114zm13.192-.958c-3.604-.369-6.812 2.357-7.17 6.087h.002c-.358 3.73 2.273 7.051 5.875 7.422 3.602.369 6.813-2.357 7.169-6.086.357-3.73-2.274-7.052-5.876-7.423z" _fill="#FF8C00"/></g><defs><clipPath id="clip0_5267_46249"><path pid="2" _fill="#fff" d="M0 0h72v75.201H0z"/></clipPath></defs>'
  }
})
