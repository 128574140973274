<template>
    <div class="z-preloader">
        <img class="z-preloader__image" :src="buildSrc" title="preloader">
    </div>
</template>

<script>
export default {
    name: 'z-preloader',
    computed: {
        buildSrc () {
            return require('./images/preloader.svg')
        }
    }
}
</script>

<style lang="scss">
.z-preloader {
    height: 72px;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 16px;

    &__image {
        max-width: 100%;
        max-height: 100%;
        animation: ZPreloader 2s linear infinite;
    }
}

@keyframes ZPreloader {

    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
</style>
