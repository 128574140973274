<template>
    <div
        class="navigation-left"
        v-if="data.items.length"
        role="navigation"
        aria-label="Left navigation"
    >
        <div class="navigation-left__list">
            <div
                class="navigation-left__item"
                v-for="(item, index) in data.items"
                :key="index"
                :class="{'is-active': item.active}"
            >
                <a
                    :href="item.link"
                    @click="clickHandler(item)"
                    :title="item.name"
                    v-html="item.name"
                ></a>
                <template v-if="item.items">
                    <div
                        class="navigation-left__item"
                        v-for="(item, index) in item.items"
                        :key="index"
                        :class="{'is-active': item.active}"
                    >
                        <a
                            :href="item.link"
                            @click="clickHandler(item)"
                            :title="item.name"
                            v-html="item.name"
                        ></a>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'navigation-left',
    data () {
        return {
            data: this.$root.app.components['navigation-left']
        }
    },
    created () {
        this.data.items = this.filterData(this.data.items, (item) => {
            return !(!Array.isArray(item.params) && item.params.hidePage)
        })
    },
    methods: {
        clickHandler (item) {
            if (item.params.hashLink) {
                location.replace(item.link)
                location.reload()
            }
        },
        filterData (data, predicate) {
            return !data ? null : data.reduce((list, entry) => {
                let clone = null
                if (predicate(entry)) {
                    clone = Object.assign({}, entry)
                }
                if (entry.items != null) {
                    let items = this.filterData(entry.items, predicate)
                    if (items.length > 0) {
                        clone = Object.assign({}, entry, {items: items})
                    }
                }

                clone && list.push(clone)
                return list
            }, [])
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
