<template>
    <nav class="z-breadcrumbs">
        <a
            class="z-button z-button--kind-text"
            :href="backLink"
            v-if="detail"
        >
            <div class="z-button__icon z-button__icon--align-left">
                <z-icon name="arrow" dir="top"></z-icon>
            </div>
            {{ backText }}
        </a>
        <ul class="z-breadcrumbs__list" v-else>
            <li
                class="z-breadcrumbs__item"
                v-for="(item, index) in data"
                :class="{'is-current': isCurrent(item.url)}"
                :key="index"
            >
                <template v-if="!isCurrent(item.url)">
                    <a
                        class="z-breadcrumbs__link"
                        :href="item.url"
                        :title="item.name"
                    >
                        <span v-html="item.name"></span>
                    </a>
                </template>
                <template v-else>
                    <span class="z-breadcrumbs__link">
                        <span v-html="item.name"></span>
                    </span>
                </template>
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    name: 'z-breadcrumbs',
    props: {
        detail: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            data: this.$root.app.components.breadcrumbs,
            pathname: window.location.pathname
        }
    },
    computed: {
        backLink () {
            return this.$store.getters.backLink
        },
        backText () {
            return this.$store.getters.backText
        }
    },
    methods: {
        isCurrent (link) {
            return this.pathname === link
        }
    }
}

</script>

<style lang="scss" src="./index.scss"></style>
