/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'external_table': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M20.917 5V4H13.7v2h3.802l-5.752 5.751 1.415 1.415 5.75-5.752v3.801h2V5zm-9.702-.908a8.458 8.458 0 109.61 9.61h-2.029a6.46 6.46 0 11-7.58-7.581V4.09z" _fill="#FF8C00"/>'
  }
})
