/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'taxi_circle': {
    width: 72,
    height: 76,
    viewBox: '0 0 72 76',
    data: '<ellipse pid="0" cx="36" cy="37.601" rx="36" ry="37.601" _fill="#F5F5F5"/><g clip-path="url(#clip0_6436_49023)"><path pid="1" d="M58 43.5A6.25 6.25 0 1058 31a6.25 6.25 0 000 12.5z" _fill="#40256B"/><path pid="2" d="M48.625 43.5c1.726 0 3.125-2.798 3.125-6.25S50.35 31 48.625 31c-1.726 0-3.125 2.798-3.125 6.25s1.4 6.25 3.125 6.25z" _fill="#FFFAD7"/><path pid="3" d="M39.25 43.5a6.25 6.25 0 100-12.5 6.25 6.25 0 000 12.5z" _fill="#A562FF"/><path pid="4" d="M20.5 43.5c6.904 0 12.5-2.798 12.5-6.25S27.404 31 20.5 31 8 33.798 8 37.25s5.596 6.25 12.5 6.25z" _fill="#38CE97"/></g><defs><clipPath id="clip0_6436_49023"><path pid="5" _fill="#fff" transform="translate(8 31)" d="M0 0h56.25v12.5H0z"/></clipPath></defs>'
  }
})
