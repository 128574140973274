/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'back': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10.707 5.707a1 1 0 00-1.414-1.414l-7 7a.997.997 0 000 1.414l7 7a1 1 0 001.414-1.414L5.414 13H20.8c.663 0 1.2-.448 1.2-1s-.537-1-1.2-1H5.414l5.293-5.293z" _fill="#2F363F"/>'
  }
})
