/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sign-in': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M20.546 15.54A9.25 9.25 0 0018.54 5.46a.75.75 0 111.06-1.061 10.75 10.75 0 11-15.202 0 .75.75 0 111.06 1.06 9.25 9.25 0 1015.087 10.08zm-4.014-5.126a.75.75 0 01.936 1.172l-5 4-.468.374-.469-.374-5-4a.75.75 0 11.938-1.172l3.781 3.026V3a.75.75 0 011.5 0v10.44l3.782-3.026z" _fill="#FF8200"/>'
  }
})
