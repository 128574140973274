<template>
    <div :class="[
        'slider',
        { [`slider--theme-${theme}`]: theme }
    ]">
        <div class="swiper-container slider__container" ref="slider">
            <div class="swiper-wrapper">
                <slot></slot>
            </div>
        </div>
        <div class="slider__controls">
            <button class="slider__nav-btn slider__nav-btn--prev" ref="prev">
                <z-icon
                    name="arrow-up"
                    width="32"
                    height="32"
                    dir="left"
                />
            </button>
            <div class="slider__pagination" ref="pagination"></div>
            <button class="slider__nav-btn slider__nav-btn--next" ref="next">
                <z-icon
                    name="arrow-up"
                    width="32"
                    height="32"
                    dir="right"
                />
            </button>
        </div>
    </div>
</template>

<script>
import Swiper from 'swiper/dist/js/swiper.js'

export default {
    name: 'slider',
    props: {
        theme: {
            type: String,
            validator: prop => ['products'].includes(prop)
        },
        options: Object
    },
    data () {
        return {
            slider: undefined
        }
    },
    methods: {
        init () {
            let options = {
                observer: true,
                observeParents: true,
                navigation: {
                    prevEl: this.$refs.prev,
                    nextEl: this.$refs.next
                },
                pagination: {
                    clickable: true,
                    el: this.$refs.pagination
                },
                slidesPerView: 1
            }

            if (this.options) options = { ...options, ...this.options }

            this.slider = new Swiper(this.$refs.slider, options)
        }
    },
    mounted () {
        this.init()
    }
}
</script>

<style lang="scss">
.slider {
    $parent: &;

    position: relative;

    &__controls {
        @include padding-level(top, M);
        display: flex;
        justify-content: center;
    }

    &__nav-btn {
        padding: 0;
        border: none;
        background: none;
        pointer-events: auto;

        .z-icon {
            path {
                transition: fill $transition;
            }
        }

        &:hover {
            .z-icon {
                path {
                    fill: $token-colors-primary;
                }
            }
        }

        &--prev {
            @include margin-level(right, XS);
        }

        &--next {
            @include margin-level(left, XS);
        }
    }

    &__pagination {
        margin-left: -12px;
        margin-right: -12px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        min-height: 16px;

        .swiper-pagination-bullet {
            background: $token-colors-gray-20;
            width: 12px;
            height: 12px;
            opacity: 1;
            margin: 0 12px;
            transition: $transition;

            &-active {
                width: 16px;
                height: 16px;
                background: $token-colors-primary;
            }
        }
    }

    &--theme {
        &-products {
            padding: 0 32px;

            #{$parent}__pagination {
                display: none;
            }

            #{$parent}__controls {
                padding-top: 0;
                margin: 0;
                pointer-events: none;
                width: 100%;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                justify-content: space-between;
                z-index: 5;
            }
        }
    }
}
</style>
