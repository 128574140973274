<template>
    <div
        class="anchor-menu"
        v-if="data.length"
        ref="anchor-menu"
    >
        <div :class="[
            'anchor-menu__container',
            { 'is-fixed': isFixed }
        ]">
            <div :class="{ 'container container--wide': isFixed }">
                <div :class="{ 'row u-collapse--top': isFixed }">
                    <div :class="{ 'col-default-10 col-tablet-12 col-default-offset-2 col-tablet-offset-0 u-collapse--top': isFixed }">
                        <ul class="anchor-menu__list" ref="list">
                            <li
                                v-for="(item, index) in data"
                                :key="item.id"
                                :class="[
                                    'anchor-menu__item',
                                    { 'is-active': item.id === activeItem }
                                ]"
                                @click="onClick(item.id)"
                            >
                                <span>
                                    <template v-if="showCounter">{{ index + 1 }}.&nbsp;</template><span v-html="item.name"></span>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Velocity from 'velocity-animate'

export default {
    name: 'anchor-menu',
    props: {
        data: {
            type: Array,
            default: () => []
        },
        targets: {
            type: String,
            default: 'js-anchor-container'
        },
        showCounter: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            activeItem: null,
            isFixed: false
        }
    },
    methods: {
        onClick (id) {
            const anchorContainer = document.querySelector(`[data-anchor="${id}"]`)
            const anchorMenu = this.$refs['anchor-menu']
            if (!anchorContainer && !anchorMenu) return

            let offset = anchorContainer.offsetTop - anchorMenu.offsetHeight + 60
            Velocity(document.body, 'scroll', {
                offset: offset
            })
        },
        onScroll () {
            this.checkActiveItem()
            this.fixedMenu()
        },
        checkActiveItem () {
            const targets = Array.from(document.querySelectorAll(`.${this.targets}`))

            for (let i = 0; i < targets.length; i++) {
                const elm = targets[i]
                const offset = 160
                const minHeight = 100
                const curAnchor = elm.getAttribute('data-anchor')

                if (elm.getBoundingClientRect().height < minHeight) {
                    if (elm.getBoundingClientRect().top > offset && elm.getBoundingClientRect().top < elm.getBoundingClientRect().height + offset) {
                        this.setActiveItem(curAnchor)
                    }
                } else {
                    if (elm.getBoundingClientRect().top < offset + minHeight / 2 && elm.getBoundingClientRect().bottom > offset) {
                        this.setActiveItem(curAnchor)
                    }
                }
            }
        },
        fixedMenu () {
            const anchorMenu = this.$refs['anchor-menu']
            const offset = window.innerWidth > 1359 ? 80 : 72
            if (!anchorMenu) return

            if (anchorMenu.getBoundingClientRect().top <= offset) {
                this.isFixed = true
                document.querySelector('.aside').style.paddingTop = '80px'
            } else {
                this.isFixed = false
                document.querySelector('.aside').style.paddingTop = '0'
            }
        },
        setActiveItem (item) {
            this.activeItem = item
        }
    },
    mounted () {
        if (this.data && this.data.length) this.activeItem = this.data[0].id
        window.addEventListener('scroll', this.onScroll)
    },
    beforeDestoroy () {
        window.removeEventListener('scroll', this.onScroll)
    }
}
</script>

<style lang="scss">
.anchor-menu {
    $parent: &;

    top: 0;
    background: $token-colors-white;
    z-index: 5;
    padding-top: 16px;

    &__container {
        z-index: 2;
        background: $token-colors-white;
        padding-top: 16px;

        &.is-fixed {
            position: fixed;
            box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
            width: 100%;
            left: 0;
            top: 0;
        }
    }

    &__list {
        display: flex;
        margin: 0;
        overflow: auto;

        @include breakpoint (mobile) {
            padding-bottom: 6px;
        }

        &::-webkit-scrollbar {
            height: 8px;
            border-radius: 16px;
            background: $token-colors-gray-10;
        }

        &::-webkit-scrollbar-thumb {
            background: $token-colors-gray-30;
            border-radius: 16px;
            cursor: pointer;
        }
    }

    &__item {
        @include margin-level(right, M);
        padding-bottom: 12px;
        padding-top: $token-spacers-3-xs;
        @include typo-level(L);
        position: relative;
        cursor: pointer;
        line-height: 1.2;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        @include breakpoint (v-tablet) {
            min-width: 200px;
        }

        @include breakpoint (mobile) {
            padding-left: $token-spacers-3-xs;
            padding-right: $token-spacers-3-xs;
        }

        &:last-child {
            margin-left: 0;
        }

        &:after {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            display: block;
            width: 0;
            height: 4px;
            border-radius: 2px;
            background-color: $token-colors-primary;
            margin: auto;
            content: '';
            transition: width 0.4s;
        }

        &:hover,
        &.is-active {
            color: $token-colors-primary;

            &:after {
                width: 100%;
            }
        }
    }
}
</style>
