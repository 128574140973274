<template>
    <div class="stock-data">
        <z-table
            v-if="ticker.nasdaq && ticker.moex"
            class="u-bottom-margin--xl"
            :columns="['Share', 'Date', 'Last', 'High', 'Low', 'Changes', 'Changes %', 'Bid', 'Ask', 'Volume']"
            financial
        >
            <z-table-row>
                <z-table-col index="0">QIWI (NASDAQ)</z-table-col>
                <z-table-col index="0">{{ticker.nasdaq.Date}}</z-table-col>
                <z-table-col index="1" is-highlighted>
                    {{ticker.nasdaq.last}} <small>{{ticker.nasdaq.Currency}}</small>
                </z-table-col>
                <z-table-col index="2">{{parseFloat(ticker.nasdaq.High).toFixed(2)}}</z-table-col>
                <z-table-col index="3">{{parseFloat(ticker.nasdaq.Low).toFixed(2)}}</z-table-col>
                <z-table-col index="4">
                    <span
                        :style="{
                            'color': ticker.nasdaq.percentIsNegative ? '#D0021B' : '#4BBD5C'
                        }"
                    >{{parseFloat(ticker.nasdaq.Change).toFixed(2)}}</span>
                </z-table-col>
                <z-table-col index="4">
                    <span
                        :style="{
                            'color': ticker.nasdaq.percentIsNegative ? '#D0021B' : '#4BBD5C'
                        }"
                    >{{ticker.nasdaq.percent}}</span>
                </z-table-col>
                <z-table-col index="5">{{parseFloat(ticker.nasdaq.Bid).toFixed(2)}}</z-table-col>
                <z-table-col index="5">{{parseFloat(ticker.nasdaq.Ask).toFixed(2)}}</z-table-col>
                <z-table-col index="5">{{ticker.nasdaq.Volume | numberFilter}}</z-table-col>
            </z-table-row>
            <z-table-row>
                <z-table-col index="0">QIWI (MOEX)</z-table-col>
                <z-table-col index="0">{{ticker.moex.Date}}</z-table-col>
                <z-table-col index="1" is-highlighted>{{ticker.moex.last}} <small>{{ticker.moex.Currency}}</small></z-table-col>
                <z-table-col index="2">{{parseFloat(ticker.moex.High).toFixed(2)}}</z-table-col>
                <z-table-col index="3">{{parseFloat(ticker.moex.Low).toFixed(2)}}</z-table-col>
                <z-table-col index="4">
                    <span
                        :style="{
                            'color': ticker.moex.percentIsNegative ? '#D0021B' : '#4BBD5C'
                        }"
                    >{{parseFloat(ticker.moex.Change).toFixed(2)}}</span>
                </z-table-col>
                <z-table-col index="4">
                    <span
                        :style="{
                            'color': ticker.moex.percentIsNegative ? '#D0021B' : '#4BBD5C'
                        }"
                    >{{ticker.moex.percent}}</span>
                </z-table-col>
                <z-table-col index="5">{{parseFloat(ticker.moex.Bid).toFixed(2)}}</z-table-col>
                <z-table-col index="5">{{parseFloat(ticker.moex.Ask).toFixed(2)}}</z-table-col>
                <z-table-col index="5">{{ticker.moex.Volume | numberFilter}}</z-table-col>
            </z-table-row>
        </z-table>

        <div class="row">
            <div class="col-default-6 col-mobile-12">
                <z-caption tag="h2">QIWI (NASDAQ)</z-caption>
                <z-chart
                    v-if="optionsHistoricalNasdaq"
                    :options="optionsHistoricalNasdaq"
                ></z-chart>
            </div>
            <div class="col-default-6 col-mobile-12">
                <z-caption tag="h2">QIWI (MOEX)</z-caption>
                <z-chart
                    v-if="optionsHistoricalMoex"
                    :options="optionsHistoricalMoex"
                ></z-chart>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
// import moment from 'moment'
import { localize } from '@/utils/i18n'
import StockTabs from '@/views/StockTabs'

export default {
    name: 'stock-data',
    components: {
        StockTabs
    },
    data () {
        return {
            optionsHistoricalNasdaq: null,
            optionsHistoricalMoex: null,
            ticker: {
                nasdaq: null,
                moex: null
            },
            prefixUrl: this.$root.lang === 'en' ? '' : '/ru',
            text: {
                more: localize({
                    'ru': 'Подробнее',
                    'en': 'More Info'
                })
            }
        }
    },
    created () {
        axios.get('https://tools.eurolandir.com/tools/pricefeed/?companycode=cy-qiwi&format=json')
            .then((res) => {
                this.ticker.nasdaq = {
                    last: `${parseFloat(res.data['QIWI (NASD)'].Last).toFixed(2)}`,
                    percent: `${parseFloat(res.data['QIWI (NASD)'].ChangePercent).toFixed(2)}%`,
                    percentIsNegative: res.data['QIWI (NASD)'].ChangePercent[0] === '-',
                    ...res.data['QIWI (NASD)']
                }
                this.ticker.moex = {
                    last: `${parseFloat(res.data['QIWI (MOEX)'].Last).toFixed(2)}`,
                    percent: `${parseFloat(res.data['QIWI (MOEX)'].ChangePercent).toFixed(2)}%`,
                    percentIsNegative: res.data['QIWI (MOEX)'].ChangePercent[0] === '-',
                    ...res.data['QIWI (MOEX)']
                }
            })
            .catch((err) => {
                console.log(err)
            })

        axios.get('https://tools.eurolandir.com/tools/pricefeed/history.aspx?companycode=cy-qiwi&format=json&period=365')
            .then((res) => {
                let xColumnsNasdaq = ['x']
                let dataColumnsNasdaq = ['QIWI']
                let xColumnsMoex = ['x']
                let dataColumnsMoex = ['QIWI']

                res.data[Object.keys(res.data)[0]].Data.forEach(obj => {
                    dataColumnsNasdaq.push(parseFloat(obj.Open))
                    xColumnsNasdaq.push(obj.Date)
                })

                res.data[Object.keys(res.data)[1]].Data.forEach(obj => {
                    dataColumnsMoex.push(parseFloat(obj.Open))
                    xColumnsMoex.push(obj.Date)
                })

                this.optionsHistoricalNasdaq = {
                    size: {
                        // height: 180
                    },
                    data: {
                        x: 'x',
                        columns: [
                            xColumnsNasdaq,
                            dataColumnsNasdaq
                        ],
                        type: 'area'
                    },
                    zoom: {
                        enabled: true // for ESM specify as: zoom()
                    },
                    axis: {
                        y: {
                            label: {
                                text: 'USD',
                                position: 'outer-middle'
                            },
                            tick: {
                                count: 10,
                                format: function (x) {
                                    return parseFloat(x).toFixed(0)
                                }
                            }
                            // min: 3000,
                            // max: 4000
                        },
                        x: {
                            type: 'timeseries',
                            tick: {
                                count: 6,
                                format: '%Y-%m-%d'
                            }
                        }
                    },
                    legend: {
                        // show: false
                    },
                    area: {
                        linearGradient: {
                            x: [0, 0],
                            y: [0, 1],
                            stops: [
                                [0, '#ff8200', 1],
                                [1, '#fff', 0.5]
                            ]
                        }
                    }
                }

                this.optionsHistoricalMoex = {
                    size: {
                        // height: 180
                    },
                    data: {
                        x: 'x',
                        columns: [
                            xColumnsMoex,
                            dataColumnsMoex
                        ],
                        type: 'area'
                    },
                    zoom: {
                        enabled: true
                    },
                    axis: {
                        y: {
                            label: {
                                text: 'RUB',
                                position: 'outer-middle'
                            },
                            tick: {
                                count: 10,
                                format: function (x) {
                                    return parseFloat(x).toFixed(0)
                                }
                            }
                            // min: 3000,
                            // max: 4000
                        },
                        x: {
                            type: 'timeseries',
                            tick: {
                                count: 6,
                                format: '%Y-%m-%d'
                            }
                        }
                    },
                    legend: {
                        // show: false
                    },
                    area: {
                        linearGradient: {
                            x: [0, 0],
                            y: [0, 1],
                            stops: [
                                [0, '#ff8200', 1],
                                [1, '#fff', 0.5]
                            ]
                        }
                    }
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }
}
</script>

<style lang="scss">
    .stock-data {
        .z-table td {
            font-size: 16px;
        }

        .z-chart {
            margin-left: -14px;
        }

        circle.bb-shape {
            r: 1;
        }
    }
</style>
