<template>
    <div class="remind">
        <tippy-tooltip
            @show="onShow"
            trigger="click"
            :placement="popupPlacement"
            :interactive="true"
            arrow
            theme="light"
            :distance="popupDistance"
        >
            <template v-slot:trigger>
                <slot></slot>
            </template>
            <z-link
                v-for="(item, index) in data"
                :href="item.link"
                :key="index"
                :target="item.type === 'google' ? '_blank' : false"
                :download="item.name || item.type"
                theme="black"
            >
                <z-icon
                    :name="item.type"
                    width="32"
                    height="32"
                />
                {{ item.type }}
            </z-link>
        </tippy-tooltip>
    </div>
</template>

<script>
import { mixinDevice } from '@/utils/mixin'

export default {
    name: 'remind',
    mixins: [mixinDevice],
    props: {
        data: [Array, Object],
        placement: {
            type: String,
            default: 'right'
        },
        distance: {
            type: String,
            default: '30'
        }
    },
    methods: {
        onShow (tippy) {
            tippy.popper.classList.add('remind-popup')
        }
    },
    computed: {
        popupPlacement () {
            if (this.device === 'mobile') return 'bottom'
            return this.placement
        },
        popupDistance () {
            if (this.device === 'mobile') return '20'
            return this.distance
        }
    }
}
</script>

<style lang="scss">
.remind {
    position: relative;
}

.remind-popup {
    font-size: 16px;

    @include breakpoint (mobile) {
        min-width: 180px;
    }

    .tippy-tooltip {
        padding: 0 !important;
    }

    .tippy-content {
        @include padding-level(top, 3XS);
        @include padding-level(bottom, 3XS);
        border-radius: 10px;
    }

    .tippy-arrow {
        background-image: url("data:image/svg+xml,%3Csvg width='20' height='44' viewBox='0 0 20 44' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.0011 42L20 42L20 2L16.0011 2L16.0011 4.22468C16.0011 5.37311 15.6057 6.48653 14.8815 7.3778L5.56226 18.8467C4.06946 20.6839 4.06953 23.3161 5.56244 25.1532L14.8813 36.6203C15.6057 37.5116 16.0011 38.6251 16.0011 39.7736L16.0011 42Z' fill='white'/%3E%3C/svg%3E%0A");
        border: none !important;
        height: 48px;
        width: 18px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 0 0;
    }

    &[x-placement^=right] .tippy-arrow {
        left: auto;
        right: calc(100% - 4px);
    }

    &[x-placement^=bottom] .tippy-arrow {
        transform: rotate(90deg);
        transform-origin: 50% 50%;
        top: -29px;
    }

    .z-icon {
        margin-right: .25rem;
    }

    .z-link {
        display: block;
        font-weight: 400;
        text-transform: capitalize;
        @include padding-level(left, 2XS);
        @include padding-level(right, 2XS);
        @include padding-level(top, 3XS);
        @include padding-level(bottom, 3XS);
        transition: $transition;

        &:hover {
            color: $token-colors-tertiary;
            background: $token-colors-gray-10;
        }
    }
}

</style>
