<template>
    <div class="cmd">
        <z-tabs
            v-if="data.length"
            class="u-bottom-margin--quad"
        >
            <template v-slot:label>
                <z-tabs-label
                    :key="index"
                    :id="`cmd-${year.year}`"
                    v-for="(year, index) in data"
                >{{ year.year }}</z-tabs-label>
            </template>
            <template v-slot:content>
                <z-tabs-content
                    :key="index"
                    :id="`cmd-${year.year}`"
                    v-for="(year, index) in data"
                >
                    <cmd-card class="u-bottom-margin--2xl" :data="year" />

                    <div
                        class="u-bottom-margin--2xl"
                        v-if="year['preview-text']"
                    ><vue-raw :raw="year['preview-text']" /></div>

                    <div
                        v-if="year.type === 'upcoming' && year.timelist"
                        class="u-bottom-margin--2xl"
                    >
                        <z-caption class="u-bottom-margin--m-important" size="5xl" tag="h2">
                            <span v-html="text.schedule"></span>
                        </z-caption>
                        <timelist :data="year.timelist"/>
                    </div>

                    <div
                        v-if="year.type === 'upcoming' && year.speakers"
                        class="u-bottom-margin--2xl"
                    >
                        <z-caption class="u-bottom-margin--m-important" size="5xl" tag="h2">
                            <span v-html="text.speakers"></span>
                        </z-caption>
                        <div class="cmd-speakers">
                            <div class="row">
                                <div
                                    class="col-default-4 col-v-tablet-6 col-mobile-12"
                                    v-for="(item, index) in year.speakers"
                                    :key="index"
                                >
                                    <bio-item :data="item" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="u-bottom-margin--2xl" v-if="year.materials && year.materials.length">
                        <z-caption class="u-bottom-margin--m-important" size="5xl" tag="h2">
                            <span v-html="text.media"></span>
                        </z-caption>

                        <z-filelist>
                            <z-filelist-item
                                icon="file"
                                :info="item.type"
                                v-for="(item, index) in year.materials"
                                :key="index"
                            >
                                <z-link :href="item.link" target="_blank">
                                    <span v-html="item.name"></span>
                                </z-link>
                            </z-filelist-item>
                        </z-filelist>
                    </div>
                </z-tabs-content>
            </template>
        </z-tabs>
    </div>
</template>

<script>
import { CmdCard, Timelist } from './components'

export default {
    name: 'cmd',
    components: {
        CmdCard,
        Timelist
    },
    data () {
        return {
            data: this.$root.app.components['cmd-calendar'],
            text: {
                main: {
                    format: this.$root.lang === 'ru' ? 'Формат:' : 'Format:',
                    past: this.$root.lang === 'ru' ? 'Проходил:' : 'Date:'
                },
                schedule: this.$root.lang === 'ru' ? 'Расписание' : 'Agenda',
                speakers: this.$root.lang === 'ru' ? 'Спикеры' : 'Speakers',
                media: this.$root.lang === 'ru' ? 'Материалы' : 'Materials'
            }
        }
    }
}
</script>

<style lang="scss"></style>
