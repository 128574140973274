<template>
    <div class="general-meetings" v-if="data.length">
        <div class="general-meetings__filter">
            <div class="general-meetings-filter">
                <div class="general-meetings-filter__inner">
                <div class="general-meetings-filter__list">
                    <div class="general-meetings-filter__item">
                        <z-checkbox name="annual" @change="onChange" checked>Annual</z-checkbox>
                    </div>
                    <div class="general-meetings-filter__item">
                        <z-checkbox name="extraordinary" @change="onChange" checked>Extraordinary</z-checkbox>
                    </div>
                </div>
                </div>
            </div>
        </div>

        <z-tabs>
            <template v-slot:label>
                <z-tabs-label
                    v-for="item in yearSorted"
                    :key="item"
                    :id="item"
                >
                    {{item}}
                </z-tabs-label>
            </template>
            <template v-slot:content>
                <z-tabs-content
                    v-for="(tabItem, tabIndex) in data"
                    :key="tabIndex"
                    :id="`${tabItem.year}`"
                >
                    <div
                        class="general-meetings__item"
                        :key="index"
                        v-for="(item, index) in getFilteredData(tabItem.items)"
                    >
                        <z-caption size="4xl" tag="h3" class="u-bottom-margin--2xs-important">
                            <span v-html="item.name"></span>
                        </z-caption>
                        <p class="u-collapse--top u-top-margin--s-important gray-60-text" v-if="item.date" v-html="item.date"></p>
                        <z-filelist v-if="item.results">
                            <z-filelist-item
                                icon="file"
                                :info="file.extension"
                                v-for="(file, fileIndex) in item.results"
                                :key="fileIndex"
                            >
                                <z-link :href="file.link" theme="black" target="_blank">
                                    <span v-html="file.title"></span>
                                </z-link>
                            </z-filelist-item>
                        </z-filelist>
                        <z-caption tag="p" size="3xl" class="u-top-margin--s u-bottom-margin--2xs-important">Meeting materials</z-caption>
                        <z-filelist v-if="item.materials">
                            <z-filelist-item
                                icon="file"
                                :info="file.extension"
                                v-for="(file, fileIndex) in item.materials"
                                :key="fileIndex"
                            >
                                <z-link :href="file.link" theme="black" target="_blank">
                                    <span v-html="file.title"></span>
                                </z-link>
                            </z-filelist-item>
                        </z-filelist>
                    </div>
                    <z-not-found
                        v-if="getFilteredData(tabItem.items).length < 1"
                        :text="text.notFoundText"
                        :title="text.notFoundTitle"
                    />
                </z-tabs-content>
            </template>
        </z-tabs>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'general-meetings',
    data () {
        return {
            types: {
                annual: true,
                extraordinary: true
            },
            data: this.$root.app.components['general-meetings'] || [],
            text: {
                notFoundTitle: localize({
                    ru: 'Ничего не найдено',
                    en: 'No results found'
                }),
                notFoundTinotFoundTexttle: localize({
                    ru: 'По&nbsp;Вашему запросу ничего не&nbsp;нашлось.',
                    en: 'Sorry, no matches were found for your request. Try changing your search terms.'
                })
            }
        }
    },
    created () {
        if (this.data) {
            this.data.sort((a, b) => {
                return b.year - a.year
            })
        }
    },
    computed: {
        yearSorted () {
            return this.data.map(obj => obj.year).sort((a, b) => b - a)
        }
    },
    methods: {
        onChange (data) {
            this.types[data.name] = data.value
        },
        getFilteredData (data) {
            return data.filter(item => {
                let type = ''
                if (item.type && item.type === 'annual-type') {
                    type = 'annual'
                } else if (item.type && item.type === 'extraordinary-type') {
                    type = 'extraordinary'
                }
                return this.types[type]
            })
        }
    }
}
</script>

<style lang="scss">
.general-meetings {
    &__item {
        & + & {
            @include margin-level(top, L);
            @include padding-level(top, L);
            border-top: 1px solid #CCCCCC;
        }
    }

    &__filter {
        @include margin-level(bottom, S);
    }
}

.general-meetings-filter {
    &__list {
        display: flex;
        flex-wrap: wrap;
        @include margin-level(left, M, true);
        @include margin-level(right, M, true);
        @include margin-level(top, 2XS, true);
        @include margin-level(bottom, 2XS, true);
    }
    &__item {
        @include padding-level(left, M);
        @include padding-level(right, M);
        @include padding-level(top, 2XS);
        @include padding-level(bottom, 2XS);

    }
}
</style>
