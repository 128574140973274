/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'favorite': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12 16.265c.176 0 .352.042.513.125l3.27 1.695c.11.056.2.007.232-.017a.21.21 0 00.088-.212l-.625-3.59a1.076 1.076 0 01.318-.96l2.645-2.542a.21.21 0 00.056-.223.213.213 0 00-.178-.148l-3.656-.523a1.1 1.1 0 01-.83-.594L12.198 6.01A.215.215 0 0012 5.89a.215.215 0 00-.198.12l-1.635 3.265c-.16.321-.47.543-.83.595l-3.656.523a.214.214 0 00-.178.148.21.21 0 00.056.223l2.646 2.542c.26.249.378.608.317.96l-.625 3.59a.21.21 0 00.088.212.218.218 0 00.232.017l3.27-1.695c.16-.083.337-.125.513-.125zm4.734 4.235c-.214 0-.428-.05-.626-.153l-3.983-2.064a.272.272 0 00-.25 0l-3.983 2.064a1.35 1.35 0 01-1.413-.101 1.306 1.306 0 01-.534-1.294l.76-4.37a.262.262 0 00-.077-.235l-3.222-3.095a1.301 1.301 0 01-.34-1.356 1.328 1.328 0 011.084-.9l4.453-.637a.268.268 0 00.202-.145l1.992-3.977A1.336 1.336 0 0112 3.5c.515 0 .976.283 1.204.737l1.99 3.977c.04.078.116.132.203.144l4.453.638c.51.073.924.418 1.083.9a1.301 1.301 0 01-.34 1.356l-3.221 3.095a.262.262 0 00-.078.235l.761 4.37c.087.5-.118.996-.534 1.294a1.35 1.35 0 01-.787.254z" _fill="#FF8C00"/>'
  }
})
