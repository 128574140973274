/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'member': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<g clip-path="url(#clip0_5242_46230)"><circle pid="0" cx="14.735" cy="15.272" r="14.542" _fill="#FF8C00"/><path pid="1" d="M7.467 22.059h2.526l.568-7.773c.078-.92.02-2.173.02-2.173h.039s.45 1.37.763 2.173l2.252 5.384h2.193l2.251-5.384c.313-.803.764-2.154.764-2.154h.039s-.059 1.234.02 2.154l.567 7.773h2.526L20.859 8.158h-2.682l-2.741 6.872a40.24 40.24 0 00-.685 2.036h-.04s-.371-1.214-.685-2.036l-2.74-6.872H8.602l-1.136 13.9z" _fill="#fff"/></g><defs><clipPath id="clip0_5242_46230"><path pid="2" _fill="#fff" transform="scale(1.02439)" d="M0 0h29.286v29.286H0z"/></clipPath></defs>'
  }
})
