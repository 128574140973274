<template>
    <div class="search">
        <!-- begin: search-input -->
        <div class="search__input">
            <z-input
                name="q"
                theme="search"
                :value="params.q"
                @change="debouncedChange"
                :placeholder="text.placeholder"
            ></z-input>
            <span class="search__input--prompt" v-html="text.prompt"></span>
        </div>
        <!-- end: search-input -->

        <z-preloader v-if="loading && !items.length"></z-preloader>

        <!-- begin: search-not-found -->
        <div class="search__not-found u-align-center" v-if="notFound.state && !loading">
            <z-not-found
                :title="notFound.title"
                :text="notFound.text"
            ></z-not-found>

            <z-button
                v-if="isResetButton"
                tag="a"
                kind="secondary"
                class="u-top-margin--m"
                @click="debouncedChange(null)"
            >
                {{ text.resetAll }}
                <template v-slot:right>
                    <z-icon name="close" dir="right" width="16" height="16"></z-icon>
                </template>
            </z-button>
        </div>
        <!-- end: search-not-found -->

        <div class="search__container" v-if="!notFound.state">
            <div class="row between-default">
                <!-- begin: search-content -->
                <div class="col-default-8 col-v-tablet-12">
                    <div class="search__content">
                        <div class="search__content--count" v-if="items.length">
                            <span v-html="text.founded"></span>: <span v-html="allResultsLength"></span>
                        </div>
                        <div
                            v-for="(item, index) of items"
                            :key="index"
                            class="search__content--item"
                        >
                            <div class="group" v-html="item.category"></div>
                            <a :href="item.link" class="name" v-html="item.name"></a>
                            <div class="preview" v-html="item.detail"></div>
                            <!-- <div v-if="item.date" class="date" v-html="buildDate(item.date)"></div> -->
                        </div>

                        <z-preloader v-if="loading && items.length"></z-preloader>
                    </div>
                </div>
                <!-- end: search-content -->

                <!-- begin: search-filters -->
                <div class="col-default-3 col-v-tablet-12">
                    <div class="search__filters" v-if="items.length">
                        <div class="search__filters__button">
                            <z-button @click.native="toggleFilters"  size="m">
                                {{text.filters}}
                                <template v-slot:right>
                                    <z-icon
                                        width="16"
                                        height="16"
                                        name="search-filters"
                                    >
                                    </z-icon>
                                </template>
                            </z-button>
                        </div>
                        <template v-if="isFiltersVisible || !isMobile()">
                            <div class="search__filters--block">
                                <div class="title" v-html="text.sort"></div>
                                <div class="item-groups item-groups--type-sort">
                                    <div class="item-groups__content" v-for="sortObj of listSortFilters" :key="sortObj.id">
                                        <z-button
                                            tag="a"
                                            kind="text"
                                            size="m"
                                            @click="onChangeSort(sortObj.id)"
                                            :class="{ 'is-active': sortObj.selected }"

                                        >
                                            {{ sortObj.name }}
                                        </z-button>
                                    </div>
                                </div>
                            </div>
                            <div class="search__filters--block" v-if="list.tags.length > 1">
                                <div class="title" v-html="text.tags"></div>
                                <div class="item-tags">
                                    <div class="item-tags__content" v-for="tag of list.tags" :key="tag.id">
                                        <z-button
                                            size="s"
                                            kind="text"
                                            @click="debouncedChange({
                                                name: 'tags',
                                                value: tag.id
                                            })"
                                            :class="{ 'is-active': tag.selected }"

                                        >
                                            {{ tag.name }}
                                            <z-icon
                                                name="close"
                                                width="16"
                                                height="16"
                                                v-if="tag.selected && tag.id !== 'all'"
                                            ></z-icon>
                                        </z-button>
                                    </div>
                                </div>
                            </div>
                            <div class="search__filters--block">
                                <div class="title" v-html="text.groups"></div>
                                <div class="item-groups">
                                    <div class="item-groups__content" v-for="category of list.category" :key="category.id">
                                        <z-button
                                            tag="a"
                                            kind="text"
                                            size="m"
                                            @click="debouncedChange({
                                                name: 'category',
                                                value: category.id
                                            })"
                                            :class="{ 'is-active': category.selected }"

                                        >
                                            {{ category.name }}
                                            <span class="item-groups__content--count" v-html="category.count"></span>
                                        </z-button>
                                    </div>
                                </div>
                            </div>
                            <div class="search__filters--reset" v-if="isResetButton">
                                <z-button
                                    tag="a"
                                    kind="secondary"
                                    @click="debouncedChange(null)"
                                    size="m"
                                >
                                    {{ text.resetAll }}
                                </z-button>
                            </div>
                        </template>
                    </div>
                </div>
                <!-- end: search-filters -->
            </div>
        </div>

        <!-- begin: temporary-margin -->
        <div style="margin-bottom: 387px;" v-if="!list.items.length"></div>
        <!-- end: temporary-margin -->
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'
import moment from 'moment'
import { debounce } from 'throttle-debounce'
import { queryString } from '@/utils/queryString'
import { getSearch } from '@/api/search'
import { mixinDevice } from '@/utils/mixin'

export default {
    name: 'search',
    props: {
        url: {
            type: String,
            default: '/search'
        }
    },
    mixins: [mixinDevice],
    data () {
        return {
            isFiltersVisible: false,
            loading: false,
            listSortFilters: [
                {
                    name: localize({
                        'ru': 'По релевантности',
                        'en': 'By relevance'
                    }),
                    id: 'relevance',
                    selected: true
                },
                {
                    name: localize({
                        'ru': 'По дате',
                        'en': 'By date'
                    }),
                    id: 'date',
                    selected: false
                }
            ],
            list: {
                tags: [],
                category: [],
                items: []
            },
            items: [],
            page: {
                count: 0,
                current: 0,
                total: 0
            },
            params: {
                q: this.$root.app.system.params.get.hasOwnProperty('q') ? this.$root.app.system.params.get.q : '',
                tags: this.$root.app.system.params.get.hasOwnProperty('tags') ? this.$root.app.system.params.get.tags : ['all'],
                category: this.$root.app.system.params.get.hasOwnProperty('category') ? this.$root.app.system.params.get.category : 'all',
                sort: this.$root.app.system.params.get.hasOwnProperty('sort') ? this.$root.app.system.params.get.sort : 'relevance',
                page: 1
            },
            text: {
                allResults: localize({
                    'ru': 'Все результаты',
                    'en': 'All results'
                }),
                founded: localize({
                    'ru': 'Результатов',
                    'en': 'Results'
                }),
                placeholder: localize({
                    'ru': 'Введите ключевое слово',
                    'en': 'Enter keyword'
                }),
                prompt: localize({
                    'ru': 'Введите Ваш запрос, например, &laquo;грузоперевозки&raquo;',
                    'en': 'Enter your request, for example, "payment services"'
                }),
                loadMore: localize({
                    'ru': 'Загрузить еще',
                    'en': 'Load more'
                }),
                resetAll: localize({
                    'ru': 'Сбросить категории',
                    'en': 'Reset categories'
                }),
                tags: localize({
                    'ru': 'Теги',
                    'en': 'Tags'
                }),
                sort: localize({
                    'ru': 'Сортировка',
                    'en': 'Sort'
                }),
                groups: localize({
                    'ru': 'Категории',
                    'en': 'Categories'
                }),
                filters: localize({
                    'ru': 'Фильтры',
                    'en': 'Filters'
                })
            },
            notFound: {
                state: false,
                title: localize({
                    'ru': 'Ничего не&nbsp;найдено',
                    'en': 'No&nbsp;results found'
                }),
                text: localize({
                    'ru': 'По&nbsp;Вашему запросу ничего не&nbsp;нашлось.',
                    'en': 'Sorry, no&nbsp;matches were found for your request. Try changing your search terms.'
                })
            }
        }
    },
    created () {
        this.debouncedChange = debounce(500, (data) => {
            if (data === null) {
                this.params = {
                    q: this.params.q,
                    tags: ['all'],
                    category: 'all',
                    sort: 'relevance'
                }
            } else {
                if (data.name === 'tags') {
                    const tagAlreadyActive = this.params[data.name].filter(el => el === data.value)

                    if (tagAlreadyActive.length) {
                        this.params[data.name] = this.params[data.name].filter(el => el !== data.value)
                    } else {
                        if (data.value === 'all') {
                            this.params[data.name] = [data.value]
                        } else {
                            this.params[data.name].push(data.value)
                            this.params[data.name] = this.params[data.name].filter(el => el !== 'all')
                        }
                    }
                } else if (data.name === 'category' || data.name === 'q' || data.name === 'sort') {
                    this.params[data.name] = data.value
                }
            }

            this.params.page = 1

            this.send()
        })

        if (this.params.q !== '') {
            this.send()
        }

        this.listenScroll()
    },
    methods: {
        onChangeSort (newSort) {
            this.listSortFilters.forEach(obj => {
                if (obj.id === newSort) {
                    obj.selected = true
                } else {
                    obj.selected = false
                }
            })
            this.debouncedChange({
                name: 'sort',
                value: newSort
            })
        },
        toggleFilters () {
            if (this.isFiltersVisible) {
                this.isFiltersVisible = false
            } else {
                this.isFiltersVisible = true
            }
        },
        send () {
            this.loading = true

            if (this.params.page === 1) this.items = []

            queryString(this.clearEmptyParams)

            if (this.params.q.length >= 3) {
                getSearch(this.params, this.url).then(response => {
                    this.list = response

                    if (response.hasOwnProperty('nav')) {
                        this.page = response.nav
                    }

                    if (response.hasOwnProperty('items')) {
                        if (response.items.length === 0) {
                            this.notFound.state = true
                        } else {
                            this.notFound.state = false
                        }

                        if (this.page.current <= this.page.total) {
                            this.items = this.items.concat(response.items)
                        } else {
                            this.items = response.items
                        }
                    }

                    if (this.params.q === '') {
                        this.notFound.state = false
                    }

                    this.loading = false
                })
            } else {
                this.items = []
                this.list = {
                    tags: [],
                    category: [],
                    items: []
                }
                this.notFound.state = false
                this.loading = false
            }
        },
        buildDate (date) {
            moment.locale(`${this.$root.lang}`)
            const momentDate = moment.unix(date)

            return momentDate.format('DD MMMM YYYY')
        },
        listenScroll () {
            window.addEventListener('scroll', debounce(500, () => {
                const offsetTop = this.$el.offsetTop
                const offsetHeight = this.$el.offsetHeight
                const bottom = offsetTop + offsetHeight
                const scroll = window.pageYOffset || document.documentElement.scrollTop
                const scrolled = scroll + document.documentElement.clientHeight

                if (scrolled >= (bottom - 100) && !this.loading && this.page.current < this.page.total) {
                    this.params.page++
                    this.send()
                };
            }))
        }
    },
    computed: {
        clearEmptyParams () {
            let params = Object.assign({}, this.params)

            Object.keys(params).forEach(function (key, index) {
                if (Array.isArray(params[key])) {
                    if (!params[key].length) delete params[key]
                } else if (!params[key]) {
                    delete params[key]
                }
            })

            return params
        },
        allResultsLength () {
            const categoryAll = this.list.category.filter(item => item.id === 'all')
            return categoryAll.length ? categoryAll[0].count : 0
        },
        isResetButton () {
            if (!this.params.tags.length && this.params.category === 'all') {
                return false
            }
            return !(this.params.category === 'all' && (this.params.tags.length === 1 && this.params.tags.indexOf('all') === 0))
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
