<template>
    <nav
        class="navigation-mobile"
        aria-label="Main navigation"
        :class="{'navigation-mobile--index': isIndex}"
    >
        <a
            class="navigation-mobile__burger"
            href="javascript:void(0);"
            :class="{'is-active': getBurgerMenu}"
            @click="toggleDropdown(getBurgerMenu)"
            title=""
        >
            <z-icon
                class="navigation-mobile__burger--open"
                name="burger"
                width="22"
                height="22"
            />
            <z-icon
                class="navigation-mobile__burger--close"
                name="cross"
                width="22"
                height="22"
            />
        </a>
        <div
            class="navigation-mobile__dropdown"
            v-if="getBurgerMenu"
        >
            <div
                class="navigation-mobile__mainmenu"
                v-if="!subMenu.visible"
            >
                <ul class="navigation-mobile__list">
                    <li
                        v-for="(item, index) in main"
                        class="navigation-mobile__item"
                        :key="index"
                        :class="{'is-active': item.active}"
                    >
                        <template v-if="item.items.length">
                            <span
                                v-html="item.name"
                                @click="showSubMenu(item.items, item.name, index)"
                            ></span>
                            <z-icon dir="down" name="back" class="navigation-mobile__arrow" width="22" height="22"/>
                        </template>
                        <a v-else
                            :href="item.link"
                            @click="clickHandler(item)"
                            :title="item.name"
                            v-html="item.name"
                        ></a>
                    </li>
                </ul>
                <ul class="navigation-mobile__list">
                    <li
                        v-for="(item, index) in top"
                        class="navigation-mobile__item"
                        :key="index"
                        :class="{'is-active': item.active}"
                    >
                        <a
                            :href="item.link"
                            @click="clickHandler(item)"
                            :title="item.name"
                            v-html="item.name"
                        ></a>
                    </li>
                </ul>
                <div class="navigation-mobile__footer">
                    <!-- <z-link
                        href="https://corp.qiwi.com/"
                        external
                        theme="black"
                    >Corporate website</z-link> -->

                    <div class="navigation-mobile__subscribe">
                        <z-button
                            tag="a"
                            href="/news-and-events/subscription/"
                            size="s"
                            kind="secondary"
                        >Subscribe</z-button>
                    </div>
                </div>
            </div>
            <div
                class="navigation-mobile__submenu"
                v-if="subMenu.visible"
            >
                <div
                    class="navigation-mobile__submenu-header"
                    @click="hideSubMenu"
                >
                    <z-icon name="back" width="22" height="22"/>
                    <span v-html="subMenu.header"></span>
                </div>
                <div class="navigation-mobile__submenu-body">
                    <ul class="navigation-mobile__list">
                        <li
                            class="navigation-mobile__item navigation-mobile__item--top"
                            v-for="(item, index) in subMenu.items"
                            :key="index"
                            :class="{'is-active': item.active}"
                        >
                            <a
                                :href="item.link"
                                @click="clickHandler(item)"
                                :title="item.name"
                                v-html="item.name"
                            ></a>
                            <ul v-if="item.items.length" class="navigation-mobile__list">
                                <li
                                    class="navigation-mobile__item navigation-mobile__item--top"
                                    v-for="(item, index) in item.items"
                                    :key="index"
                                    :class="{'is-active': item.active}"
                                >
                                    <a
                                        :href="item.link"
                                        @click="clickHandler(item)"
                                        :title="item.name"
                                        v-html="item.name"
                                    ></a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    name: 'navigation-mobile',
    props: {
        isIndex: Boolean
    },
    data () {
        return {
            top: this.$root.app.components.navigation.top,
            main: this.$root.app.components.navigation.main,
            subMenu: {
                items: [],
                header: '',
                visible: false
            }
        }
    },
    computed: {
        getBurgerMenu () {
            return this.$store.getters.burgerMenu
        },
        getFastSearch () {
            return this.$store.getters.fastSearch
        }
    },
    watch: {
        getBurgerMenu (next, prev) {
            let header = document.querySelector('.header')

            this.subMenu.visible = !(next && header)

            if ((next && header) || this.getFastSearch) {
                header.classList.add('is-open')
                document.body.classList.add('is-disabled')
            } else {
                header.classList.remove('is-open')
                document.body.classList.remove('is-disabled')
            }
        }
    },
    created () {
        this.top = this.filterData(this.top, (item) => {
            return !((!Array.isArray(item.params) && item.params?.hidePage) || (!Array.isArray(item.params) && item.params?.mobileHide))
        })
        this.main = this.filterData(this.main, (item) => {
            return !(!Array.isArray(item.params) && item.params?.hidePage)
        })
    },
    methods: {
        clickHandler (item) {
            if (item.params.hashLink) {
                location.replace(item.link)
                location.reload()
            }
        },
        filterData (data, predicate) {
            return !data ? null : data.reduce((list, entry) => {
                let clone = null
                if (predicate(entry)) {
                    clone = Object.assign({}, entry)
                }
                if (entry.items != null) {
                    let items = this.filterData(entry.items, predicate)
                    if (items.length > 0) {
                        clone = Object.assign({}, entry, {items: items})
                    }
                }

                clone && list.push(clone)
                return list
            }, [])
        },
        toggleDropdown (state) {
            this.$store.commit('FastSearch', false)
            this.$store.commit('BurgerMenu', !state)
        },
        showSubMenu (items, name, index) {
            this.subMenu.items = items
            this.subMenu.header = name
            this.subMenu.visible = true
        },
        hideSubMenu () {
            this.subMenu.items = []
            this.subMenu.header = ''
            this.subMenu.visible = false
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
