/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'social/facebook': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<circle pid="0" cx="16" cy="16" r="16" _fill="#2F363F"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M17.333 10.711v1.733H20l-.32 2.89h-2.347V24h-3.2v-8.667H12v-2.889h2.133v-1.733c0-2.138 1.067-4.044 3.734-4.044H20v2.889h-1.387c-1.12 0-1.28.404-1.28 1.155z" _fill="#fff"/>'
  }
})
